@font-face {
  font-family: "Satoshi_Black";
  src: url("./font/Satoshi-Black.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_BlackItalic";
  src: url("./font/Satoshi-BlackItalic.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_Bold";
  src: url("./font/Satoshi-Bold.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_BoldItalic";
  src: url("./font/Satoshi-BoldItalic.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_Italic";
  src: url("./font/Satoshi-Italic.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_Light";
  src: url("./font/Satoshi-Light.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_LightItalic";
  src: url("./font/Satoshi-LightItalic.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_Medium";
  src: url("./font/Satoshi-Medium.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_MediumItalic";
  src: url("./font/Satoshi-MediumItalic.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi_Regular";
  src: url("./font/Satoshi-Regular.otf") format("truetype");
}
:root {
  --main-font: "Satoshi_Medium", sans-serif;
}
body {
  font-family: var(--main-font);
}

.errorcss {
  display: flex;
  justify-content: flex-start;
  color: rgb(201, 89, 89);
  font-size: small;
  margin-top: 8px;
}

.icons {
  color: #8c92ab !important;
}

.pair_section img {
  border-radius: 50px;
  height: 18px;
  margin-right: 1px;
  width: 18px;
}

.pair_section h2 {
  font-size: 12px;
  font-weight: 600;
}

.imgcss {
  height: 40px;
  width: 32px;
}

.paginationcss {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #87909f !important;
  background-color: #060612 !important;
  border: 1px solid #87909f !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #5841d8 !important;
  border-color: #5841d8 !important;
}
.dashboardtablebtn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.text-red {
  color: red !important;
}

.terms_contition h1 {
  font-size: 30px;
  color: #fff !important;
  text-align: center;
}

.terms_contition p {
  font-size: 16px;
  margin-top: 18px;
  color: #8c92ab !important;
  text-align: left;
}
.terms_contition h3 {
  font-size: 27px;
  text-align: left;
  color: #fff !important;
}
.min200{
  min-height: 200px !important;
}

.flexgap{
  display: flex !important;
  gap: 20px !important;
}
.w100{
  width: 100%;
}

.uniramp button.priomary {
  background: #000;
  text-transform: capitalize;
  color: #fff;
  padding: 8px;
}

.paymentby{
    border-bottom: 1px solid #e4e4e42e !important;
}
