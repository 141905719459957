/* Deposit */

.deposit_crypto_header {
  display: flex;
  justify-content: space-between;
}

.deposit_left_heading h2 {
  font-size: 28px;
  color: rgb(29, 29, 29);
  font-weight: 700;
}

.deposit_right_btn button {
  border-radius: 24px;
  border: none;
  cursor: pointer;
  width: auto;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 24px;
  color: rgb(29, 29, 29);
  background: rgba(29, 29, 29, 0.04);
}

.faq_dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 8px;
  border-radius: 50%;
  align-self: baseline;
  margin-top: 9px;
  background: rgb(29, 29, 29);
  flex-shrink: 0;
}

.faq_list h3 {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
}

.faq_list a {
  background-color: transparent;
  outline: none;
  text-decoration: none;
  transition: color 0.3s ease 0s;
  font-size: 14px;
  font-weight: 400;
  color: rgb(29, 29, 29);
  cursor: pointer;
}

.faq_list {
  padding: 32px;
  border-radius: 16px;
  border: 1px solid rgba(29, 29, 29, 0.08);
}

.select_btn::after {
  display: flex;
  flex: 1 1 100%;
  width: 1px;
  background: rgba(29, 29, 29, 0.08);
  content: "";
}

.ri-checkbox-circle-line {
  width: 24px !important;
}

.select_coin_section .select-coin {
  width: 100%;
}

.slt_dropdown img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  vertical-align: middle;
  overflow: hidden;
  border: none;
  opacity: 1;
}

.spn_usdt {
  margin-left: 8px;
  font-size: 16px;
  color: rgb(29, 29, 29);
}

.spn_tether {
  white-space: nowrap;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(29, 29, 29, 0.4);
  overflow: hidden;
  padding-right: 5px;
}

.selection_dropdown {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid rgba(29, 29, 29, 0.12);
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: rgb(29, 29, 29);
  /* margin: 0 25px; */
}

.coin_list p {
  margin-right: 150px;
}
.market-header.deposit th {
  /* padding: 17px 60px; */
}

.deposit_table_title {
  margin-bottom: 12px;
  color: rgb(29, 29, 29);
  font-weight: 600;
  font-size: 20px;
}

.empty_data_img {
  text-align: center;
}
.no_records_text {
  text-align: center;
}

.coin-item img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 50%;
}

.coin-item {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background: rgba(29, 29, 29, 0.04);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: rgba(29, 29, 29, 0.6);
  margin: 0px 8px 16px 0px;
  cursor: pointer;
}

.coin_list {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.select_coin_section {
  position: relative;
  display: flex;
  flex-flow: column;

  -webkit-box-align: center;
  align-items: center;
  max-width: 616px;
  margin: 0px auto;
}

.select_text {
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 12.5px;
  color: rgb(29, 29, 29);
  margin-top: 10px;
}

.network_content h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 12.5px;
  color: rgb(29, 29, 29);
}

.select_network_section {
  width: 100%;
}

.faq_list a:hover {
  color: rgb(69, 186, 157) !important;
  text-decoration: underline !important;
}

.modal-content.select_coin {
  background: #fff;
  border-radius: 20px;
}
.red-color {
  color: #ec2f2f;
}

.select_coin .close {
  color: #000 !important;
}
.select_coin .modal-title {
  color: #000;
  /* margin-left: 20px; */
}
.content_vallet img {
  width: 40%;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
.content_vallet p {
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.slt_dropdown {
  flex-grow: 1;
  background: transparent;
}
.slt_dropdown input {
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
}
.text_purble {
  color: #5841d8;
}
.select_coin .modal-header {
  border-bottom: none;
}

.top_coin_item {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background: rgba(29, 29, 29, 0.04);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: rgba(29, 29, 29, 0.6);
  margin: 0px 8px 16px 0px;
  cursor: pointer;
}

.top_coin_section h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: rgba(29, 29, 29, 0.4);
  margin: 10px 0;
}

.top_coin_section {
  /* margin-left: 20px; */
}
.cryptocoin_items {
  /* margin-left: 20px; */
  max-height: 315px;
  overflow: hidden;
  overflow-y: auto;
}
.cryptocoin_items::-webkit-scrollbar {
  display: none;
}

.cryptocoin_list {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 10px;
  border-bottom: 1px solid #2e323d78;
  transition: all 0.3s;
  cursor: pointer;
}
.cryptocoin_list:hover {
  transition: all 0.3s;
  cursor: pointer;
  background: #cccccc1c;
  border-radius: 5px;
}
.list_content h3 {
  color: rgb(29, 29, 29);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}

.list_content span {
  font-weight: 400;
  font-size: 14px;
  color: rgba(29, 29, 29, 0.4);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cryptocoin_logo img {
  color: rgba(0, 20, 42, 0.6);
  font-weight: 400;
  background-color: #fff !important;
  font-size: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.selection_network_warning {
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  word-break: break-word;
  border-radius: 8px;
  font-weight: 500;
  background: rgba(248, 178, 0, 0.08);
}

.selection_network_warning p {
  color: rgba(29, 29, 29, 0.6);
  font-size: 14px;
  margin: 0px;
  line-height: 130%;
}

.ri-error-warning-line {
  color: rgb(248, 178, 0);
  margin-right: 10px;
}

/* Staking Page */

.staking_now_box {
  margin-right: 12px;
  background-image: url("./Image/Swap/staking_bg.png");
  background-repeat: no-repeat;
  background-position: 186px;
}

.tutorial-step1.stack_card {
  height: 150px;
  border-radius: 8px;
  padding: 24px;
  border: 0.5px solid rgba(29, 29, 29, 0.08);
  background-color: rgba(29, 29, 29, 0.02);
  display: flex;
  flex-direction: column;
}

.staking_table_heading {
  font-weight: 700;
  font-size: 36px;
  line-height: 100px;
  margin-top: 30px;
  color: rgb(29, 29, 29);
}

.row_profile_pic img {
  width: 25px;
}

.p2p_row.stake_row td > p {
  margin: 0 30px;
}
.p2p_row_button.subscribe button :active {
  background: rgb(129 62 242);
}

.p2p_row_button.subscribe button {
  font-weight: 700;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  width: auto;
  opacity: 1;
  font-weight: 700;
  height: 37px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  background: rgb(29, 29, 29);
}

.staking_banner_section {
  width: 100%;
  background-color: rgb(21, 21, 21);
  min-height: 380px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.staking_banner_section .left_bg {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url("./Image/Swap/left_bg.png") 0px 100% no-repeat;
}

.staking_banner_section .right_bg {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url("./Image/Swap/right_bg.png") 100% 0px no-repeat;
}

.banner_left_content {
  padding-left: 280px;
}

.banner_left_content h2 {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 56px;
  color: rgb(243, 243, 243);
}

.banner_left_content p {
  color: rgba(243, 243, 243, 0.4);
  font-size: 18px;
  line-height: 130%;
  margin-bottom: 0px;
  font-weight: 300;
}

.detail_btn {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 24px;
  padding-right: 20px;
  gap: 4px;
  color: rgb(243, 243, 243);
  font-size: 14px;
  font-weight: 700;
  background-color: rgba(243, 243, 243, 0.08);
  cursor: pointer;
  border-radius: 20px;
  width: fit-content;
}

.faq_btn {
  display: flex;
  align-items: center;
  margin-left: 32px;
  color: rgb(243, 243, 243);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.ri-arrow-drop-right-line {
  color: rgba(140, 140, 140, 0.6);
  margin-left: 4px;
}

.staking_right_section {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid rgba(243, 243, 243, 0.12);
  background: rgba(243, 243, 243, 0.02);
  color: rgba(243, 243, 243, 0.4);
  backdrop-filter: blur(4px);
  font-size: 14px;
  /* max-height: 160px; */
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 380px;
}

.stak_asset {
  display: flex;
  align-items: center;
  color: rgba(243, 243, 243, 0.4);
  font-size: 14px;
  line-height: 130%;
}

.asset_star-1 {
  font-size: 24px;
  font-weight: 700;
  color: rgb(243, 243, 243);
}
.asset_star-2 {
  color: rgba(243, 243, 243, 0.4);
  font-size: 12px;
  margin-bottom: 10px;
}

.asset_myholdings {
  gap: 10px;
}

.hold_green_text {
  color: rgb(128 107 244);
}

.staking_btns {
  margin: 30px 0;
}

.ri-eye-off-line {
  margin-left: 8px;
}

.card-title.p2p.staking {
  font-weight: 500;
}

.staking_now_box h3 {
  font-weight: 700;
}

.row.staking_header {
  margin-right: 0px;
}

.search_checkbox {
  display: flex;
  justify-content: space-between;
}

.staking_input {
  margin-top: 32px;
}

.coin-checkbox {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.staking_input span {
  margin-right: 10px;
}

.search-box-content {
  display: flex;
  padding: 5px 0px;
  border: 1px solid rgba(29, 29, 29, 0.4);
  border-radius: 8px;
}

.search-box-content input {
  background: transparent;
  border: none;
}

.staking_input {
  display: flex;
  gap: 8px;
  align-items: center;
}

.search-line {
  padding: 0 10px;
}

/* Popup */

.modal_available.right_popup {
  display: flex;
  justify-content: space-between;
}
.estimat_return {
  padding-top: 200px;
}
.estimat_return button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.4;
  font-weight: 700;
  height: 40px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  background: #5841d8;
  padding: 24px 0px;
  margin-top: 18px;
}
.modal_available.left_popup {
  display: flex;
  justify-content: space-between;
}

.modal-text.right-popup {
  background-color: rgba(29, 29, 29, 0.02);
  padding: 20px 55px;
}

.modal-content.p2p.staking {
  border-radius: 15px;
}
.modal-header.preview {
  padding: 25px 0;
}

.modal_text.left-popup .p2p_row_trades {
  display: flex;
  padding: 15px 0px;
  color: rgb(29, 29, 29);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  border-bottom: 1px solid rgba(29, 29, 29, 0.04);
}

.pop_color_text p,
.pop_color_text span,
.ri-add-circle-line {
  color: rgb(1, 188, 141);
}

.modal_text.left-popup h3 {
  color: rgba(29, 29, 29, 0.4);
  font-size: 14px;
  line-height: 1.3;
}

.flexible_text p {
  color: rgb(29, 29, 29);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  text-align: right;
  display: flex;
  align-items: center;
}

.popup_line {
  border: none;
  flex-shrink: 0;
  display: block;
  width: 100%;
  height: 1px;
  margin: 24px 0px;
  background: rgba(29, 29, 29, 0.08);
}

.profile_content.stak_heading h2 {
  font-size: 20px;
  font-weight: 600;
}

.modal-header.preview h2 {
  color: rgb(29, 29, 29);
  font-weight: 600;
  font-size: 20px;
}

.buy-amt.left_popup {
  position: relative;
  background: transparent;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 1;
  cursor: text;
  padding: 14px 16px;
  border: 1px solid rgba(29, 29, 29, 0.08);
}
.color_white_sedr {
  color: #fff;
  font-weight: 400;
}

.modal_available.right_popup h3 {
  color: rgba(29, 29, 29, 0.4);
  font-size: 14px;
  line-height: 1.3;
}

.modal_available.right_popup p {
  color: rgb(29, 29, 29);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  text-align: right;
  display: flex;
  align-items: center;
}

.returns_text {
  color: rgb(29, 29, 29);
  font-weight: 600;
  font-size: 20px;
}

.pop_color {
  color: rgb(1, 188, 141);
}

.hydra-text p {
  color: rgba(29, 29, 29, 0.4);
  font-size: 14px;
  line-height: 1.3;
}
.modal-content.p2p .modal-body.staking_body_content {
  padding-bottom: 0px;
}
.modal_text.left-popup {
  padding: 20px 60px;
}

.modal-content.p2p .modal-header .close.btn {
  margin: -20px;
}
.modal-body.staking_body_content {
  padding: 0;
}

.dark .banner_V {
  height: 563px;
  background: #0a0a12;
}

.staking_period p {
  color: #000 !important;
}

.Dashcard.bg_trance .lrtcss-17ouo08 {
  display: flex;
  flex-direction: row;
}

.flexbutton {
  gap: 6px;
  margin-left: 0px;
}

.p2p_chat_section {
  height: auto;
  border-radius: 10px;
  padding: 14px;
}

.msg-box {
  height: 570px;
}

.place_order_header {
  margin-top: 80px;
  padding: 45px;
  border-radius: 10px;
  background: #fafafa;
}

.sub-text {
  color: #545b66;
  font-weight: 100;
  display: flex;
  margin-right: 20px;
  font-size: 14px;
}

.sub-text.order {
  margin-right: 4px;
}

.usd-bold {
  font-size: 14px;
  font-weight: 500;
}

.total-text.amt {
  font-size: 14px;
}

.card-title.p2p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #333;
}

.trading_accordion h1 {
  font-weight: 600;
  font-size: 36px;
  margin: 100px 0px 40px 19px;
}

.place_order_content h1 {
  font-size: 36px;
  margin-bottom: 25px;
}

.order-text {
  margin-top: 25px;
}
.ri-send-plane-2-fill {
  color: green;
  font-size: 25px;
  margin-left: -35px !important;
}
.trading_accordion_section .card-body {
  font-size: 16px;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: start;
  color: #000 !important;
  padding: 19.5px 12px !important;
  width: 355px;
}

/* .lrtcss-1urys0g {
  margin: 3px 55px;
} */
.dark .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff !important;
  padding: 15.5px 12px !important;
  width: 355px;
}

.dark .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #5841d8 !important;
  font-size: 16px !important;
}

.dark
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #262a37 !important;
  border-width: 2px;
}
/*Dark Mode  */

.dark .lrtcss-yqpjhy {
  background: #0d0e0f;
}

.dark .lrtcss-wxjc90 {
  background: #060612;
}

.dark .lrtcss-sq6w90 {
  color: #fff;
}

.dark .lrtcss-1cp6ea7 {
  color: #fff;
}

.dark .lrtcss-11vn8f6 {
  color: #87909f;
}

.dark .lrtcss-131r450,
.lrtcss-131r450:hover {
  color: #87909f;
}

.dark .lrtcss-8d5juj {
  color: #fff;
}

.dark .lrtcss-135twnz {
  color: #87909f;
}
.lrtcss-kernjd {
  color: #87909f;
}

.dark .lrtcss-112vdob {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark .refframnl_card {
  border: 1px solid #2e323d78;
  background-color: #181c2a3b;
}

.dark .content_card h4 {
  color: #fff;
}

.dark .dark .lrtcss-112vdob {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark .proice h4 {
  color: #fff;
}

.dark button.priomary-outline {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark .content_card p {
  color: #87909f;
}

.dark button.priomary {
  background: #813ef2;
}

.dark .content_card a,
.content_card a:hover {
  color: #813ef2;
}

.dark .table_img1 .ox {
  color: #fff;
}

.dark .table_img1 span {
  color: #87909f;
}

.dark .table.market td {
  color: #87909f;
}

.dark .heading_section {
  color: #fff;
}

.dark .table_responsive {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .sidemenu a.active span {
  color: #fff;
}

.dark .sidemenu a.active i {
  color: #fff;
}

.dark .sidemenu a span {
  color: #fff;
}

.dark .Dashcard {
  border: 1px solid #2e323d78;
  border-radius: 20px;
  background-color: #181c2a3b;
}

.dark .balance_amount p span {
  color: #fff;
}

.dark .balance_amount h4 {
  color: #87909f;
}

.dark .wallet_balance h3 {
  color: #fff;
}

.dark .nav-link.earn-btn {
  color: #fff !important;
  border: 1px solid #813ef2 !important;
}
.dark .nav-pills .nav-link.spot-btn.active {
  color: #fff;
  background-color: #813ef2 !important;
  border: none;
}

.dark .wallet_balance {
  box-shadow: 0 6px 54px #2e323d78;
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .amount h4 {
  color: #fff !important;
}

.dark .amount p {
  color: #fff !important;
}

.dark .balance_amount p span {
  color: #fff !important;
}

.dark .p2p_header h1 {
  color: #fff;
}

.dark .lrtcss-1urys0g {
  background: transparent;
  border: none;
  border-radius: 9px;
  border-radius: 0;
  border-bottom: 1px solid #222429;
}

.dark .lrtcss-1urys0g {
  color: #fff;
}

.dark .header_content h3 {
  color: #fff;
}

.dark .lrtcss-1c963u1 {
  color: #fff;
}

.dark .lrtcss-6kgahy {
  color: #87909f;
}

.dark .header_content p {
  color: #87909f;
}

.dark .lrtcss-1d4kpr5 {
  color: #fff !important;
  border: 1px solid #813ef2 !important;
}

.dark .lrtcss-9h3gn9 span {
  color: #f8b200;
}

.dark .lrtcss-xjkkcr {
  border: 1px solid #2e323d78;
  background-color: #181c2a3b;
}

.dark .staking_right_section {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a;
}

.dark .tutorial-step1.stack_card {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .tutorial-steps h3 {
  color: #fff;
}

.dark .tutorial-steps p {
  color: #87909f;
}

.dark .staking_table_heading {
  color: #fff;
}

.coin-checkbox {
  border: 1px solid #2e323d78;
}

.dark .staking_input span {
  color: #87909f;
}

.dark .search-box-content {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .search-line {
  color: #87909f;
}

.dark .search-box-content input {
  color: #87909f;
}

.dark .staking_period p {
  color: #fff !important;
  margin: 0;
}

.dark .p2p_order_limit h3 {
  color: #fff;
}

.dark .profile_content h3 {
  color: #fff;
}

.dark .staking_banner_section .left_bg {
  display: none;
}

.dark .staking_banner_section .right_bg {
  display: none;
}

.dark .staking_banner_section {
  background-color: rgb(88 65 216 / 17%);
}
.dark .stak_asset {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 400;
}
.dark .asset_star-1 {
  color: #fff;
  font-size: 32px;
}
.dark .asset_star-2 {
  color: #999;
}

.dark .detail_btn {
  color: #000;
  background-color: #fff;
}

.dark .ri-arrow-drop-right-line {
  color: #000;
}
.dark .p2p_row_button.subscribe button {
  color: #fff;
  border: 1px solid rgb(129 62 242);
  background: transparent;
  color: #fff;
  border: 1px solid #813ef2;
  border-radius: 20px;
  padding: 9px 10px;
  margin: 0px 4px;
  font-size: 14px;
}

.dark .p2p_row_button.subscribed button {
  color: rgb(255, 255, 255);
  background: rgb(129 62 242);
}

.dark .trading_accordion h1 {
  color: #fff;
}

.dark .card-header.collapsed.p2p {
  background: #11142199;
  color: #fff;
}

.dark .card {
  background-color: #060612;
}

.dark .card-title.p2p {
  color: #fff;
}

.dark .trading_accordion_section .card-body {
  color: #87909f;
}

.dark .accordion .card-header:after {
  color: #fff;
}

.dark .convert_section {
  background: #060612;
}

.dark .convert-form {
  margin-bottom: 50px;
  background: #060612;
  padding-bottom: 50px;
  margin-top: 100px;
}

.dark .swap-convert {
  border: 1px solid #2e323d78;
  border-radius: 20px;
  background-color: #181c2a3b !important;
}

.dark .confirm-btn button {
  background: #813ef2;
}

.dark .faq-title {
  color: #fff;
}

.dark .contvert-text {
  color: #fff;
}

.dark .history-icon {
  color: #fff;
}

.dark .pay {
  color: #fff;
}

.dark .available-balance {
  color: #fff;
  border: 1px solid #813ef2;
  font-size: 14px;
  font-weight: 300;
}

.dark .convert-bg img {
  display: none;
}

.dark .price-text {
  color: #87909f;
  display: flex;
  gap: 6px;
}
.history-icon p {
  margin: 0;
}

.dark .usdt-btc {
  color: #87909f;
}

.dark .input input {
  color: #fff;
  opacity: 1;
}

.dark .ri-swap-line:before {
  color: #813ef2;
}

.dark .tutorial-title {
  color: #fff;
}

.dark .tutorial-step1 {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .ustd_kucoin h3 {
  color: #fff;
}

.dark .kucoin_title {
  color: #fff;
}

.dark .about-kucoin p {
  color: #87909f;
}

.dark .about-kucoin2 p {
  color: #87909f;
}

.dark .p2p_works_title {
  color: #fff;
}

.dark .p2p_buy_btn .nav-link.active {
  color: rgb(255, 255, 255);
  background: #813ef2;
}
.dark .p2p_sell_btn .nav-link.active {
  color: rgb(255, 255, 255);
  background: #813ef2;
}
.dark .p2p_sell_btn .nav-link {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark .work_step1 h3 {
  color: #fff;
}

.dark .work_step1 p {
  color: #87909f;
}

.dark .p2p_header p {
  color: #87909f;
}

.dark .p2p_tabs .nav-link.active {
  color: #fff;
}

.dark .p2p_tabs .nav-tabs .nav-link.active::after {
  background: #813ef2;
}

.dark .p2p_tabs .nav-item a {
  color: #87909f;
}

.dark .nav-item.buy .buy-btn {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark .p2p_row_price h3 {
  color: #fff;
}

.dark .p2p_row_price h3 span {
  color: #87909f;
}

.dark .p2p_pagination a {
  color: #813ef2;
  border: 1px solid #2e323d78;
  background-color: #181c2a3b;
}

.dark .pagination .page-item li {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .p2p_buy_btn .nav-link {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark .deposit_left_heading h2 {
  color: #fff;
}

.dark .deposit_right_btn button {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark .faq_list h3 {
  color: #fff;
}

.dark .faq_list p {
  color: #87909f;
}

.dark .faq_list {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .faq_dot {
  background: #fff;
}

.dark .selection_dropdown {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .spn_usdt {
  color: #fff;
}

.dark .spn_tether {
  color: #87909f;
}

.dark .select_text {
  color: #fff;
}

.dark .ri-arrow-down-s-line:before {
  color: #fff;
}

.dark .deposit_table_title {
  color: #fff;
}

.dark .coin-item {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark .place_order_content h1 {
  color: #fff;
}

.dark .place_order_content p span {
  color: #fff;
  background: #2e323d78;
}

.dark .order-text {
  background: #11142199;
  border: 1px solid #222429;
  border-radius: 9px;
}

.dark .text-usdt {
  color: #fff;
}

.dark .total-text {
  color: #87909f;
}

.dark .usd-bold {
  color: #fff;
}

.dark .sub-text {
  color: #fff;
}

.dark .pay-btn button {
  background: #813ef2;
  color: #fff;
}

.dark .p2p_chat_section {
  background: #11142199;
  border: 1px solid #222429;
  box-shadow: none;
}

.dark .msg-box {
  background: #11142199;
  border-radius: 5px;
}
.dark .msg-text-box input {
  color: #fff;
  background: #0f121f;
}

.dark .msg-text-box {
  background: #0f121f;
  box-shadow: none;
  border: 1px solid #6725d6;
  margin: 10px 20px;
  max-height: 70px;
  border-radius: 10px;
  margin-top: 24px;
}

.dark .sender p {
  background: #0d0e0f;
  border: 1px solid #222429;
  color: #fff;
}
/* .dark .sender p {
    background: #0d0e0f;
    border: 1px solid #222429;
    color: #fff;
} */
.dark .receiver p {
  background: #0f121f;
  border: 1px solid #813ef25c;
  color: #fff;
}

.dark .chat_profile {
  border: 1px solid rgb(132 135 143);
  color: #fff;
}

.dark .total.amt-usd {
  border-bottom: 1px solid #222429;
}

.dark .cancel {
  color: #ff000094;
}

.dark .place_order_header {
  background: #11142199;
  border: 1px solid #222429;
  box-shadow: none;
  margin-left: 32px;
}

.dark .p2p_chat_section {
  height: auto;
  border-radius: 10px;
  padding: 18px;
  margin-right: 32px;
}

.dark .ri-search-line {
  color: #87909f;
}

.dark .row_profile {
  color: #fff;
  border: 1px solid rgb(132 135 143);
}

.dark .p2p_modal_contant h3 {
  color: #87909f;
}

.dark .p2p_modal_contant p {
  color: #fff;
}

.dark .modal-content.p2p {
  background: #181c2a;
}

.dark .modal_available h3 {
  color: #87909f;
}

.dark .modal_available p {
  color: #fff;
}
.dark .selection_network_warning p {
  color: rgb(248, 178, 0);

  font-weight: 400;
}
.dark .buy-amt {
  border-color: #e4e4e42e;
  background: transparent;
  color: #fff;
}

.usd-amt {
  border: 1px solid #e4e4e42e;
  background: transparent;
  color: #fff;
}

.dark .bg_trans_non {
  color: #fff;
}

.dark .place-order {
  color: #fff;
}

.dark .modal-content.p2p .modal-header .close {
  color: #fff;
}

.dark .pay-currency {
  margin-bottom: 14px;
  border-color: #e4e4e42e;
  background: transparent;
  color: #fff;
}

.dark .accordion > .card > .card-header {
  background: #11142199;
  color: #fff;
}
.dark .modal-content.select_coin {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #0a090d;
}
.dark .select_coin .modal-title {
  color: #fff;
}
.dark .returns_text {
  color: rgb(255 255 255);
  font-weight: 400;
}
.dark .modal-header.preview h2 {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}
.dark .modal_text.left-popup .p2p_row_trades {
  color: #fff;
  font-weight: 500;
}
.dark .select_coin .close {
  color: #fff !important;
  text-shadow: none;
}
.dark .ReactFlagsSelect-module_selectBtn__19wW7 {
  color: #fff;
  border: thin solid rgba(77, 77, 77, 0.3);
  padding: 12px 10px;
}
.color_red {
  color: rgb(248 0 0);
}
.dark .ReactFlagsSelect-module_selectBtn__19wW7:after {
  border-top: 5px solid #fff;
}
.check_radio .nav {
  flex-direction: column;
}
.check_radio .nav a {
  width: 100%;
  display: block;
}
.check_radio .nav a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 10px;
  border: thin solid rgba(77, 77, 77, 0.3);
}
.check_radio .nav a.active {
  border-color: #813ef2;
}
.check_radio .nav a.active .ri-checkbox-blank-circle-line:before {
  content: "\eb80";
  color: #813ef2;
}
.check_radio .nav a .image_div {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 16px;
}
.check_radio .nav a .cicler {
  width: 40px;
  height: 40px;
  display: grid;
  place-content: center;
  background: #0000004f;
  border-radius: 50%;
  border: 1px solid #cccccc4a;
}
.check_radio .nav a i {
  font-size: 22px;
  color: #fff;
}
.lable_KYC {
  color: #87909f;
  font-size: 14px;
  margin-bottom: 15px;
  text-transform: capitalize;
  font-weight: 300;
}
.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  margin-bottom: 10px;
}
.check_radio .nav a small {
  font-weight: 200;
  font-size: 12px;
  background: #813ef2;
  padding: 2px 10px;
  border: thin solid #813ef2;
  border-radius: 4px;
}
.modal-footer {
  border-top: thin solid #dee2e614 !important;
}
.modal-footer button,
.modal-footer button:hover {
  background: #813ef2;
  text-transform: capitalize;
  color: #fff;
  padding: 8px 20px;
  border-radius: 50px;
  border: none;
  font-size: 15px;
}
.formbold-mb-5 {
  margin-bottom: 20px;
}
.formbold-file-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  min-height: 200px;
}
.formbold-file-input label {
  position: relative;
  border: thin dashed rgba(77, 77, 77, 0.3);
  border-radius: 6px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  cursor: pointer;
}
.formbold-file-input label:hover {
  border: thin dashed #4c37bd;
}
.formbold-drop-file {
  display: block;
  font-weight: 600;
  color: #07074d;
  font-size: 20px;
  margin-bottom: 8px;
  color: #87909f;
  font-size: 14px;
  margin-bottom: 15px;
  text-transform: capitalize;
  font-weight: 300;
}
.formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  display: block;
  margin-bottom: 8px;
}
.formbold-browse {
  font-weight: 500;
  font-size: 16px;
  color: #87909f;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #4c37bd;
  border-radius: 4px;
}
.formbold-file-list {
  border-radius: 6px;
  background: #f5f7fb;
  padding: 16px;
  border: thin solid rgba(77, 77, 77, 0.3);
  background: transparent;
}
.formbold-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formbold-file-name {
  overflow: scroll;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  width: 85%;
  padding-right: 12px;
}
.formbold-file-item button {
  color: #ffff;
  border: none;
  background: transparent;
  cursor: pointer;
}
.dark .top_coin_item {
  color: #fff;

  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}
.dark .list_content h3 {
  color: #fff;
}
.dark .list_content span {
  font-weight: 400;
  color: #999999c9;
}

.deposite_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p2p_tabs .nav-tabs.history_tabs .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 1px;
  background: #813ef2;
  border-radius: 6px;
  height: 6px;
  width: 32px;
  bottom: -2px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.warning_content a {
  border: 1px solid #5148db;
  background: #5148db;
  padding: 10px 25px;
  border-radius: 20px;
  color: #fff;
}

.warning_content h2 {
  margin-bottom: 35px;
  color: #87909f;
  font-size: 24px;
}

.warning_content {
  text-align: center;
  margin-top: 70px;
}

.deposit_crypto_section.kyc_section .row {
  margin-left: 0px;
  margin-right: 0px;
}

.daily_limit p {
  color: #fff;
}

.new_ref_section {
  width: 100%;
  position: relative;
  min-height: 278px;
  background: linear-gradient(
    155.28deg,
    rgb(65 62 90) 6.36%,
    rgb(88 65 216 / 17%) 61.09%
  );
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  display: flex;
  padding: 97px 32px;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.ref_right_content h2 {
  color: #fff;
}

.ref_right_content p {
  color: #f3f3f366;
}

.earning_card_1 {
  background: #181c2a;
  border: 1px solid 2e323d78;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  padding: 18px 15px;
  justify-content: center;
}

.banner_left_content.refer h2 {
  font-size: 40px;
}

.banner_left_content.refer h4 {
  font-size: 14px;
  color: #f3f3f366;
}

.earning_card_1 p {
  font-size: 14px;
  color: #f3f3f366;
  text-align: center !important;
}

.earning_card_1 h3 {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center !important;
}

.banner_left_content.refer p {
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  line-height: 24px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: fit-content;
  padding: 6px 14px;
  cursor: pointer;
  margin: 25px 0px 16px;
  color: #fff;
  border: 1px solid #5841d8;
}
.banner_left_content.refer {
  padding-left: 130px;
}

.refferal_settings_section {
  width: 100%;
}

.settings_box .settings_box_header {
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  padding: 18px 30px;
  background: #181c2a;
  color: #fff;
}

.commission_rate {
  display: flex;
  justify-content: space-between;
}

.commission_rate_percentage {
  display: flex;
  justify-content: space-between;
}
.bottom_box {
  background: #181c2a;
  padding: 30px;
  flex: 1 1;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  margin-top: 3px;
  color: #fff;
}

.commission_rate.ref_link {
  display: flex;
  position: relative;
  background: rgb(255 255 255 / 4%);
  border-radius: 6px;
  flex: 1 1;
  max-width: 100%;
  line-height: 18px;
  padding-left: 12px;
  align-items: center;
  height: 100%;
  padding: 18px 8px;
}
.commission_rate.ref_link input {
  border: none;
  background: transparent;
  color: #fff;
}

.invite_button_ref {
  display: flex;
  /* justify-content: space-between; */
  text-align: center;
}
.learn_green_btn span {
  margin: 0 4px;
}
.invite_btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  opacity: 1;
  padding: 9px 18px;
  background: #5841d8 !important;
  color: #fff !important;
  width: 454px;
  height: 48px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  border-radius: 5px;
}

.invite_qr i {
  width: 60px;
  pointer-events: none;
  margin-left: 3px;
  font-size: 15px;
  background: #5841d8 !important;
  color: #fff !important;
  border-radius: 5px;
  margin-left: 10px;
  padding: 8px;
  cursor: pointer !important;
}

.staking_banner_section.referal {
  min-height: 460px;
}

.refferal_settings_section {
  margin-right: 30px;
}

.copy_input i {
  color: #fff;
}
.copy_input {
  display: flex;
}

.commission_rate_text span,
.commission_rate_content span {
  color: rgb(1, 188, 141);
}

/* Launchpad */

.launchpad_card img {
  width: -webkit-fill-available;
}

.coming_soon_card {
  display: flex;
  justify-content: space-between;
}

.launchpad_card {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
  margin-top: 6px;
  padding: 15px;
}

.token_content,
.sale_content {
  display: flex;
  justify-content: space-between;
}

.coming_soon_card_title {
  font-size: 20px;
  color: #fff;
}

.coming_soon_btn {
  background: #edb9b9f0;
  border-radius: 20px;
  font-size: 14px;
  padding: 5px 9px;
  color: #ca2222;
}
.in_progress_btn {
  background: #f6eecc;
  border-radius: 20px;
  font-size: 14px;
  padding: 5px 9px;
  color: #b29400;
}
.completed_btn {
  background: #d7f0e2;
  border-radius: 20px;
  font-size: 14px;
  padding: 5px 9px;
  color: #0d803e;
}

.launchpad_card_1 p {
  color: #87909f;
  margin-top: 10px;
  font-size: 16px;
}

.token_text {
  font-size: 16px;
  color: #87909f;
  margin-top: 10px;
}

.token_syn {
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
}

.card_timer span {
  color: #fff;
}
.card_timer {
  font-size: 13px;
  color: #87909f;
}

.yield_staking_years {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.yield_staking_years li a {
  color: #87909f;
}
.yield_staking_years li {
  list-style: none;
  width: 33%;
  margin-top: 10px;
  text-align: center;
}

.p2p_table .Dropdown-root {
  color: #87909f;
  background: aliceblue;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 15px;
  border-radius: 5px;
  text-align: right !important;
  border: 1px solid #2e323d78;
  background-color: #181c2aa3;
  position: absolute;
  z-index: 1;
  margin-top: -48px;
}
.staking-history-dropdown {
  display: flex;
  justify-content: end;
}

.staking-history-dropdown i {
  color: #87909f;
}

.Dropdown-placeholder {
  display: flex;
  justify-content: flex-end;
  padding: 6px 17px;
}
.Dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-right: 15px;
}

.read_agree_text {
  color: #87909f;
}

.staking_period button {
  background: transparent;
  border: none;
  border: 1px solid;
  color: #fff;
  border: 1px solid #813ef2;
  border-radius: 20px;
  padding: 3px 8px;
  margin: 0px 5px;
  font-size: 14px;
}

.staking_period button:hover {
  background: #813ef2;
}
.staking_period button:active {
  background: #813ef2;
}

.p2p_order_limit button.active,
.p2p_order_limit button:active {
  background-color: #813ef2;
}
.p2p_row_button.subscribe button.active,
.p2p_row_button.subscribe button:active {
  background-color: #813ef2;
}

.p2p_order_limit button {
  color: #fff;
  border: 1px solid #813ef2;
  border-radius: 20px;
  padding: 3px 8px;
  margin: 0px 5px;
  font-size: 14px;
}

.stake-now-btn {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.p2p_order_limit button small {
  font-size: 14px;
}

.fa-solid.fa-spinner.fa-spin {
  font-size: 48px;
  position: absolute;
  top: 40%;
  left: 50%;
}

.tradeicons {
  position: relative !important;
  top: 120px !important;
  left: 0 !important;
}

/* launchpad_popup */

.launch_popup_title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #202020e3;
  padding: 35px;
  border-radius: 10px;
}
.pop_up_heading {
  font-size: 42px;
  color: #fff;
}

.pop_up_heading {
  font-size: 42px;
  color: #fff;
}
.launch_popup_content h2 {
  font-size: 40px;
  color: #fff;
}

.launch_popup_content p {
  color: #87909f;
  font-size: 18px;
}

.start_end_date {
  display: flex;
  justify-content: flex-end;
}
.start_end_date p {
  color: #87909f;
  font-size: 14px;
  margin-right: 20px;
  margin-bottom: 0px;
}

.start_end_date span {
  color: #fff;
}

.subscription_text p {
  font-size: 16px;
  color: #87909f;
  margin-bottom: 4px;
}
.subscription_text {
  margin-top: 20px;
}
.subscription_text h3 {
  font-size: 18px;
  color: #fff;
}
.buy_button {
  padding: 8px 74px;
  border-radius: 10px;
  background: #5841d8;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  left: 40%;
  margin-top: 40px;
  font-size: 18px;
}

.nav_dropdown-content1 img {
  width: 50px;
}
.content_nav p {
  color: #fff;
  font-size: 18px;
}

.content_nav span {
  color: #87909f;
}
.MuiListItem-gutters.nav_dropdown-content1 {
  padding-left: 5px;
  padding-right: 5px;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  background: #181c2a;
}

.dropdown_nav_item {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 15px;
}
.css-11igqa5-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
  background: #05050c;
}
.MuiAccordionSummary-root {
  background: #181c2a3b;
}
.MuiAccordionSummary-root {
  display: flex;
  padding: 0px 16px;
  min-height: 48px;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #181c2a3b !;
}
.MuiButtonBase-root.dropdown1 {
  background-color: #181c2a !important;
  border-bottom: none !important;
}
.MuiAccordionSummary-content p {
  color: #fff;
}

.MuiIconButton-label svg {
  color: #fff;
}
.css-11igqa5-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
  background: #181c2a;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: center;
  margin-top: 20px;
}

.launchpad p {
  font-size: 16px;
}
.step1_title {
  font-size: 34px;
  color: #fff;
}

.step_content {
  color: #9685ff;
  font-size: 20px;
}

.launch_now_step1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancel_next_btn {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.cancel_next_btn button {
  border-radius: 12px;
  text-align: center;
  padding: 0px 24px;
  color: #5841d8;
  border-radius: 15px;
  border: none;
  min-height: 24px;
  border: 1px solid #5841d8;
  width: 100%;
  height: 53px;
  font-weight: 400;
  background: transparent;
}
.apply_next_btn_active {
  background: #5841d8 !important ;
  color: #fff !important;
}

.form_login_input.position_eye2 {
  display: flex;
  gap: 15px;
}

.token_1 {
  display: flex;
  gap: 5px;
}
.network_dropdown {
  height: 64px;
  border-radius: 6px;
  margin-bottom: 14px;
  border-color: #e4e4e42e;
  padding: 21px;
  width: 100%;
  background: transparent;
  color: #87909f;
}

.step5_content {
  color: #fff;
}

.agree {
  color: #87909f;
}
input[type="checkbox"] {
  accent-color: #5841d8;
}

.count_market {
  text-align: center;
}
.market_trade_btn button {
  background: transparent;
  border: none;
  color: rgb(197, 169, 13);
  font-size: 18px;
}

.market_title {
  font-size: 42px;
  color: #fff;
}

.highlights {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.highlight_content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.highlight_title {
  font-size: 16px;
  color: #87909f;
}

.coin_name {
  color: #fff;
  font-size: 18px;
}

.coin_title {
  font-size: 18px;
  margin-bottom: 0px;
}
.coin_title span {
  font-size: 18px;
}
.Market_overview_section .nav-tabs .nav-link.active {
  color: #fff;
  background-color: transparent;
  border: none;
  position: relative;
}
.Market_overview_section .nav-tabs .nav-link {
  color: #87909f;
}

.Market_overview_section .nav-tabs .nav-link:hover {
  border: none;
}

.market_tabs.nav.nav-tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
  gap: 20px;
}

.wallet-table.market_overview_table {
  margin-top: 10px;
}
.Market_overview_section .nav-tabs .nav-link.active::after {
  background: #813ef2;
}

.market_tab_search_box {
  display: flex;
  justify-content: space-between;
}

.search_box {
  border: 1px solid;
  padding: 9px 3px 9px 150px;
  border-radius: 8px;
}
.Market_overview_section .nav-tabs .nav-link.active::after {
  content: "";
  position: absolute;
  border-radius: 6px;
  height: 6px;
  width: 33px;
  bottom: -3px;
  left: 47%;
  transform: translate(-50%, -50%);
  z-index: 555555;
}
.table_img1 img {
  width: 28px !important;
  height: fit-content;
}
.table_img1 {
  display: flex;
  align-items: center;
}

.deposit_history .table.market td {
  text-align: center;
}
.delete_edit_icon {
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
}
/* Responsive */

@media (max-width: 1024px) {
  .card_timer {
    text-align: center;
  }

  .token_content,
  .sale_content {
    flex-direction: column;
    text-align: center;
  }
  .coming_soon_card {
    flex-direction: column;
    text-align: center;
  }
  .commission_rate.ref_link input {
    font-size: 14px;
  }
  .commission_rate_percentage {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }
  .copy_input_text {
    font-size: 14px;
  }
  .settings_box .settings_box_header {
    padding: 18px 10px;
  }
  .bottom_box {
    padding: 10px;
  }
  .lrtcss-sbd8h {
    padding: 32px 15px 48px;
  }
  .lrtcss-yqpjhy {
    width: 190px;
  }

  .refframnl_card {
    padding: 20px 5px;
    margin-bottom: 20px;
  }
  .bg_img_nrw {
    background-position-y: 10px;
    background-position-x: 412px;
  }
  .faq_list {
    padding: 10px;
  }

  .coin_list p {
    margin-right: 0px;
  }

  .coin-item {
    padding: 6px;
  }

  .banner_left_content {
    padding-left: 280px;
  }

  .staking_now_box {
    margin-right: 2px;
    background-position: 135px;
    margin: 0 -13px;
  }

  .lrtcss-mivjb5 {
    width: 310px;
    padding: 0px;
  }
}
@media (max-width: 920px) {
  .dark .Dashcard {
    margin-bottom: 20px;
  }
  .dark .refframnl_card {
    margin-bottom: 20px;
  }
  .dark .card_table {
    margin: 30px 0px;
    padding: 20px 50px;
  }
  .faq_list {
    padding: 10px 40px;
  }

  .dark .section_card_with_image {
    text-align: center;
  }

  .faq-section {
    margin-left: 5px;
    margin-top: 30px;
  }

  .Deposit_history_section {
    margin-top: 30px;
  }

  /* Staking Page */

  .staking_banner_section .left_bg {
    display: none;
  }

  .staking_banner_section .right_bg {
    display: none;
  }

  .banner_left_content {
    padding-left: 20px;
  }

  .banner_left_content h2 {
    font-size: 45px;
  }

  .banner_left_content p {
    font-size: 18px;
  }

  .staking_right_section {
    padding: 20px;
    max-height: 175px;
    max-width: 280px;
  }

  .staking_now_box {
    background-position: 315px;
  }
  .lrtcss-mivjb5 {
    margin-left: 20px;
    width: 310px;
  }

  .dark .login_form {
    min-height: 0vh;
  }
  table thead .market-header.deposit th {
    min-width: 0px;
  }
  .earning_card_1 {
    margin-top: 15px;
  }
  .invite_btn {
    width: 100%;
  }
  .launchpad_card {
    border: 1px solid #2e323d78;
    border-radius: 10px;
    background-color: #181c2a3b;
    margin-top: 20px;
    padding: 15px;
  }
  .section_card_with_image {
    padding: 40px 80px;
  }
  .table_tabe .nav-tabs {
    justify-content: space-around;
  }
}

@media (max-width: 700px) {
  .lrtcss-1iijojv {
    overflow: hidden;
    overflow-x: auto;
  }
  .content_section h1 {
    font-size: 40px;
  }

  .content_section p {
    font-size: 18px;
  }

  .heading_h2 {
    font-size: 30px;
  }

  .dark .section_card_with_image {
    text-align: center;
    background-position: right;
    background-position-y: 50px;
  }

  .dark .content_center_mobile h3 {
    font-size: 28px;
  }

  /* Staking Page */

  .banner_left_content p {
    font-size: 15px;
  }

  .banner_left_content h2 {
    font-size: 38px;
  }

  .search_checkbox {
    flex-direction: column;
  }

  .staking_table_heading {
    line-height: 60px;
  }

  .p2p_table {
    margin-top: 20px;
    border: 1px solid;
  }

  .lrtcss-1945s3i {
    flex-direction: column;
  }

  .lrtcss-mivjb5 {
    margin-left: 0px;
    width: 100%;
    margin-top: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .lrtcss-1urys0g {
    padding: 24px 12px;
  }

  .grow_1 {
    width: 100%;
  }

  .dark .lrtcss-xjkkcr {
    margin-right: auto;
  }
  table thead .market-header.deposit th {
    min-width: 0px;
  }
}

@media (max-width: 500px) {
  .section_card_with_image {
    padding: 40px 28px;
  }
  .token_syn {
    font-size: 14px;
  }
  .token_text {
    font-size: 14px;
  }
  .launchpad_card_1 p {
    font-size: 14px;
  }
  .coming_soon_card_title {
    font-size: 18px;
  }

  .coming_soon_btn {
    padding: 4px 8px;
  }

  .launchpad_card {
    margin-top: 18px;
  }
  .warning_content h2 {
    font-size: 22px;
  }
  .flexbutton button {
    padding: 3px 20px;
  }

  .dark .Dashcard {
    margin-bottom: 20px;
  }

  .dark .refframnl_card {
    margin-bottom: 20px;
  }

  .balance_input {
    margin-right: 55px;
  }

  .lrtcss-1iijojv {
    overflow: hidden;
    overflow-x: auto;
  }
  .lrtcss-ac8m87 {
    padding: 25px 0px 0px 16px;
  }
  .grow_1 {
    width: 100%;
  }
  .dark .lrtcss-xjkkcr {
    margin-right: auto;
  }
  .content_section h1 {
    font-size: 38px;
  }

  .heading_h2 {
    font-size: 28px;
  }

  .dark .content_feturee h3 {
    font-size: 26px;
  }

  .dark .content_center_mobile h3 {
    font-size: 28px;
  }

  .dark .section_card_with_image {
    text-align: center;
    background-image: none !important;
    background-position-y: 205px;
  }

  /* Deposit Page */

  .deposit_left_heading h2 {
    font-size: 26px;
  }

  .deposit_right_btn button {
    font-size: 13px;
    padding: 8px;
  }

  .coin_list p {
    margin-right: 0px;
  }

  .coin-item {
    padding: 6px 3px;
  }

  .faq-section {
    margin-left: 0px;
    margin-top: 30px;
  }
  .Deposit_history_section {
    text-align: center;
    margin-top: 30px;
  }

  .faq_list {
    padding: 15px;
  }

  /* Staking Page */

  .staking_banner_section .right_bg {
    display: none;
  }

  .staking_banner_section .left_bg {
    display: none;
  }

  .banner_left_content {
    margin: 30px 0px;
    padding-left: 0px;
  }

  .staking_banner_section {
    display: flex;
    flex-direction: column;
  }

  .banner_left_content h2 {
    font-size: 36px;
  }

  .banner_left_content p {
    font-size: 14px;
  }

  .detail_btn {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
  }

  .faq_btn {
    font-size: 13px;
  }

  .staking_right_section {
    max-width: 225px;
    margin: 20px;
  }

  .stak_asset {
    font-size: 14px;
  }

  .asset_myholdings {
    font-size: 14px;
  }

  .tutorial-step1.stack_card {
    height: 190px;
  }

  .search_checkbox {
    flex-direction: column;
  }

  .staking_table_heading {
    font-size: 26px;
    line-height: 35px;
  }

  .search-box-content input {
    font-size: 14px;
  }

  .staking_input span {
    font-size: 14px;
  }

  .p2p_table {
    margin-top: 30px;
  }

  .card-header.collapsed.p2p {
    padding: 22px 15px;
  }

  .card-title.p2p {
    font-size: 13px;
  }

  .msg-text-box input {
    padding: 20px 22px;
  }

  .dark .place_order_header {
    margin-left: 0px;
  }

  .dark .p2p_chat_section {
    margin-right: 0px;
  }
  .place_order_header {
    padding: 10px 8px;
  }

  .lrtcss-1945s3i {
    flex-direction: column;
  }

  .lrtcss-mivjb5 {
    margin-left: 0px;
    width: 100%;
    margin-top: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .lrtcss-1urys0g {
    padding: 24px 12px;
  }
  table thead .market-header.deposit th {
    min-width: 0px;
  }

  .banner_left_content.refer h2 {
    font-size: 28px;
  }
  .bottom_box {
    padding: 15px;
  }
  .settings_box .settings_box_header {
    padding: 18px 12px;
  }

  .commission_rate_percentage {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .earning_card_1 {
    margin-top: 15px;
  }

  .copy_input_text {
    font-size: 14px;
  }

  .commission_rate.ref_link input {
    font-size: 14px;
  }
  .start_end_date {
    flex-direction: column;
    align-items: flex-end;
  }

  .pop_up_heading {
    font-size: 32px;
  }

  .launch_popup_content h2 {
    font-size: 32px;
  }
  .launch_popup_content p {
    font-size: 14px;
  }
  .subscription_text p {
    font-size: 14px;
  }
  .subscription_text h3 {
    font-size: 16px;
  }
  .buy_button {
    padding: 8px 60px;
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .dark .Dashcard {
    margin-bottom: 40px;
  }
  .balance_input {
    margin-right: 35px;
  }
  .dark .refframnl_card {
    margin-bottom: 40px;
  }
  .flexbutton button {
    min-width: 1px;
    padding: 4px 10px;
  }
  .content_section h1 {
    font-size: 34px;
  }

  .content_section p {
    font-size: 15px;
  }

  .content_section button {
    padding: 6px 16px;
    font-size: 14px;
  }

  .heading_h2 {
    font-size: 28px;
  }

  .dark .content_feturee h3 {
    font-size: 26px;
  }

  .dark .content_center_mobile h3 {
    font-size: 26px;
  }

  .dark .section_card_with_image {
    text-align: center;
    background-position-y: 178px;
  }

  /* Deposit Page */

  .deposit_left_heading h2 {
    font-size: 24px;
  }

  .deposit_right_btn button {
    font-size: 13px;
    padding: 8px;
  }
  .coin_list p {
    margin-right: 0px;
  }
  .coin-item {
    padding: 4px 3px;
    font-size: 12px;
    margin: 0px 5px 12px 0px;
  }
  .faq-section {
    margin-left: 0px;
    margin-top: 30px;
  }

  .Deposit_history_section {
    margin-top: 30px;
    text-align: center;
  }

  .faq_list {
    padding: 14px;
  }

  /* Staking page */

  .staking_banner_section .right_bg {
    display: none;
  }

  .staking_banner_section .left_bg {
    display: none;
  }

  .banner_left_content {
    padding-left: 0px;
  }

  .staking_banner_section {
    display: flex;
    flex-direction: column;
  }

  .banner_left_content h2 {
    font-size: 32px;
  }

  .banner_left_content p {
    font-size: 14px;
  }

  .detail_btn {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
  }

  .faq_btn {
    font-size: 13px;
  }

  .staking_right_section {
    max-width: 225px;
    margin: 22px;
  }

  .stak_asset {
    font-size: 14px;
  }

  .asset_myholdings {
    font-size: 14px;
  }

  .tutorial-step1.stack_card {
    height: 190px;
  }

  .search_checkbox {
    flex-direction: column;
  }

  .staking_table_heading {
    font-size: 26px;
    line-height: 35px;
  }

  .search-box-content input {
    font-size: 14px;
  }

  .staking_input span {
    font-size: 14px;
  }

  .p2p_table {
    margin-top: 30px;
  }

  .card-header.collapsed.p2p {
    padding: 22px 15px;
  }

  .card-title.p2p {
    font-size: 14px;
  }
  .dark .card_login {
    padding: 20px 10px;
  }
  .header_content {
    margin-top: 15px;
  }

  .dark .lrtcss-xjkkcr {
    margin-right: auto;
  }
  table thead .market-header.deposit th {
    min-width: 0px;
  }

  .banner_left_content.refer h2 {
    font-size: 30px;
  }

  .ref-settings_text {
    font-size: 14px;
  }

  .commission_rate_text {
    font-size: 14px;
  }

  .commission_rate_content {
    font-size: 14px;
  }

  .bottom_box {
    padding: 8px;
  }

  .commission_rate.ref_link input {
    font-size: 14px;
  }

  .copy_input_text {
    font-size: 14px;
  }

  .settings_box .settings_box_header {
    padding: 15px 10px;
  }

  .earning_card_1 {
    margin-top: 15px;
  }

  .nav-item.overview a {
    font-size: 14px;
  }
  .commission_rate_percentage {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .copy_input {
    margin-left: -20px;
  }
  .coming_soon_card_title {
    font-size: 18px;
  }
  .coming_soon_btn {
    padding: 4px 7px;
  }

  .launchpad_card_1 p {
    font-size: 14px;
  }
  .token_text {
    font-size: 14px;
  }

  .token_syn {
    font-size: 14px;
  }
  .card_timer {
    font-size: 12px;
  }
  .launchpad_card {
    margin-top: 20px;
  }
  .start_end_date {
    flex-direction: column;
    align-items: flex-end;
  }

  .pop_up_heading {
    font-size: 32px;
  }

  .launch_popup_content h2 {
    font-size: 32px;
  }
  .launch_popup_content p {
    font-size: 14px;
  }
  .subscription_text p {
    font-size: 14px;
  }
  .subscription_text h3 {
    font-size: 16px;
  }
  .buy_button {
    padding: 8px 60px;
    font-size: 16px;
  }
}
@media (max-width: 300px) {
  .launchpad_card {
    margin-top: 20px;
  }

  .card_timer {
    text-align: center;
  }
  .token_content,
  .sale_content {
    flex-direction: column;
    text-align: center;
  }
  .token_syn {
    font-size: 14px;
  }

  .token_text {
    font-size: 14px;
  }

  .launchpad_card_1 p {
    font-size: 14px;
    text-align: center;
  }
  .coming_soon_card {
    flex-direction: column;
    text-align: center;
  }
  .refferal_settings_section .container {
    padding-right: 5px;
    padding-left: 5px;
  }
  .copy_input {
    margin-left: 0px;
    width: 100%;
  }
  .input_border {
    padding: 5px 5px;
    margin-right: -10px;
  }
  .flexbutton {
    gap: 10px;
    flex-direction: column;
  }
  /* Home Page */
  .content_section h1 {
    font-size: 24px;
  }

  .content_section p {
    font-size: 13px;
  }

  .content_section button {
    padding: 2px 15px;
    font-size: 13px;
  }

  .dark .content_feturee h3 {
    font-size: 22px;
  }

  .dark .section_card_with_image h4 {
    font-size: 18px;
  }

  .dark .content_center_mobile h3 {
    font-size: 24px;
  }

  .dark .section_card_with_image {
    text-align: center;
    background-position-y: 210px;
  }

  .dark .card_table {
    padding: 10px;
  }

  /*Deposite Page  */

  .deposit_left_heading h2 {
    font-size: 22px;
  }

  .deposit_right_btn button {
    font-size: 13px;
    padding: 3px 10px;
    border-radius: 5px;
  }

  .selection_dropdown {
    padding: 7px 12px;
    font-size: 13px;
    max-width: 180px;
  }

  .spn_usdt {
    font-size: 13px;
  }
  .coin_list p {
    margin-right: 2px;
  }

  .coin-item {
    padding: 4px;
  }

  .coin-item {
    margin: 0px 5px 5px 0px;
  }

  .faq-section {
    margin-top: 30px;
    margin-left: 0px;
  }

  .deposit_table_title {
    font-size: 20px;
    padding-top: 30px;
  }

  .faq_list {
    padding: 12px;
  }

  /* Staking Page */

  .staking_banner_section .right_bg {
    display: none;
  }

  .staking_banner_section .left_bg {
    display: none;
  }

  .banner_left_content {
    padding-left: 0px;
  }

  .staking_banner_section {
    display: flex;
    flex-direction: column;
  }

  .banner_left_content h2 {
    font-size: 36px;
  }

  .banner_left_content p {
    font-size: 14px;
  }

  .detail_btn {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
  }

  .faq_btn {
    font-size: 13px;
  }

  .staking_right_section {
    max-width: 225px;
    margin: 15px 22px;
  }

  .stak_asset {
    font-size: 13px;
  }

  .asset_myholdings {
    font-size: 13px;
  }

  .tutorial-step1.stack_card {
    height: 190px;
  }

  .search_checkbox {
    flex-direction: column;
  }

  .staking_table_heading {
    font-size: 26px;
    line-height: 35px;
  }

  .search-box-content input {
    font-size: 13px;
  }

  .staking_input span {
    font-size: 13px;
  }

  .p2p_table {
    margin-top: 30px;
  }

  .card-header.collapsed.p2p {
    padding: 22px 4px;
  }

  .card-title.p2p {
    font-size: 13px;
  }
  .lrtcss-ac8m87 {
    padding: 0px 2px 0px;
  }
  table thead .market-header.deposit th {
    min-width: 0px;
  }

  .banner_left_content.refer h2 {
    font-size: 25px;
  }

  .banner_left_content.refer h4 {
    font-size: 13px;
  }

  .learn_green_btn span {
    font-size: 13px;
  }
  .ref-settings_text {
    font-size: 13px;
  }

  .settings_box .settings_box_header {
    padding: 12px;
  }

  .commission_rate_text {
    font-size: 13px;
  }

  .commission_rate_content {
    font-size: 13px;
  }
  .bottom_box {
    padding: 10px;
  }

  .commission_rate.ref_link input {
    font-size: 13px;
    text-align: center;
    margin-bottom: 12px;
  }

  .commission_rate.ref_link {
    padding: 18px 5px;
  }

  .copy_input_text {
    font-size: 13px;
    flex-grow: 1;
  }
  .earning_card_1 {
    margin-top: 15px;
  }

  .commission_rate_percentage {
    flex-direction: column;
  }
  .commission_rate.ref_link {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  .start_end_date {
    flex-direction: column;
    align-items: end;
  }

  .pop_up_heading {
    font-size: 32px;
  }

  .launch_popup_content h2 {
    font-size: 32px;
  }

  .launch_popup_content p {
    font-size: 14px;
  }

  .buy_button {
    padding: 8px 50px;
    font-size: 14px;
  }

  .subscription_text h3 {
    font-size: 16px;
  }
}

.-green span,
.-green svg {
  color: rgb(55 147 47) !important;
}

.-red span,
.-red svg {
  color: rgb(194 30 30) !important;
}
.react-tel-input .form-control {
  padding-left: 3rem !important;
}

.curpoint {
  cursor: pointer !important;
}

.input_section_kyc {
  align-items: center;
  background: #0b0b18;
  border-radius: 10px;
  color: #6c757d;
  display: flex;
  border: 1px solid #32323d;
  height: 55px;
  justify-content: center;
  padding: 14px;
  position: relative;
  width: 100%;
}
.input_section_kyc input {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
.input_section_kyc img {
  height: auto;
  width: 100%;
  max-height: 100%;
}

.Accordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #0b0b18 !important;
  padding: 0px !important;
}

.Accordion .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  color: rgb(135 144 159) !important;
}

.Accordion .css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: rgb(135 144 159) !important;
}

.Accordion .MuiAccordionDetails-root {
  background: #0b0b18;
}

.height_100 {
  min-height: 100vh !important;
}

.dark .swap-convert.bg-black {
  background-color: #000 !important;
}

.Qrdesign {
  background: #fff !important;
  padding: 10px;
  border-radius: 10px;
}
.nav_brand img {
  height: 100%;
  width: 190px;
  padding: 5px 16px !important;
}

.link_tage,
.link_tage:hover {
  color: #fff;
  padding: 5px 16px !important;
  font-weight: 500;
  font-size: 20px;
}

.link_tage i {
  margin-right: 11px;
}

.link_tage,
.link_tage:hover {
  width: 100%;
  display: block;
}

.link_tage,
.link_tage:hover {
  font-size: 17px;
}
/* .MuiButtonBase-root{
  width: 100% !important;
  justify-content: flex-start !important;
} */

.drawclass .MuiButtonBase-root {
  width: 100% !important;
  justify-content: flex-start !important;
}
