.min-height-100vh {
  min-height: 100vh;
  margin-top: 65px;
}

.trade_page_global {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background-color: #060612;
  display: grid;
  gap: 1px;
  grid-template:
    "header header header header header" minmax(0px, auto)
    "left subHeader subHeader market right" 68px
    "left orderbook chart market right" 360px
    "left orderbook chart trades right" 160px
    "left orderbook orderform trades right" minmax(169px, 1fr)
    "left orderbook orderform marketActivity right" minmax(146px, auto)
    "left basictable basictable basictable right" 250px
    "footer footer footer footer footer" 24px / 1fr minmax(253px, 320px)
    minmax(510px, 880px) minmax(253px, 320px) 1fr;
}
.subHeader {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 100%;
  width: 100%;
  background-color: #181c2a7d;
  grid-area: subHeader;
  font-size: 14px;
  color: rgb(234, 236, 239);
  padding-top: 10px;
  padding-bottom: 10px;
}
.subHeader .inner_sub {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}
.subHeader .inner_sub .content {
  min-width: 0px;
  display: flex;
  box-sizing: border-box;
  margin: 0px;
  flex: 1 1;
  flex-direction: row;
}
.subHeader .inner_sub .content .left {
  display: flex;
  box-sizing: border-box;
}
.css-1qkq70v .left .layout {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0px;
  flex: 0 0 auto;
}
.subHeader .inner_sub .content .left .layout .childrenContainer {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
}
.css-l36dyj {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  height: auto;
  justify-content: space-around;
  border-right: 0px solid rgb(37, 41, 48);
  margin-right: 24px;
  margin-left: 4px;
  height: 48px;
  border-right-width: 1px;
}
.subHeader .inner_sub .content .left .nowPrice {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
.css-13n52y {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(14, 203, 129);
}
.subHeader .inner_sub .content .left .nowPrice .showPrice {
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.subHeader .inner_sub .content .left .nowPrice .subPrice {
  font-size: 12px;
  color: rgb(234, 236, 239);
  line-height: inherit;
}

.css-10nf7hq {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-as916g {
  box-sizing: border-box;
  margin: 0px 0px 0px 4px;
  min-width: 0px;
  padding-left: 3px;
  padding-right: 3px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  font-weight: 400;
  color: rgb(240, 185, 11);
  background-color: rgb(54, 50, 29);
  display: inline-block;
  cursor: pointer !important;
}
.css-4cffwv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
}
.css-as916g .tag-text {
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.css-as916g .tag-text .tag {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.css-as916g .tag-margin {
  color: rgb(240, 185, 11);
  opacity: 0.25;
  margin-left: 5px;
  margin-right: 5px;
}
.css-omng2l {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: currentcolor;
  font-size: 12px;
  fill: currentcolor;
  width: 1em;
  height: 1em;
}
.css-4h6mys {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
}
.css-f4kgqr a {
  color: #999;
}
.subHeader .inner_sub .content.right {
  flex: 1 1 0%;
  flex-flow: row;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-width: 0px;
  overflow: hidden;
}
.subHeader .inner_sub .right .tickerListContainer {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin-left: 32px;
  margin-right: 8px;
  overflow: hidden;
}
.subHeader .inner_sub .right .tickerListContainer .tickerList {
  white-space: nowrap;
  overflow: auto hidden;
}

.subHeader .inner_sub .right .tickerListContainer .tickerList > div {
  display: inline-block;
  padding-right: 1.4vw;
}
.layout h1 {
  font-size: 20px;
  margin: 0;
}
.layout {
  display: flex;
}
.tickerPriceText span {
  display: flex;
}

.subHeader .inner_sub .right {
  flex: 1 1 0%;
  flex-flow: row;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-width: 0px;
  overflow: hidden;
  flex-grow: 1;
  position: static;
}

.subHeader .inner_sub .right .tickerListContainer .tickerList .tickerItemLabel {
  font-size: 12px;
  margin-right: 0px;
  margin-bottom: 2px;
  color: rgb(132, 142, 156);
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  border-bottom: 1px solid transparent;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.subHeader .inner_sub .right .tickerListContainer .tickerList .tickerPriceText {
  font-size: 12px;
  color: rgb(234, 236, 239);
}
.price_red {
  color: rgb(246, 70, 93);
}

.order_form {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  grid-area: orderform;
  background-color: rgb(30, 32, 38);
}

.order_book {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  background-color: #181c2a7d;
  grid-area: orderbook;
  position: relative;
  padding-top: 16px;
  padding-bottom: 12px;
}
.chart_trade {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  background-color: #181c2a7d;
  grid-area: chart;
  position: relative;
}
.trades {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  grid-area: trades;
  /* padding-left: 16px;
  padding-right: 16px; */
  padding-bottom: 16px;
  background-color: #181c2a7d;
  z-index: auto;
  position: relative;
  overflow: hidden;
}
.markets {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding-top: 16px;
  width: 100%;
  height: 100%;
  background-color: #181c2a7d;
  grid-area: market;
}
.basictable {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #181c2a7d;
  grid-area: basictable;
  font-size: 14px;
  color: rgb(234, 236, 239);
  flex-direction: column;
  overflow: hidden;
}
.orderbook-header {
  -webkit-box-align: center;
  align-items: center;
  margin-left: 12px;
  margin-right: 16px;
  margin-bottom: 8px;
  position: relative;
  -webkit-box-pack: initial;
  justify-content: initial;
}
.orderbook-header .orderbook-header-tips {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.orderbook-header .orderbook-header-tips button {
  margin: 0px 4px 0px 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  outline: none;
  line-height: 20px;
  padding: 0px 4px;
  border: none;
  border-radius: 2px;
  width: 36px;
  min-width: auto;
  height: 32px;
  background-color: transparent;
  opacity: 1;
  /* flex: 1 1 0%; */
}
.orderbook-header .orderbook-header-tips button svg {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: currentcolor;
  font-size: 24px;
  fill: currentcolor;
  width: 1em;
  height: 1em;
}
.orderbook-header .orderbook-header-tips button svg {
  color: rgb(132, 142, 156);
}

.market_order table {
  width: 100%;
}

.market_order {
  padding: 0px 16px;
}
.market_order table {
  width: 100%;
}
.market_order table th {
  color: rgb(132, 142, 156);
  font-size: 13px;
  font-weight: 400;
}

.market_order table {
  table-layout: fixed;
}
.market_order th {
  font-weight: bold;
}
.market_order th,
.market_order td {
  text-align: right;
  color: rgb(183, 189, 198);
  font-weight: 400;
  font-size: 13px;
}
.market_order article {
  height: auto;
  display: flex;
  flex-direction: column;
}
.market_order .divider {
  background: #0f111e;
}
.market_order .orderbook-header {
  background: transparent;
}
.market_order .current-price {
  font-weight: 400;
  font-size: 16px;
  padding: 6px 0px;
  color: #fff;
}
.market_order .side {
  height: 50%;
  overflow-y: auto;
}
.market_order .price.sell {
  color: rgb(246, 70, 93);
  text-align: left;
}
.market_order .price.buy {
  color: #0c0;
  text-align: left;
}
.market_order table th.price {
  text-align: left;
}

::-webkit-scrollbar {
  display: none;
}

.current-price span {
  color: rgb(132, 142, 156);
  font-size: 13px;
  margin-left: 10px;
}
.order_form .nav-pills {
  display: flex;
  background: #0f111e;
}
.order_form .nav-pills li {
  display: flex;
}
.order_form .nav-pills li a,
.order_form .nav-pills li a:hover {
  display: flex;
  padding: 11px 30px;
  text-transform: capitalize;
  color: rgb(132, 142, 156);
  text-decoration: none !important;
}
.order_form .nav-pills li a.active {
  display: flex;
  padding: 11px 30px;
  border-top: 3px solid #5841d8;
  color: #fff;
  background: #1e2026;
}
.tradeform ul.nav {
  background: transparent;
  margin-top: 10px;
}
.tradeform ul.nav a,
.tradeform ul.nav a:hover {
  padding: 0px;
  border: none;
  font-size: 14px;
  padding: 0px 10px;
}
.buy_BTN button,
.buy_BTN button:hover {
  text-transform: capitalize;
  background: rgb(14, 203, 129);
  color: #fff;
  width: 100%;
  font-weight: 400;
}
.buy_BTN button.Sell {
  background: rgb(246, 70, 93);
}
.slider_spacing {
  padding: 0px 10px;
}
.tradeform ul.nav a.active {
  color: #8570ff;
  border: none;
  font-size: 14px;
  padding: 0px 10px;
}
.form_trade {
  padding: 10px 10px;
  padding-bottom: 15px;
}
.form_trade .avali {
  color: #a1a7b0;
  font-size: 13px;
  margin-bottom: 10px;
}
.form_trade .avali p {
  margin: 0;
}
.form_trade .avali p span {
  margin-left: 5px;
  color: #fff;
}

.form_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0px solid #999;
  border-radius: 5px;
  margin-top: 5px;
  padding: 0px 8px;
  background: #2a2d35;
  margin-bottom: 12px;
}
.form_right label {
  margin: 0;
  color: rgb(132, 142, 156);
  font-weight: 300;
  font-size: 14px;
  font-weight: 400;
}
.form_right .input_section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.form_right .input_section input {
  background: transparent;
  border: none;
  color: #fff;
  height: 40px;
  flex-grow: 1;
  text-align: right;
}
.form_right .input_section span {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  margin-left: 5px;
}
.form_seldect__pair {
  padding: 10px;
}
.form_seldect__pair .searcj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(43, 49, 57);
  padding: 0px 7px;
  gap: 9px;
  border-radius: 5px;
}
.searcj i {
  color: rgb(94, 102, 115);
}
.form_seldect__pair .searcj input {
  height: 18px;
  font-size: 14px;
  flex-grow: 1;
  background: transparent;
  border: none;
  color: #fff;
}
.form_seldect__pair ul {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}
.form_seldect__pair ul li a,
.form_seldect__pair ul li a:hover {
  color: rgb(132, 142, 156);
  font-size: 13px;
  text-decoration: none;
  padding: 1px 5px;
  border-radius: 2px;
}
.form_seldect__pair ul li a.active {
  color: rgb(234, 236, 239);
  background: rgb(71, 77, 87);
  font-weight: 300;
}
.pair_details .fixTableHead {
  overflow-y: auto;
  height: 340px;
}
.pair_details .fixTableHead thead th {
  position: sticky;
  top: 0;
}
.pair_details table {
  border-collapse: collapse;
  width: 100%;
}
.pair_details th {
  background: transparent;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-weight: 400;
  /* text-align: center !important; */
}
.pair_details th {
  background: #0f111e;
}
.pair_details p {
  font-size: 12px !important;
  font-weight: 500 Im !important;
  color: #fff !important;
}
.pair_details p span {
  color: #848e9c;
}
.pair_details td {
  font-size: 12px;
  color: rgb(183, 189, 198);
  font-weight: 300;
  padding: 3px 0px;
}
.text-green {
  color: rgb(14, 203, 129);
}

.trades .form_seldect__pair ul li a.active {
  background: transparent;
  font-weight: 400;
}
.trades .pair_details .fixTableHead {
  overflow-y: auto;
  height: 290px;
}

.basictable.tradeform ul.nav a,
.basictable.tradeform ul.nav a:hover {
  padding: 0px;
  border: none;
  font-size: 14px;
  text-decoration: none;
  padding: 0px 10px;
  color: rgb(132, 142, 156);
}
.basictable.tradeform ul.nav a.active {
  color: #8570ff;
}
.green_content tr {
  position: relative;
}
.green_content tr::before {
  position: absolute;
  content: "";
  height: 30px;
  background: #daefe1;
  right: 0;
  left: auto;
  width: attr(data-width);
  display: none;
}
.pading_oedar {
  padding: 10px;
  padding-top: 0;
}
.pair_details.pading_oedar td {
  font-size: 13px;
  color: rgb(183, 189, 198);
  font-weight: 400;
  padding: 10px 0px;
  text-align: center;
  border-bottom: 1px solid #cccccc21;
}
.pair_details.pading_oedar th {
  background: #0f111e;
  padding: 4px 0px;
  font-size: 13px;
  text-align: center;
  border-bottom: 1px solid #cccccc21;
}
.pair_details.pading_oedar tbody tr:hover {
  background-color: rgb(43, 49, 57);
}
.pair_details.pading_oedar tbody tr {
  transition: all 0.2s ease-in 0s;
}

.pair_details.pading_oedar tbody tr:hover > td {
  border-color: transparent;
}

.text-red {
  color: rgb(246, 70, 93);
}

.css-1bea9fq {
  box-sizing: border-box;
  margin: -1px 0px 0px;
  min-width: 0px;
  grid-area: footer;
  background-color: #181c2a;
  padding: 0px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(37, 41, 48);
  border-right-color: rgb(37, 41, 48);
  border-bottom-color: rgb(37, 41, 48);
  border-left-color: rgb(37, 41, 48);
}
.css-1bea9fq.footer-sticky {
  position: fixed;
  width: 100%;
  z-index: 10;
  bottom: 0px;
  padding: 4px 0px;
}
.css-fnk7g1 .previewItemWrap .change {
  font-size: 12px;
  margin-right: 8px;
}
.css-fnk7g1 .previewItemWrap .sell {
  color: rgb(246, 70, 93);
}
.css-1vrz22u {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 138px;
}
.css-19fju8h {
  box-sizing: border-box;
  margin: 0px 0px 0px 10px;
  min-width: 0px;
  color: rgb(14, 203, 129);
  font-size: 12px;
}
.css-14fo47n {
  box-sizing: border-box;
  margin: 0px 0px 0px 32px;
  min-width: 0px;
  flex: 1 1 0%;
  display: flex;
  height: 16px;
  -webkit-box-align: center;
  align-items: center;
}
.css-14fo47n .foot-line {
  margin-top: 3px;
  width: 1px;
  height: 10px;
  background-color: rgb(71, 77, 87);
}
.css-18shdj7 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  outline: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-1aebr02 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-15gs5hm {
  box-sizing: border-box;
  margin: 2px 0px 0px 16px;
  min-width: 0px;
  color: rgb(132, 142, 156);
  font-size: 16px;
  fill: rgb(132, 142, 156);
  width: 1em;
  height: 1em;
}
.css-7tgotf {
  box-sizing: border-box;
  margin: 0px 0px 0px 2px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  overflow: hidden;
}
.css-7tgotf .animation {
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: animation-1hgl3te;
  animation-delay: 2s;
}
.css-7tgotf .container11 {
  display: flex;
  flex-wrap: nowrap;
  animation-duration: 431s;
}
.css-fnk7g1 {
  box-sizing: border-box;
  min-width: 0px;
  display: flex;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
@-webkit-keyframes animation-1hgl3te {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes animation-1hgl3te {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.css-fnk7g1 .previewItemWrap {
  margin-left: 8px;
  display: flex;
  cursor: pointer;
}
.css-fnk7g1 .previewItemWrap {
  margin-left: 8px;
  display: flex;
  cursor: pointer;
}
.css-fnk7g1 .previewItemWrap .symbol {
  color: rgb(183, 189, 198);
  font-size: 12px;
  margin-right: 8px;
}
.css-fnk7g1 .previewItemWrap .change {
  font-size: 12px;
  margin-right: 8px;
}
.css-fnk7g1 .previewItemWrap .buy {
  color: rgb(14, 203, 129);
}
.css-fnk7g1 .previewItemWrap .lastPrice {
  font-size: 12px;
  color: rgb(132, 142, 156);
  margin-right: 12px;
}
.css-14fo47n .foot-line {
  margin-top: 3px;
  width: 1px;
  height: 10px;
  background-color: rgb(71, 77, 87);
}
.markert_activity {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  background-color: #181c2a7d;
  grid-area: marketActivity;
}

.css-13fs1br {
  box-sizing: border-box;
  margin: 0px 16px;
  min-width: 0px;
  display: flex;
  position: relative;
  z-index: 1;
  padding-top: 12px;
  padding-bottom: 10px;
  height: 40px;
  font-size: 14px;
  color: rgb(234, 236, 239);
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 600;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
}
.markert_activity ul.nav {
  padding: 0px 16px;
  gap: 6px;
}
.markert_activity ul.nav a {
  display: flex;
  font-size: 13px;
  color: #a0a6af;
  padding: 0px 0px;
  text-decoration: none;
  font-weight: 300;
}
.markert_activity ul.nav a.active {
  color: #5841d8;
}
.css-14d05gv:hover {
  text-decoration: none;
}
.css-14d05gv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  height: 41px;
  flex: 0 0 auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-decoration: none;
  border-bottom: 1px solid rgb(31, 33, 36);
  border-top-color: rgb(31, 33, 36);
  border-right-color: rgb(31, 33, 36);
  border-left-color: rgb(31, 33, 36);
  text-decoration: none;
}
.pading_tabs_content {
  padding: 1px 16px;
}
.css-1pysja1 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
}
.css-qt6vj7 {
  box-sizing: border-box;
  margin: 0px 0px 2px;
  min-width: 0px;
  color: rgb(132, 142, 156);
  font-size: 12px;
}
.css-qt6vj7 {
  box-sizing: border-box;
  margin: 0px 0px 2px;
  min-width: 0px;
  color: rgb(132, 142, 156);
  font-size: 12px;
}
.css-1iqe90x {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(234, 236, 239);
}
.css-21cgr0 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(132, 142, 156);
  font-size: 12px;
}
.css-m3c6zl {
  box-sizing: border-box;
  margin: 0px;
  flex: 1 1 0%;
  text-align: right;
  overflow: hidden;
  min-width: 96px;
}
.css-4na7jw {
  box-sizing: border-box;
  margin: 0px 0px 2px;
  min-width: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(14, 203, 129);
  font-size: 12px;
}
.css-wk8c7j {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(234, 236, 239);
  font-size: 12px;
  padding-bottom: 1px;
}
.css-vjdxdv {
  box-sizing: border-box;
  margin: 0px;
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: end;
  justify-content: flex-end;
  min-width: 54px;
}
.css-ao5z3i {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  position: relative;
  width: 54px;
  height: 24px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.css-1qlplmi {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgb(14, 203, 129);
  border-radius: 4px;
  opacity: 0.15;
}
.css-3kwgah {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: currentcolor;
  font-size: 24px;
  fill: currentcolor;
  width: 1em;
  height: 1em;
}
.css-ao5z3i > svg {
  color: rgb(14, 203, 129);
  fill: rgb(14, 203, 129);
  font-size: 32px;
}
@media only screen and (max-width: 768px) {
  .trade_page_global {
    /* Make the grid 1 column when the screen width is 768px or less */
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-areas:
      "header"
      "left"
      "subHeader"
      "orderbook"
      "chart"
      "market"
      "trades"
      "orderform"
      "marketActivity"
      "basictable"
      "footer";
  }
  .pair_details.pading_oedar td {
    min-width: 130px;
  }
}

@media only screen and (max-width: 480px) {
  /* Make the header smaller when the screen width is 480px or less */
  header {
    grid-area: header;
    height: 50px;
  }
  .pair_details.pading_oedar td {
    min-width: 130px;
  }

  /* Make the grid items smaller when the screen width is 480px or less */
  #left {
    grid-area: left;
  }
  #subHeader {
    grid-area: subHeader;
  }
  #market {
    grid-area: market;
  }
  #right {
    grid-area: right;
  }
  #orderbook {
    grid-area: orderbook;
  }
  #chart {
    grid-area: chart;
  }
  #trades {
    grid-area: trades;
  }
  #orderform {
    grid-area: orderform;
  }
  #marketActivity {
    grid-area: marketActivity;
  }
  #basictable {
    grid-area: basictable;
  }
  #footer {
    grid-area: footer;
  }

  #left,
  #subHeader,
  #market,
  #right,
  #orderbook,
  #chart,
  #trades,
  #orderform,
  #marketActivity,
  #basictable {
    font-size: 14px;
    padding: 8px;
  }
}
