* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap");

body {
  font-family: "Martel Sans", sans-serif;
}

body {
  background: #fff;
  /* height: 100vh; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.MuiButton-root {
  font-family: "Martel Sans", sans-serif;
}

html {
  height: 100%;
}

.contsnt h1 {
  font-size: 41px;
  color: #1e2329;
  font-family: "Martel Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 11px;
}

.contsnt h2 {
  font-size: 23px;
  margin-bottom: 16px;
}

.contsnt h1 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contsnt p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1e2329;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 0px;
}

.contsnt p img {
  margin-right: 6px;
}

.contsnt p i {
  display: flex;
  margin-left: 5px;
}

.text-decoreatioen {
  text-decoration: none !important;
}

.contsnt small {
  font-size: 16px;
  color: #888b90;
  margin-top: 13px;
  display: block;
}

header {
  border-bottom: none !important;
  background: #fff !important;
  border-bottom: none !important;
  box-shadow: none !important;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.16) !important; */
}

.contsnt {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

button.primery_color,
button.primery_color:hover {
  color: #fff;
  padding: 17px 34px;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
  margin-top: 22px;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  width: 100%;
  background: #fcd535 !important;
  color: #000;
  background: linear-gradient(180deg, #0bd3f0 0%, #0c2af4 100%) !important;
  color: #fff !important;
}

.nav_link_section a {
  font-size: 15px;
  color: rgb(29 29 29 / 73%);
  padding: 0px 12px;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.3s;
}

.nav_link_section a:hover {
  color: #0b49f3;
}

.nav_link_section {
  /* margin-left: auto; */
}

.header_btn {
  font-family: var(--main-Medium);

  text-transform: capitalize !important;
  padding: 8px 15px !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  color: #fff !important;
}

.header_btn:hover {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  padding: 8px 15px !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  color: #fff !important;
}

.header_padidng {
  padding: 0px 0px;
}

.imag img {
  width: 150px;
  margin-right: 15px;
}

.banner_bg {
  background-image: url(./Image/stars-second.svg);
  /* background-color: #000; */
  /* height: 100vh; */
  padding: 30px 0px;
  background-size: cover;
}

.secode_beee {
  background-image: url(./Image/gold_filter.png);
  background-size: cover;
  /* height: 100vh; */
  position: relative;
}

.secode_beee::before {
  background-image: url(./Image/slider_coin.png);
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  position: absolute;
  background-size: 100% 100%;
  top: 71px;
  right: 0;
  animation: float 6s ease-in-out infinite;
}

.secode_beee::after {
  background-image: url(./Image/slider_coin.png);
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  background-size: 100% 100%;
  bottom: 40px;
  left: 0;
  animation: float 6s ease-in-out infinite;
}

.banner_content {
  position: relative;
  z-index: 555;
  margin: 30vh 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;
}

.banner_content span {
  font-size: 40px;
  color: #ffeb3c;
  font-weight: 800;
}

.banner_content h1 {
  font-family: "Jost", sans-serif !important;
  color: #ffeb3c;
  font-size: 131px;
  /* line-height: 59px; */
  margin-bottom: 25px;
  /* margin-top: -44px; */
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  color: transparent;
  -webkit-background-clip: text;
}

.conatnt_seciotnt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.conatnt_seciotnt > div {
  color: #fff;
  width: 80px;
  font-size: 68px;
  height: 80px;
  display: grid;
  place-content: center;
  border: 4px solid #ffeb3c;
  border-radius: 9px;
  margin: 22px 5px;
  margin-top: 12px;
}

.conatnt_seciotnt > div:first-child {
  margin-left: 0;
}

.banner_content button {
  font-size: 20px !important;
  padding: 5px 36px !important;
  margin-top: 10px;
}

.banner_ing {
  position: relative;
  height: 501px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 134px 0px;
  margin-bottom: 0;
}

.banner_ing img {
  width: 100%;
  margin: 0 auto;
  display: block;
  /* margin-bottom: -126px; */
}

.enterer {
  color: #ffeb3c;
  position: absolute;
  bottom: -55px;
  font-size: 83px;
  font-weight: 600;
  transform: rotate(-12deg);
}

.banner_content p {
  text-align: left;
  color: #fff;
  font-size: 20px;
}

.section_secode_bg {
  background-color: transparent;
  padding: 60px 0px;
  position: relative;
  background-image: url(./Image/stars-second.svg);
  background-size: cover;
  padding-bottom: 0;
  background-attachment: fixed;
}

.section_secode_bg::before {
  position: absolute;
  content: "";
  background-image: url(./Image/Orange.png);
  height: 500px;
  width: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0px;
  -webkit-animation: blink-2 1.5s infinite both;
  animation: blink-2 1.5s infinite both;
}

/* .section_secode_bg::after {
  position: absolute;
  content: "";
  background-image: url(./Image/bro.png);
  height: 318px;
  width: 604px;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  right: 0;
} */
.blink {
  animation: blink 0.5s linear infinite;
}

.image_coin {
  margin: 0 !important;
  position: absolute;
  width: 81px !important;
  top: 33px;
  left: 128px;
  animation: float 4s ease-in-out infinite;
}

.right {
  right: 55px;
  left: auto;
  top: 115px;
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.bounce-top {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 1.5s infinite both;
  animation: wobble-hor-bottom 1.5s infinite both;
}

@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

.blink-1 {
  -webkit-animation: blink-1 1s infinite both;
  animation: blink-1 1s infinite both;
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.shake-horizontal {
  -webkit-animation: shake-horizontal 1.6s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.scale {
  width: 100%;
  transform: scale(0.9);
}

.zoome {
  animation: zoome 2s linear infinite;
}

@keyframes zoome {
  0% {
    transform: scale(0.7);
  }

  25% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.7);
  }
}

.second_ticket_content {
  padding: 6px 0px;
}

.second_ticket_content h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
}

.second_ticket_content p {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.second_ticket_content button.header_btn {
  padding: 8px 30px !important;
  font-weight: 700 !important;
  font-size: 16px;
  margin: 0 auto;
  display: block;
  margin-top: 30px !important;
}

.price_table {
  background: #000;
  border-radius: 20px;
  overflow: hidden;
  margin: 50px 50px;
}

.price_row {
  display: flex;
  border-bottom: 1px solid #ccc6;
  position: relative;
}

.price_col {
  width: 50%;
  display: grid;
  place-content: center;
  padding: 15px 0px;
}

.price_col p {
  margin: 0;
  font-weight: 800;
  color: #999;
  font-size: 19px;
}

.colwhite {
  color: #fff !important;
}

.bor_right {
  position: relative;
}

.bor_right::after {
  position: absolute;
  content: "";
  height: 46px;
  width: 1px;
  background-color: #ccc6;
  right: 0;
  top: 7px;
}

.inner_row {
  display: flex;
}

.inner_row .inner_col {
  width: 50%;
  display: grid;
  place-content: center;
  background: #212121;
  padding: 15px 0px;
}

.inner_row span {
  color: #fff;
  font-size: 19px;
}

.bordr-non {
  border: none;
}

/* .inner_section {
  background-image: url(./Image/inner_bg_1.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
} */
.buy_ticket_section h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
}

.buy_ticket_section .card_buy_ticket {
  background: #000;
  border-radius: 10px;
  padding: 0px 0px;
  border: 5px solid #ffeb3c;
}

.buy_ticket_section .card_buy_ticket .card_header {
  margin-bottom: 10px;
}

.card_buy_ticket {
  margin-top: 40px;
}

.buy_ticket_section .card_buy_ticket .card_header .flex_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffeb3c;
  border-radius: 0;
  padding: 9px 10px;
}

.buy_ticket_section .card_buy_ticket .card_header .flex_header h4 {
  margin: 0;
  color: #000;
  font-weight: 700;
  font-size: 20px;
  padding: 10px 0px;
}

.buy_ticket_section .card_buy_ticket .card_header .flex_header span {
  color: #000;
  font-weight: 500;
  font-size: 15px;
}

.card_body_ {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card_body_ span {
  color: #fff;
  font-weight: 800;
  font-size: 25px;
  margin-bottom: 10px;
}

.card_body_ h5 {
  margin: 0;
  color: #ffeb3c;
  font-weight: 800;
  font-size: 45px;
  margin-bottom: 20px;
}

.card_body_ > button {
  border-radius: 5px !important;
  font-weight: 800 !important;
  /* margin-right: 10px !important; */
}

.inner_section_2 {
  /* background-image: url(./Image/inner_bg_2.png); */
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  padding: 173px 0px;
  position: relative;
}

.inner_section_2::before {
  position: absolute;
  content: "";
  background-image: url(./Image/slider_coin.png);
  height: 200px;
  width: 200px;
  position: absolute;
  background-size: 100% 100%;
  top: 40px;
  animation: float 6s ease-in-out infinite;
  left: 0;
}

.winnwrr_list h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  margin-bottom: 40px;
  text-align: center;
}

.winnwrr_list .card_table {
  background: #000;
  padding: 20px 0px;
  border: 5px solid #ffeb3c;
  border-radius: 10px;
  padding-top: 0;
}

.winnwrr_list .card_table .nav-tabs {
  border-bottom: 1px solid #efefef38;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffeb3c;
}

.winnwrr_list .tab-content {
  padding: 0px 25px;
}

.winnwrr_list .card_table .nav-tabs li {
  display: flex;
}

.winnwrr_list .card_table .nav-tabs li a {
  padding: 15px 20px;
  text-align: center;
  color: #525254;
  text-decoration: none;
  margin-bottom: -1px;
  font-weight: 600;
}

.winnwrr_list .card_table .nav-tabs li a.active {
  color: #000;
  border-bottom: 5px solid #000;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6 !important;
  color: #6c7080;
}

.table td,
.table th {
  padding: 17px 0px;
  vertical-align: top;
  border-top: 1px solid #dee2e63d !important;
}

.table td {
  color: #fff;
}

.three_section {
  padding: 110px 0px;
  background-image: url(./Image/stars-second.svg);
  background-size: cover;
  padding-top: 0;
}

.four_section {
  background-size: cover;
  background-image: url(./Image/stars-second.svg);
}

#main {
  margin: 50px 0;
}

#main #faq .card {
  margin-bottom: 14px;
  border: 0;
  background: transparent;
  overflow: hidden;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #302f2f;
  color: #fff;
  padding: 20px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #302f2f;
  color: #fff;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapsing {
  background: #302f2f;
  line-height: 30px;
  color: #fff;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #302f2f;
  line-height: 30px;
  color: #fff;
}

.Faq_section h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  margin-bottom: 40px;
}

.four_section {
  padding: 100px 0px;
}

.img_faq {
  padding: 136px 0px;
}

.img_faq img {
  margin: 0 auto;
  display: block;
  width: 80%;
}

.logo_section_f img {
  width: 165px;
  margin-bottom: 10px;
}

.logo_section_f p {
  color: #000;

  font-size: 17px;
}

.link_section h4 {
  color: #000;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

.link_section ul {
  list-style: none;
}

.link_section ul a {
  color: #000;
  text-decoration: none;
  padding: 7px 0px;

  display: block;
}

.link_section ul a:hover {
  color: #0b49f3;
}

footer {
  padding: 50px 0px;
  background: #0d0e0f;
  padding-bottom: 0;
  border-top: 1px solid #222429;
}

.email_form {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin-bottom: 11px;
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0px 10px 25px #e4e7ec;
  padding: 3px 8px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.email_form input {
  flex-grow: 1;
  background: #f8f8f9;
  border: none;
  color: #000;
  height: 50px;
  padding: 0px 10px;
  background: transparent;
}

.email_form input:focus-visible {
  outline: none;
}

.email_form button {
  background: #0b49f3;
  height: 42px;
  padding: 0px 11px;
  font-size: 15px;
  margin: 0;
  border: none;
  color: #fff;
  font-weight: 500;
  border-radius: 40px;
}

.note_footer {
  color: #999;
}

.social {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0px;
}

.social a {
  color: #0b6dff;
  font-size: 25px;
  padding: 0px 15px !important;
  padding-left: 0 !important;
}

.social a:hover {
  text-decoration: none;
}

.social a:first-child {
  padding-left: 0;
}

.copywrigt p {
  margin: 0;
  color: #999;
  text-align: left;
  text-align: center;
}

.copywrigt {
  padding: 15px 0px;
  margin-top: 15px;
  border-top: 0px solid #66656542;
}

.modal {
  z-index: 50000000;
}

.modal-content {
  background-color: #302f2f;
}

.modal-title {
  color: #fff;
}

.modal-header .close {
  color: #fff;
}

.modal-header {
  border-bottom: 1px solid #dee2e633;
}

.input_form_tivket {
  padding: 10px 0px;
}

.input_form_tivket p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  margin-bottom: 7px;
  padding: 0px 5px;
}

.input_form_tivket p span {
  font-weight: 800;
  color: #fff;
}

.input_form_tivket input {
  width: 100%;
  height: 61px;
  border-radius: 10px;
  border: none;
  background: #000;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  padding: 0px 5px;
  text-align: right;
}

.input_form_tivket .amount_bust {
  color: #fff;
  text-align: right;
  padding: 9px 5px;
}

.amount_content {
  padding: 0px 7px;
}

.amount_content p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a0a0a0;
  margin-bottom: 10px;
}

.amount_content p span {
  font-weight: 800;
  color: #fff;
}

.youpay {
  padding: 5px 0px;
  border-top: 1px solid #696969;
  margin-bottom: 20px;
}

.ticket_section button.header_btn {
  width: 100%;
  border-radius: 10px !important;
  padding: 9px 0px !important;
  font-size: 17px;
  font-weight: 800;
}

.wallet_option {
  margin: 0px 20px;
  cursor: pointer;
  padding: 20px 0px;
}

.wallet_option:hover {
  background: #000;
  border-radius: 10px;
}

.wallet_option img {
  width: 50px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.wallet_option p {
  margin: 0;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.banner_img {
  min-height: 650px;
  max-height: 650px;
}

.banner_img img {
  object-fit: cover;
  background-size: cover;
  width: 86%;
  margin: 0px auto;
  display: block;
}

.slick-prev {
  left: 25px !important;
  z-index: 555 !important;
}

.slick-next {
  right: 15px !important;
}

.slick-next:before {
  content: "\f054" !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "FontAwesome" !important;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
  content: "\f053" !important;
}

.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;
  background: #302f2f !important;
  border-radius: 38px !important;
}

.refral_contene {
  padding: 50px 0px;
}

.refral_contene h1 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 19px;
}

.refral_contene p {
  color: #999;
  font-size: 19px;
}

.referal_section {
  padding: 100px 0px;
}

.referal_card {
  padding: 20px;
  background: #302f2f;
  border-radius: 10px;
  margin: 40px 0px;
}

.tilet_refer {
  margin-bottom: 14px;
}

.tilet_refer span {
  font-weight: 700;
  color: #fff;
  font-size: 25px;
}

.refer_linlk_ss .input_card_refer {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 10px;
  overflow: hidden;
}

.refer_linlk_ss .input_card_refer input {
  flex-grow: 1;
  height: 50px;
  background: transparent;
  border: none;
  color: #fff;
  padding: 0px 10px;
}

.refer_linlk_ss .input_card_refer i {
  color: #fff;
  padding: 0px 15px;
}

.refer_linlk_ss {
  display: flex;
  justify-content: center;
  align-items: center;
}

.refer_linlk_ss button {
  height: 50px;
  color: #fff;
  background: #000;
  border-radius: 10px;
  margin-left: 8px;
}

.referal_inner_card {
  padding: 11px 11px;
  background: #000;
  margin: 20px 0px;
  border-radius: 13px;
}

.referal_inner_card h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.referal_inner_card p {
  margin: 0;
  font-weight: 900;
  color: #ffeb3c;
  font-size: 34px;
}

.lotery_titlt {
  color: #fff;
  font-size: 33px;
  margin-bottom: 39px;
}

.bg-non {
  background-image: none !important;
}

.card_how_to {
  padding: 35px 27px;
  background: #302f2f;
  border-radius: 20px;
  position: relative;
  margin: 50px 0px;
}

.card_how_to span {
  position: absolute;
  right: -14px;
  font-size: 29px;
  font-weight: 700;
  color: #000;
  background: #ffeb3c;
  padding: 0px 14px;
  width: 50px;
  height: 50px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  top: -25px;
}

.card_how_to h3 {
  color: #fff;
  font-weight: 700;
  font-size: 29px;
}

.card_how_to p {
  margin: 0;
  color: #999;
}

.card_section_new {
  background: #302f2f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin: 0px 20px;
  padding: 14px 0px;
  transition-duration: 0.3s;
  cursor: pointer;
}

.card_section_new:hover {
  border-top: 10px solid #ffeb3c;
  margin-top: -9px;
}

.card_section_new img {
  width: 57px;
  margin-right: 14px;
  filter: invert(92%) sepia(89%) saturate(1325%) hue-rotate(325deg)
    brightness(110%) contrast(102%);
}

.steep {
  margin-top: -85px;
}

.card_section_new p {
  margin: 0;
  color: #fff;
  font-weight: 700;
}

.card_ster {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heade_hoe p {
  color: #fff;
  font-size: 20px;
}

.heade_hoe h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  margin-bottom: 22px;
  text-align: center;
}

.posiotne {
  position: relative;
  padding-top: 0;
}

.animation_section {
  position: absolute;
  width: 100%;
}

.heade_hoe p {
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.howtostep {
  background-image: url(./Image/stars-second.svg);
  background-size: cover;
  background-color: transparent;
}

.purple {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #241379;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  transform: translate3d(28vw, 27vh, 0);
  z-index: 7;
}

.purple::before {
  -webkit-animation: purple-pseudo linear 15s alternate infinite;
  animation: purple-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 20px;
  height: 19px;
  transform: translate3d(-17vw, -11vh, 0) rotate(353deg);
}

.purple::after {
  -webkit-animation: purple-pseudo linear 20s alternate infinite;
  animation: purple-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 18px;
  height: 45px;
  transform: translate3d(59vw, 12vh, 0) rotate(111deg);
}

@-webkit-keyframes purple {
  50% {
    transform: translate3d(47vw, 100vh, 0);
  }

  100% {
    transform: translate3d(9vw, 41vh, 0);
  }
}

@keyframes purple {
  50% {
    transform: translate3d(47vw, 100vh, 0);
  }

  100% {
    transform: translate3d(9vw, 41vh, 0);
  }
}

@-webkit-keyframes purple-pseudo {
  33% {
    transform: translate3d(-45vw, -33vh, 0) rotate(280deg);
  }

  100% {
    transform: translate3d(62vw, 52vh, 0) rotate(123deg);
  }
}

@keyframes purple-pseudo {
  33% {
    transform: translate3d(-45vw, -33vh, 0) rotate(280deg);
  }

  100% {
    transform: translate3d(62vw, 52vh, 0) rotate(123deg);
  }
}

.medium-blue {
  -webkit-animation: medium-blue linear 30s alternate infinite;
  animation: medium-blue linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  transform: translate3d(71vw, 13vh, 0);
  z-index: 5;
}

.medium-blue::before {
  -webkit-animation: medium-blue-pseudo linear 15s alternate infinite;
  animation: medium-blue-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 46px;
  height: 26px;
  transform: translate3d(-50vw, 67vh, 0) rotate(352deg);
}

.medium-blue::after {
  -webkit-animation: medium-blue-pseudo linear 20s alternate infinite;
  animation: medium-blue-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 15px;
  height: 9px;
  transform: translate3d(-51vw, 24vh, 0) rotate(88deg);
}

@-webkit-keyframes medium-blue {
  50% {
    transform: translate3d(26vw, 80vh, 0);
  }

  100% {
    transform: translate3d(50vw, 46vh, 0);
  }
}

@keyframes medium-blue {
  50% {
    transform: translate3d(26vw, 80vh, 0);
  }

  100% {
    transform: translate3d(50vw, 46vh, 0);
  }
}

@-webkit-keyframes medium-blue-pseudo {
  33% {
    transform: translate3d(68vw, 19vh, 0) rotate(23deg);
  }

  100% {
    transform: translate3d(-21vw, -12vh, 0) rotate(254deg);
  }
}

@keyframes medium-blue-pseudo {
  33% {
    transform: translate3d(68vw, 19vh, 0) rotate(23deg);
  }

  100% {
    transform: translate3d(-21vw, -12vh, 0) rotate(254deg);
  }
}

.light-blue {
  -webkit-animation: light-blue linear 30s alternate infinite;
  animation: light-blue linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  transform: translate3d(82vw, 71vh, 0);
  z-index: 3;
}

.light-blue::before {
  -webkit-animation: light-blue-pseudo linear 15s alternate infinite;
  animation: light-blue-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 25px;
  height: 47px;
  transform: translate3d(-60vw, -37vh, 0) rotate(38deg);
}

.light-blue::after {
  -webkit-animation: light-blue-pseudo linear 20s alternate infinite;
  animation: light-blue-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 30px;
  height: 48px;
  transform: translate3d(-53vw, -34vh, 0) rotate(154deg);
}

@-webkit-keyframes light-blue {
  50% {
    transform: translate3d(89vw, 97vh, 0);
  }

  100% {
    transform: translate3d(93vw, 88vh, 0);
  }
}

@keyframes light-blue {
  50% {
    transform: translate3d(89vw, 97vh, 0);
  }

  100% {
    transform: translate3d(93vw, 88vh, 0);
  }
}

@-webkit-keyframes light-blue-pseudo {
  33% {
    transform: translate3d(-58vw, 0vh, 0) rotate(70deg);
  }

  100% {
    transform: translate3d(-3vw, -88vh, 0) rotate(283deg);
  }
}

@keyframes light-blue-pseudo {
  33% {
    transform: translate3d(-58vw, 0vh, 0) rotate(70deg);
  }

  100% {
    transform: translate3d(-3vw, -88vh, 0) rotate(283deg);
  }
}

.red {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  transform: translate3d(41vw, 23vh, 0);
  z-index: 6;
}

.red::before {
  -webkit-animation: red-pseudo linear 15s alternate infinite;
  animation: red-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 44px;
  height: 47px;
  transform: translate3d(-11vw, 31vh, 0) rotate(117deg);
}

.red::after {
  -webkit-animation: red-pseudo linear 20s alternate infinite;
  animation: red-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 46px;
  height: 21px;
  transform: translate3d(10vw, 63vh, 0) rotate(127deg);
}

@-webkit-keyframes red {
  50% {
    transform: translate3d(89vw, 83vh, 0);
  }

  100% {
    transform: translate3d(32vw, 58vh, 0);
  }
}

@keyframes red {
  50% {
    transform: translate3d(89vw, 83vh, 0);
  }

  100% {
    transform: translate3d(32vw, 58vh, 0);
  }
}

@-webkit-keyframes red-pseudo {
  33% {
    transform: translate3d(-39vw, -80vh, 0) rotate(102deg);
  }

  100% {
    transform: translate3d(-18vw, -22vh, 0) rotate(284deg);
  }
}

@keyframes red-pseudo {
  33% {
    transform: translate3d(-39vw, -80vh, 0) rotate(102deg);
  }

  100% {
    transform: translate3d(-18vw, -22vh, 0) rotate(284deg);
  }
}

.orange {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  transform: translate3d(97vw, 4vh, 0);
  z-index: 9;
}

.orange::before {
  -webkit-animation: orange-pseudo linear 15s alternate infinite;
  animation: orange-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 5px;
  height: 49px;
  transform: translate3d(-47vw, 64vh, 0) rotate(72deg);
}

.orange::after {
  -webkit-animation: orange-pseudo linear 20s alternate infinite;
  animation: orange-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 14px;
  height: 5px;
  transform: translate3d(-11vw, 4vh, 0) rotate(15deg);
}

@-webkit-keyframes orange {
  50% {
    transform: translate3d(28vw, 78vh, 0);
  }

  100% {
    transform: translate3d(100vw, 83vh, 0);
  }
}

@keyframes orange {
  50% {
    transform: translate3d(28vw, 78vh, 0);
  }

  100% {
    transform: translate3d(100vw, 83vh, 0);
  }
}

@-webkit-keyframes orange-pseudo {
  33% {
    transform: translate3d(-26vw, -28vh, 0) rotate(321deg);
  }

  100% {
    transform: translate3d(-11vw, 4vh, 0) rotate(246deg);
  }
}

@keyframes orange-pseudo {
  33% {
    transform: translate3d(-26vw, -28vh, 0) rotate(321deg);
  }

  100% {
    transform: translate3d(-11vw, 4vh, 0) rotate(246deg);
  }
}

.yellow {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  transform: translate3d(87vw, 8vh, 0);
  z-index: 9;
}

.yellow::before {
  -webkit-animation: yellow-pseudo linear 15s alternate infinite;
  animation: yellow-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 18px;
  height: 32px;
  transform: translate3d(-34vw, 47vh, 0) rotate(79deg);
}

.yellow::after {
  -webkit-animation: yellow-pseudo linear 20s alternate infinite;
  animation: yellow-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 9px;
  height: 22px;
  transform: translate3d(3vw, 27vh, 0) rotate(181deg);
}

@-webkit-keyframes yellow {
  50% {
    transform: translate3d(65vw, 7vh, 0);
  }

  100% {
    transform: translate3d(5vw, 78vh, 0);
  }
}

@keyframes yellow {
  50% {
    transform: translate3d(65vw, 7vh, 0);
  }

  100% {
    transform: translate3d(5vw, 78vh, 0);
  }
}

@-webkit-keyframes yellow-pseudo {
  33% {
    transform: translate3d(-56vw, 42vh, 0) rotate(321deg);
  }

  100% {
    transform: translate3d(88vw, 19vh, 0) rotate(196deg);
  }
}

@keyframes yellow-pseudo {
  33% {
    transform: translate3d(-56vw, 42vh, 0) rotate(321deg);
  }

  100% {
    transform: translate3d(88vw, 19vh, 0) rotate(196deg);
  }
}

.cyan {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 46px;
  height: 46px;
  transform: translate3d(14vw, 60vh, 0);
  z-index: 11;
}

.cyan::before {
  -webkit-animation: cyan-pseudo linear 15s alternate infinite;
  animation: cyan-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 6px;
  height: 25px;
  transform: translate3d(24vw, -43vh, 0) rotate(352deg);
}

.cyan::after {
  -webkit-animation: cyan-pseudo linear 20s alternate infinite;
  animation: cyan-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 25px;
  height: 11px;
  transform: translate3d(71vw, -55vh, 0) rotate(16deg);
}

@-webkit-keyframes cyan {
  50% {
    transform: translate3d(28vw, 73vh, 0);
  }

  100% {
    transform: translate3d(27vw, 64vh, 0);
  }
}

@keyframes cyan {
  50% {
    transform: translate3d(28vw, 73vh, 0);
  }

  100% {
    transform: translate3d(27vw, 64vh, 0);
  }
}

@-webkit-keyframes cyan-pseudo {
  33% {
    transform: translate3d(28vw, -53vh, 0) rotate(269deg);
  }

  100% {
    transform: translate3d(-9vw, 8vh, 0) rotate(135deg);
  }
}

@keyframes cyan-pseudo {
  33% {
    transform: translate3d(28vw, -53vh, 0) rotate(269deg);
  }

  100% {
    transform: translate3d(-9vw, 8vh, 0) rotate(135deg);
  }
}

.light-green {
  -webkit-animation: light-green linear 30s alternate infinite;
  animation: light-green linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 39px;
  height: 39px;
  transform: translate3d(31vw, 71vh, 0);
  z-index: 11;
}

.light-green::before {
  -webkit-animation: light-green-pseudo linear 15s alternate infinite;
  animation: light-green-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 24px;
  height: 34px;
  transform: translate3d(-20vw, 10vh, 0) rotate(90deg);
}

.light-green::after {
  -webkit-animation: light-green-pseudo linear 20s alternate infinite;
  animation: light-green-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 31px;
  height: 36px;
  transform: translate3d(27vw, -13vh, 0) rotate(197deg);
}

@-webkit-keyframes light-green {
  50% {
    transform: translate3d(63vw, 63vh, 0);
  }

  100% {
    transform: translate3d(55vw, 91vh, 0);
  }
}

@keyframes light-green {
  50% {
    transform: translate3d(63vw, 63vh, 0);
  }

  100% {
    transform: translate3d(55vw, 91vh, 0);
  }
}

@-webkit-keyframes light-green-pseudo {
  33% {
    transform: translate3d(-55vw, -28vh, 0) rotate(269deg);
  }

  100% {
    transform: translate3d(1vw, -47vh, 0) rotate(192deg);
  }
}

@keyframes light-green-pseudo {
  33% {
    transform: translate3d(-55vw, -28vh, 0) rotate(269deg);
  }

  100% {
    transform: translate3d(1vw, -47vh, 0) rotate(192deg);
  }
}

.lime {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  transform: translate3d(7vw, 34vh, 0);
  z-index: 10;
}

.lime::before {
  -webkit-animation: lime-pseudo linear 15s alternate infinite;
  animation: lime-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 10px;
  height: 46px;
  transform: translate3d(20vw, 10vh, 0) rotate(23deg);
}

.lime::after {
  -webkit-animation: lime-pseudo linear 20s alternate infinite;
  animation: lime-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 40px;
  height: 24px;
  transform: translate3d(35vw, -22vh, 0) rotate(220deg);
}

@-webkit-keyframes lime {
  50% {
    transform: translate3d(67vw, 35vh, 0);
  }

  100% {
    transform: translate3d(18vw, 16vh, 0);
  }
}

@keyframes lime {
  50% {
    transform: translate3d(67vw, 35vh, 0);
  }

  100% {
    transform: translate3d(18vw, 16vh, 0);
  }
}

@-webkit-keyframes lime-pseudo {
  33% {
    transform: translate3d(-65vw, -10vh, 0) rotate(59deg);
  }

  100% {
    transform: translate3d(-6vw, -2vh, 0) rotate(210deg);
  }
}

@keyframes lime-pseudo {
  33% {
    transform: translate3d(-65vw, -10vh, 0) rotate(59deg);
  }

  100% {
    transform: translate3d(-6vw, -2vh, 0) rotate(210deg);
  }
}

.magenta {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 37px;
  height: 37px;
  transform: translate3d(10vw, 77vh, 0);
  z-index: 10;
}

.magenta::before {
  -webkit-animation: magenta-pseudo linear 15s alternate infinite;
  animation: magenta-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 31px;
  height: 47px;
  transform: translate3d(45vw, -53vh, 0) rotate(272deg);
}

.magenta::after {
  -webkit-animation: magenta-pseudo linear 20s alternate infinite;
  animation: magenta-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 39px;
  height: 19px;
  transform: translate3d(2vw, 0vh, 0) rotate(268deg);
}

@-webkit-keyframes magenta {
  50% {
    transform: translate3d(53vw, 39vh, 0);
  }

  100% {
    transform: translate3d(71vw, 45vh, 0);
  }
}

@keyframes magenta {
  50% {
    transform: translate3d(53vw, 39vh, 0);
  }

  100% {
    transform: translate3d(71vw, 45vh, 0);
  }
}

@-webkit-keyframes magenta-pseudo {
  33% {
    transform: translate3d(-25vw, 45vh, 0) rotate(351deg);
  }

  100% {
    transform: translate3d(4vw, -35vh, 0) rotate(186deg);
  }
}

@keyframes magenta-pseudo {
  33% {
    transform: translate3d(-25vw, 45vh, 0) rotate(351deg);
  }

  100% {
    transform: translate3d(4vw, -35vh, 0) rotate(186deg);
  }
}

.lightish-red {
  -webkit-animation: lightish-red linear 30s alternate infinite;
  animation: lightish-red linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  transform: translate3d(66vw, 10vh, 0);
  z-index: 3;
}

.lightish-red::before {
  -webkit-animation: lightish-red-pseudo linear 15s alternate infinite;
  animation: lightish-red-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 44px;
  height: 20px;
  transform: translate3d(-13vw, -7vh, 0) rotate(342deg);
}

.lightish-red::after {
  -webkit-animation: lightish-red-pseudo linear 20s alternate infinite;
  animation: lightish-red-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 38px;
  height: 10px;
  transform: translate3d(-13vw, 54vh, 0) rotate(21deg);
}

@-webkit-keyframes lightish-red {
  50% {
    transform: translate3d(41vw, 78vh, 0);
  }

  100% {
    transform: translate3d(50vw, 12vh, 0);
  }
}

@keyframes lightish-red {
  50% {
    transform: translate3d(41vw, 78vh, 0);
  }

  100% {
    transform: translate3d(50vw, 12vh, 0);
  }
}

@-webkit-keyframes lightish-red-pseudo {
  33% {
    transform: translate3d(-5vw, -47vh, 0) rotate(83deg);
  }

  100% {
    transform: translate3d(24vw, -10vh, 0) rotate(250deg);
  }
}

@keyframes lightish-red-pseudo {
  33% {
    transform: translate3d(-5vw, -47vh, 0) rotate(83deg);
  }

  100% {
    transform: translate3d(24vw, -10vh, 0) rotate(250deg);
  }
}

.pink {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  transform: translate3d(40vw, 11vh, 0);
  z-index: 11;
}

.pink::before {
  -webkit-animation: pink-pseudo linear 15s alternate infinite;
  animation: pink-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 13px;
  height: 45px;
  transform: translate3d(32vw, 76vh, 0) rotate(317deg);
}

.pink::after {
  -webkit-animation: pink-pseudo linear 20s alternate infinite;
  animation: pink-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 28px;
  height: 48px;
  transform: translate3d(50vw, 38vh, 0) rotate(38deg);
}

@-webkit-keyframes pink {
  50% {
    transform: translate3d(15vw, 29vh, 0);
  }

  100% {
    transform: translate3d(23vw, 22vh, 0);
  }
}

@keyframes pink {
  50% {
    transform: translate3d(15vw, 29vh, 0);
  }

  100% {
    transform: translate3d(23vw, 22vh, 0);
  }
}

@-webkit-keyframes pink-pseudo {
  33% {
    transform: translate3d(70vw, -26vh, 0) rotate(191deg);
  }

  100% {
    transform: translate3d(19vw, -1vh, 0) rotate(17deg);
  }
}

@keyframes pink-pseudo {
  33% {
    transform: translate3d(70vw, -26vh, 0) rotate(191deg);
  }

  100% {
    transform: translate3d(19vw, -1vh, 0) rotate(17deg);
  }
}

.animation_section {
  background: transparent;
  overflow: hidden;
  height: 100vh;
}

.animation_section:active div,
.animation_section body:active div::before,
.animation_section body:active div::after {
  padding: 40px;
}

.animation_section div,
.animation_section div::before,
.animation_section div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  transform-origin: top;
  transition: all 5s linear 0s;
}

@keyframes float {
  0% {
    box-shadow: none;
    transform: translatey(0px);
  }

  50% {
    box-shadow: none;
    transform: translatey(-20px);
  }

  100% {
    box-shadow: none;
    transform: translatey(0px);
  }
}

.blink-2 {
  -webkit-animation: blink-2 1.5s infinite both;
  animation: blink-2 1.5s infinite both;
}

@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink-2 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.card_winner_loter {
  border: 5px solid #ffeb3c;
  border-radius: 5px;
  margin-top: 35px;
}

.header_winner_card {
  padding: 10px 0px;
  text-align: center;
  font-weight: 700;
  color: #000;
  background: #ffeb3c;
}

.header_winner_card h4 {
  margin: 0;
  font-weight: 700;
  padding: 5px 0px;
}

.tilt-in-fwd-tl {
  -webkit-animation: tilt-in-fwd-tl 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-fwd-tl 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

@keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

.scale-in-ver-center {
  -webkit-animation: scale-in-ver-center 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

html {
  height: auto;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: auto;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1112px 3972px #fff, 1385px 1380px #fff, 442px 80px #fff,
    1990px 1282px #fff, 256px 1513px #fff, 1757px 546px #fff, 611px 1280px #fff,
    1220px 818px #fff, 561px 949px #fff, 361px 1402px #fff, 626px 473px #fff,
    573px 143px #fff, 378px 564px #fff, 1973px 752px #fff, 1154px 1871px #fff,
    548px 538px #fff, 738px 1821px #fff, 29px 1048px #fff, 1752px 709px #fff,
    1008px 1894px #fff, 1328px 1871px #fff, 1482px 450px #fff,
    1700px 1096px #fff, 865px 1279px #fff, 141px 1921px #fff, 1575px 1796px #fff,
    431px 1309px #fff, 135px 1513px #fff, 996px 850px #fff, 1483px 1803px #fff,
    338px 1614px #fff, 1679px 181px #fff, 281px 931px #fff, 334px 352px #fff,
    1609px 1947px #fff, 266px 881px #fff, 53px 890px #fff, 1924px 70px #fff,
    1722px 1108px #fff, 1697px 1081px #fff, 934px 1725px #fff, 1737px 712px #fff,
    1392px 1897px #fff, 488px 1565px #fff, 1513px 1697px #fff, 977px 1654px #fff,
    603px 556px #fff, 807px 1373px #fff, 1208px 1878px #fff, 362px 735px #fff,
    43px 1279px #fff, 88px 1695px #fff, 345px 1640px #fff, 1545px 770px #fff,
    581px 1060px #fff, 1165px 12px #fff, 521px 1542px #fff, 633px 1368px #fff,
    662px 57px #fff, 874px 76px #fff, 1872px 353px #fff, 743px 539px #fff,
    1239px 763px #fff, 923px 63px #fff, 527px 126px #fff, 1051px 1411px #fff,
    287px 964px #fff, 1172px 926px #fff, 574px 108px #fff, 1706px 310px #fff,
    1956px 436px #fff, 1572px 1900px #fff, 1580px 1686px #fff,
    1800px 1309px #fff, 1745px 1004px #fff, 1853px 1875px #fff,
    1382px 208px #fff, 379px 654px #fff, 1986px 1883px #fff, 1294px 151px #fff,
    1160px 1341px #fff, 1362px 397px #fff, 1171px 458px #fff, 888px 1977px #fff,
    1488px 1919px #fff, 1948px 225px #fff, 1259px 1137px #fff, 627px 880px #fff,
    1494px 889px #fff, 1697px 1436px #fff, 901px 1327px #fff, 663px 890px #fff,
    410px 194px #fff, 1001px 913px #fff, 631px 104px #fff, 1998px 1246px #fff,
    1893px 1285px #fff, 512px 503px #fff, 188px 1954px #fff, 1360px 313px #fff,
    184px 1725px #fff, 794px 1586px #fff, 1338px 236px #fff, 1480px 925px #fff,
    378px 965px #fff, 1171px 240px #fff, 1180px 1206px #fff, 916px 1788px #fff,
    844px 698px #fff, 1826px 1672px #fff, 1030px 1209px #fff, 58px 109px #fff,
    214px 633px #fff, 91px 553px #fff, 1820px 333px #fff, 486px 267px #fff,
    1828px 956px #fff, 715px 996px #fff, 1404px 548px #fff, 1539px 1201px #fff,
    1101px 1811px #fff, 462px 684px #fff, 350px 503px #fff, 1549px 444px #fff,
    1653px 1115px #fff, 685px 1566px #fff, 1199px 1352px #fff, 1478px 157px #fff,
    638px 1491px #fff, 1438px 443px #fff, 1701px 1877px #fff, 1876px 280px #fff,
    1062px 1254px #fff, 1504px 1452px #fff, 1737px 1801px #fff, 22px 104px #fff,
    502px 1379px #fff, 920px 861px #fff, 467px 490px #fff, 1990px 1418px #fff,
    986px 1401px #fff, 84px 941px #fff, 775px 425px #fff, 160px 1560px #fff,
    1722px 1537px #fff, 94px 160px #fff, 714px 167px #fff, 986px 893px #fff,
    1695px 1847px #fff, 1899px 882px #fff, 1023px 1282px #fff, 601px 233px #fff,
    1861px 1811px #fff, 725px 1237px #fff, 1932px 1481px #fff,
    1222px 1319px #fff, 1376px 566px #fff, 941px 535px #fff, 1862px 1130px #fff,
    1508px 1717px #fff, 381px 1777px #fff, 455px 29px #fff, 1503px 875px #fff,
    1296px 1288px #fff, 1868px 361px #fff, 491px 1721px #fff, 1152px 899px #fff,
    1309px 604px #fff, 1298px 1745px #fff, 885px 1796px #fff, 428px 786px #fff,
    774px 995px #fff, 1513px 1842px #fff, 641px 38px #fff, 1254px 1019px #fff,
    1716px 1763px #fff, 1584px 1832px #fff, 284px 1432px #fff, 584px 1768px #fff,
    1192px 1640px #fff, 170px 1374px #fff, 138px 455px #fff, 913px 140px #fff,
    1350px 421px #fff, 1695px 1054px #fff, 824px 1013px #fff, 1837px 1083px #fff,
    1935px 1894px #fff, 1546px 100px #fff, 680px 1372px #fff, 1994px 1175px #fff,
    403px 1117px #fff, 1040px 354px #fff, 1364px 1960px #fff, 178px 1303px #fff,
    575px 1642px #fff, 880px 762px #fff, 1454px 1526px #fff, 1238px 1990px #fff,
    1570px 385px #fff, 1422px 1715px #fff, 711px 82px #fff, 1181px 1117px #fff,
    653px 1733px #fff, 471px 1180px #fff, 844px 1548px #fff, 1713px 1898px #fff,
    941px 183px #fff, 1736px 1464px #fff, 1587px 738px #fff, 1420px 1393px #fff,
    952px 1340px #fff, 598px 1838px #fff, 513px 878px #fff, 389px 1391px #fff,
    1190px 706px #fff, 1086px 911px #fff, 1365px 855px #fff, 950px 1720px #fff,
    1463px 1667px #fff, 1022px 1548px #fff, 1616px 479px #fff,
    1882px 1008px #fff, 1101px 544px #fff, 1185px 839px #fff, 1149px 884px #fff,
    182px 1034px #fff, 980px 609px #fff, 1472px 4px #fff, 924px 1905px #fff,
    482px 893px #fff, 578px 1806px #fff, 806px 1316px #fff, 1586px 1710px #fff,
    1049px 1050px #fff, 139px 1838px #fff, 985px 1480px #fff, 113px 130px #fff,
    1349px 1609px #fff, 1610px 627px #fff, 804px 686px #fff, 528px 218px #fff,
    18px 1127px #fff, 555px 1816px #fff, 116px 1545px #fff, 270px 359px #fff,
    159px 426px #fff, 1541px 1746px #fff, 1218px 168px #fff, 441px 955px #fff,
    1584px 819px #fff, 1686px 368px #fff, 1460px 1101px #fff, 440px 1251px #fff,
    1723px 328px #fff, 647px 1364px #fff, 254px 580px #fff, 365px 1350px #fff,
    32px 930px #fff, 308px 1656px #fff, 520px 1493px #fff, 1858px 1386px #fff,
    809px 495px #fff, 1804px 170px #fff, 1221px 224px #fff, 829px 387px #fff,
    600px 320px #fff, 1112px 1904px #fff, 1023px 1631px #fff, 697px 1210px #fff,
    785px 1099px #fff, 303px 1249px #fff, 145px 1821px #fff, 1171px 1651px #fff,
    1869px 85px #fff, 1526px 573px #fff, 950px 179px #fff, 1049px 1364px #fff,
    1819px 691px #fff, 993px 1132px #fff, 554px 662px #fff, 1603px 1704px #fff,
    1552px 658px #fff, 1942px 643px #fff, 1453px 1609px #fff, 715px 1296px #fff,
    1811px 1270px #fff, 1178px 936px #fff, 1054px 1463px #fff, 687px 312px #fff,
    661px 393px #fff, 867px 1131px #fff, 1160px 801px #fff, 1577px 968px #fff,
    1966px 394px #fff, 743px 1172px #fff, 973px 995px #fff, 149px 409px #fff,
    511px 581px #fff, 651px 1556px #fff, 1132px 1766px #fff, 1837px 575px #fff,
    1606px 22px #fff, 1136px 559px #fff, 1887px 334px #fff, 102px 749px #fff,
    1009px 1349px #fff, 984px 1505px #fff, 1674px 135px #fff, 101px 1017px #fff,
    1443px 864px #fff, 1165px 488px #fff, 129px 546px #fff, 1024px 1204px #fff,
    1382px 1673px #fff, 1132px 178px #fff, 146px 57px #fff, 1506px 1364px #fff,
    1435px 9px #fff, 1215px 380px #fff, 1801px 1832px #fff, 1467px 103px #fff,
    845px 1518px #fff, 1661px 1179px #fff, 1955px 1158px #fff, 1556px 123px #fff,
    243px 565px #fff, 1511px 1774px #fff, 43px 591px #fff, 569px 1621px #fff,
    1118px 1134px #fff, 1695px 1897px #fff, 1644px 1994px #fff,
    688px 1709px #fff, 546px 1275px #fff, 758px 1451px #fff, 1914px 1872px #fff,
    1961px 1763px #fff, 990px 1229px #fff, 874px 1847px #fff, 914px 1987px #fff,
    738px 267px #fff, 1537px 928px #fff, 1047px 1707px #fff, 1968px 379px #fff,
    1076px 251px #fff, 1583px 682px #fff, 1076px 1213px #fff, 1964px 564px #fff,
    1611px 1739px #fff, 984px 1845px #fff, 155px 1106px #fff, 24px 1473px #fff,
    313px 1842px #fff, 1627px 808px #fff, 414px 663px #fff, 34px 263px #fff,
    1501px 982px #fff, 333px 87px #fff, 765px 70px #fff, 25px 1358px #fff,
    1323px 1452px #fff, 84px 516px #fff, 592px 1458px #fff, 1926px 205px #fff,
    1465px 930px #fff, 791px 889px #fff, 205px 1876px #fff, 460px 689px #fff,
    1095px 1236px #fff, 46px 599px #fff, 1698px 1799px #fff, 1642px 1642px #fff,
    260px 1519px #fff, 1422px 1966px #fff, 1526px 1568px #fff, 1268px 600px #fff,
    1380px 1919px #fff, 1359px 1869px #fff, 794px 1613px #fff,
    1771px 1501px #fff, 126px 1287px #fff, 1634px 705px #fff, 1532px 615px #fff,
    1347px 512px #fff, 1622px 1138px #fff, 526px 1738px #fff, 642px 500px #fff,
    500px 1692px #fff, 1724px 911px #fff, 1351px 513px #fff, 386px 1534px #fff,
    570px 1783px #fff, 891px 326px #fff, 1724px 1699px #fff, 866px 41px #fff,
    111px 1450px #fff, 1181px 350px #fff, 739px 965px #fff, 1384px 1148px #fff,
    1797px 1028px #fff, 248px 1473px #fff, 654px 1002px #fff, 298px 254px #fff,
    1231px 1336px #fff, 77px 943px #fff, 219px 741px #fff, 978px 881px #fff,
    202px 1235px #fff, 1318px 655px #fff, 804px 1015px #fff, 1519px 328px #fff,
    349px 816px #fff, 1459px 1549px #fff, 349px 1542px #fff, 50px 524px #fff,
    1984px 909px #fff, 1738px 1420px #fff, 1792px 197px #fff, 1485px 999px #fff,
    176px 1864px #fff, 1465px 82px #fff, 1938px 77px #fff, 1327px 737px #fff,
    131px 1098px #fff, 1444px 1798px #fff, 479px 512px #fff, 941px 1622px #fff,
    872px 691px #fff, 772px 1142px #fff, 353px 504px #fff, 1822px 226px #fff,
    1755px 1713px #fff, 1177px 898px #fff, 64px 702px #fff, 806px 800px #fff,
    442px 806px #fff, 1681px 1085px #fff, 1614px 1133px #fff, 834px 927px #fff,
    1564px 92px #fff, 1381px 26px #fff, 425px 462px #fff, 1011px 1922px #fff,
    99px 1205px #fff, 869px 1481px #fff, 516px 30px #fff, 32px 654px #fff,
    1833px 685px #fff, 311px 1288px #fff, 86px 1140px #fff, 594px 1105px #fff,
    1224px 269px #fff, 1061px 975px #fff, 73px 131px #fff, 1078px 1732px #fff,
    468px 305px #fff, 1809px 818px #fff, 1233px 535px #fff, 1895px 1633px #fff,
    74px 811px #fff, 416px 82px #fff, 1326px 1692px #fff, 1801px 179px #fff,
    118px 592px #fff, 74px 1683px #fff, 1395px 1991px #fff, 596px 1992px #fff,
    1504px 1670px #fff, 1787px 365px #fff, 432px 339px #fff, 353px 164px #fff,
    782px 1929px #fff, 1445px 1872px #fff, 1829px 473px #fff, 234px 505px #fff,
    1120px 1497px #fff, 244px 1196px #fff, 545px 1505px #fff, 132px 1602px #fff,
    490px 131px #fff, 1720px 1349px #fff, 1386px 1976px #fff, 1224px 1091px #fff,
    1448px 1516px #fff, 989px 943px #fff, 408px 618px #fff, 815px 793px #fff,
    267px 1196px #fff, 301px 1907px #fff, 1821px 1299px #fff, 1188px 1161px #fff,
    608px 534px #fff, 415px 477px #fff, 705px 1987px #fff, 797px 661px #fff,
    602px 777px #fff, 1113px 1413px #fff, 1208px 1584px #fff, 1932px 212px #fff,
    704px 444px #fff, 637px 651px #fff, 942px 1932px #fff, 393px 1178px #fff,
    1781px 467px #fff, 604px 774px #fff, 1703px 76px #fff, 1769px 1705px #fff,
    1140px 1673px #fff, 1281px 1734px #fff, 1288px 666px #fff, 1117px 858px #fff,
    28px 1860px #fff, 105px 1498px #fff, 417px 366px #fff, 1591px 1754px #fff,
    1269px 574px #fff, 337px 548px #fff, 596px 1114px #fff, 974px 507px #fff,
    1791px 297px #fff, 966px 914px #fff, 1408px 1234px #fff, 1465px 565px #fff,
    648px 1415px #fff, 1173px 1507px #fff, 838px 1904px #fff, 308px 755px #fff,
    792px 1572px #fff, 410px 362px #fff, 994px 1401px #fff, 716px 1918px #fff,
    689px 592px #fff, 94px 843px #fff, 1814px 1487px #fff, 453px 467px #fff,
    1213px 1757px #fff, 697px 1728px #fff, 1692px 286px #fff, 263px 97px #fff,
    117px 1658px #fff, 1047px 1775px #fff, 896px 1647px #fff, 1626px 1039px #fff,
    1631px 1728px #fff, 1488px 851px #fff, 953px 110px #fff, 84px 855px #fff,
    160px 1143px #fff, 1406px 72px #fff, 887px 1482px #fff, 1953px 795px #fff,
    109px 1913px #fff, 1960px 1981px #fff, 669px 920px #fff, 1904px 804px #fff,
    1296px 1011px #fff, 1736px 1973px #fff, 1352px 1365px #fff,
    1510px 255px #fff, 1465px 1078px #fff, 137px 1030px #fff, 1353px 1962px #fff,
    1792px 598px #fff, 776px 401px #fff, 1536px 622px #fff, 1064px 614px #fff,
    439px 85px #fff, 820px 1358px #fff, 55px 793px #fff, 564px 921px #fff,
    961px 1964px #fff, 1154px 122px #fff, 340px 1209px #fff, 666px 1594px #fff,
    788px 1226px #fff, 794px 1073px #fff, 1879px 1968px #fff, 319px 1181px #fff,
    812px 208px #fff, 1355px 1290px #fff, 1506px 1012px #fff, 1296px 1707px #fff,
    1831px 33px #fff, 1673px 385px #fff, 1312px 1765px #fff, 989px 475px #fff,
    75px 921px #fff, 77px 1814px #fff, 1262px 1916px #fff, 371px 1095px #fff,
    418px 721px #fff, 1280px 672px #fff, 1386px 88px #fff, 1213px 815px #fff,
    1412px 527px #fff, 119px 176px #fff, 1156px 354px #fff, 671px 1528px #fff,
    888px 1614px #fff, 1438px 922px #fff, 1439px 1631px #fff, 782px 641px #fff,
    294px 1671px #fff, 158px 106px #fff, 227px 248px #fff, 1565px 939px #fff,
    406px 1107px #fff, 120px 851px #fff, 1093px 253px #fff, 377px 1373px #fff,
    525px 1664px #fff, 175px 882px #fff, 1664px 954px #fff, 16px 1707px #fff,
    44px 1532px #fff, 1809px 1247px #fff, 778px 1003px #fff, 38px 1343px #fff,
    140px 1066px #fff, 747px 1826px #fff, 1659px 183px #fff, 1033px 217px #fff,
    1413px 428px #fff, 1214px 1499px #fff, 82px 1822px #fff, 933px 626px #fff,
    363px 126px #fff, 274px 958px #fff, 1140px 477px #fff, 1299px 1725px #fff,
    1679px 1195px #fff, 400px 201px #fff, 1047px 1073px #fff, 1232px 42px #fff,
    168px 1520px #fff, 828px 26px #fff, 1408px 1064px #fff, 985px 827px #fff,
    931px 529px #fff, 1336px 312px #fff, 40px 1424px #fff, 1891px 194px #fff,
    807px 1078px #fff, 1807px 1434px #fff, 807px 1704px #fff, 529px 1996px #fff,
    1923px 1012px #fff, 977px 23px #fff, 405px 1851px #fff, 376px 720px #fff,
    79px 1586px #fff, 592px 1804px #fff, 1682px 1617px #fff, 702px 1592px #fff,
    181px 413px #fff, 17px 586px #fff, 631px 184px #fff, 1339px 1564px #fff,
    61px 514px #fff, 912px 1708px #fff, 60px 1799px #fff, 854px 1629px #fff,
    625px 1688px #fff, 1289px 1421px #fff, 700px 1916px #fff, 491px 725px #fff,
    1817px 631px #fff, 187px 1158px #fff, 1958px 599px #fff, 692px 471px #fff,
    1949px 1053px #fff, 1220px 1491px #fff, 1423px 1667px #fff,
    666px 1474px #fff, 661px 1229px #fff, 1118px 742px #fff, 1592px 1427px #fff,
    424px 908px #fff, 1929px 1036px #fff, 164px 96px #fff, 474px 666px #fff,
    689px 548px #fff, 568px 1201px #fff, 1968px 1071px #fff, 337px 1721px #fff,
    712px 1404px #fff, 624px 1602px #fff, 763px 1623px #fff, 195px 516px #fff,
    206px 66px #fff, 591px 739px #fff, 217px 1868px #fff, 224px 1768px #fff,
    1611px 966px #fff, 1848px 1485px #fff, 169px 1125px #fff, 1133px 905px #fff,
    738px 1681px #fff, 1462px 957px #fff;
  animation: animStar 50s linear infinite;
}

#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1112px 3972px #fff, 1385px 1380px #fff, 442px 80px #fff,
    1990px 1282px #fff, 256px 1513px #fff, 1757px 546px #fff, 611px 1280px #fff,
    1220px 818px #fff, 561px 949px #fff, 361px 1402px #fff, 626px 473px #fff,
    573px 143px #fff, 378px 564px #fff, 1973px 752px #fff, 1154px 1871px #fff,
    548px 538px #fff, 738px 1821px #fff, 29px 1048px #fff, 1752px 709px #fff,
    1008px 1894px #fff, 1328px 1871px #fff, 1482px 450px #fff,
    1700px 1096px #fff, 865px 1279px #fff, 141px 1921px #fff, 1575px 1796px #fff,
    431px 1309px #fff, 135px 1513px #fff, 996px 850px #fff, 1483px 1803px #fff,
    338px 1614px #fff, 1679px 181px #fff, 281px 931px #fff, 334px 352px #fff,
    1609px 1947px #fff, 266px 881px #fff, 53px 890px #fff, 1924px 70px #fff,
    1722px 1108px #fff, 1697px 1081px #fff, 934px 1725px #fff, 1737px 712px #fff,
    1392px 1897px #fff, 488px 1565px #fff, 1513px 1697px #fff, 977px 1654px #fff,
    603px 556px #fff, 807px 1373px #fff, 1208px 1878px #fff, 362px 735px #fff,
    43px 1279px #fff, 88px 1695px #fff, 345px 1640px #fff, 1545px 770px #fff,
    581px 1060px #fff, 1165px 12px #fff, 521px 1542px #fff, 633px 1368px #fff,
    662px 57px #fff, 874px 76px #fff, 1872px 353px #fff, 743px 539px #fff,
    1239px 763px #fff, 923px 63px #fff, 527px 126px #fff, 1051px 1411px #fff,
    287px 964px #fff, 1172px 926px #fff, 574px 108px #fff, 1706px 310px #fff,
    1956px 436px #fff, 1572px 1900px #fff, 1580px 1686px #fff,
    1800px 1309px #fff, 1745px 1004px #fff, 1853px 1875px #fff,
    1382px 208px #fff, 379px 654px #fff, 1986px 1883px #fff, 1294px 151px #fff,
    1160px 1341px #fff, 1362px 397px #fff, 1171px 458px #fff, 888px 1977px #fff,
    1488px 1919px #fff, 1948px 225px #fff, 1259px 1137px #fff, 627px 880px #fff,
    1494px 889px #fff, 1697px 1436px #fff, 901px 1327px #fff, 663px 890px #fff,
    410px 194px #fff, 1001px 913px #fff, 631px 104px #fff, 1998px 1246px #fff,
    1893px 1285px #fff, 512px 503px #fff, 188px 1954px #fff, 1360px 313px #fff,
    184px 1725px #fff, 794px 1586px #fff, 1338px 236px #fff, 1480px 925px #fff,
    378px 965px #fff, 1171px 240px #fff, 1180px 1206px #fff, 916px 1788px #fff,
    844px 698px #fff, 1826px 1672px #fff, 1030px 1209px #fff, 58px 109px #fff,
    214px 633px #fff, 91px 553px #fff, 1820px 333px #fff, 486px 267px #fff,
    1828px 956px #fff, 715px 996px #fff, 1404px 548px #fff, 1539px 1201px #fff,
    1101px 1811px #fff, 462px 684px #fff, 350px 503px #fff, 1549px 444px #fff,
    1653px 1115px #fff, 685px 1566px #fff, 1199px 1352px #fff, 1478px 157px #fff,
    638px 1491px #fff, 1438px 443px #fff, 1701px 1877px #fff, 1876px 280px #fff,
    1062px 1254px #fff, 1504px 1452px #fff, 1737px 1801px #fff, 22px 104px #fff,
    502px 1379px #fff, 920px 861px #fff, 467px 490px #fff, 1990px 1418px #fff,
    986px 1401px #fff, 84px 941px #fff, 775px 425px #fff, 160px 1560px #fff,
    1722px 1537px #fff, 94px 160px #fff, 714px 167px #fff, 986px 893px #fff,
    1695px 1847px #fff, 1899px 882px #fff, 1023px 1282px #fff, 601px 233px #fff,
    1861px 1811px #fff, 725px 1237px #fff, 1932px 1481px #fff,
    1222px 1319px #fff, 1376px 566px #fff, 941px 535px #fff, 1862px 1130px #fff,
    1508px 1717px #fff, 381px 1777px #fff, 455px 29px #fff, 1503px 875px #fff,
    1296px 1288px #fff, 1868px 361px #fff, 491px 1721px #fff, 1152px 899px #fff,
    1309px 604px #fff, 1298px 1745px #fff, 885px 1796px #fff, 428px 786px #fff,
    774px 995px #fff, 1513px 1842px #fff, 641px 38px #fff, 1254px 1019px #fff,
    1716px 1763px #fff, 1584px 1832px #fff, 284px 1432px #fff, 584px 1768px #fff,
    1192px 1640px #fff, 170px 1374px #fff, 138px 455px #fff, 913px 140px #fff,
    1350px 421px #fff, 1695px 1054px #fff, 824px 1013px #fff, 1837px 1083px #fff,
    1935px 1894px #fff, 1546px 100px #fff, 680px 1372px #fff, 1994px 1175px #fff,
    403px 1117px #fff, 1040px 354px #fff, 1364px 1960px #fff, 178px 1303px #fff,
    575px 1642px #fff, 880px 762px #fff, 1454px 1526px #fff, 1238px 1990px #fff,
    1570px 385px #fff, 1422px 1715px #fff, 711px 82px #fff, 1181px 1117px #fff,
    653px 1733px #fff, 471px 1180px #fff, 844px 1548px #fff, 1713px 1898px #fff,
    941px 183px #fff, 1736px 1464px #fff, 1587px 738px #fff, 1420px 1393px #fff,
    952px 1340px #fff, 598px 1838px #fff, 513px 878px #fff, 389px 1391px #fff,
    1190px 706px #fff, 1086px 911px #fff, 1365px 855px #fff, 950px 1720px #fff,
    1463px 1667px #fff, 1022px 1548px #fff, 1616px 479px #fff,
    1882px 1008px #fff, 1101px 544px #fff, 1185px 839px #fff, 1149px 884px #fff,
    182px 1034px #fff, 980px 609px #fff, 1472px 4px #fff, 924px 1905px #fff,
    482px 893px #fff, 578px 1806px #fff, 806px 1316px #fff, 1586px 1710px #fff,
    1049px 1050px #fff, 139px 1838px #fff, 985px 1480px #fff, 113px 130px #fff,
    1349px 1609px #fff, 1610px 627px #fff, 804px 686px #fff, 528px 218px #fff,
    18px 1127px #fff, 555px 1816px #fff, 116px 1545px #fff, 270px 359px #fff,
    159px 426px #fff, 1541px 1746px #fff, 1218px 168px #fff, 441px 955px #fff,
    1584px 819px #fff, 1686px 368px #fff, 1460px 1101px #fff, 440px 1251px #fff,
    1723px 328px #fff, 647px 1364px #fff, 254px 580px #fff, 365px 1350px #fff,
    32px 930px #fff, 308px 1656px #fff, 520px 1493px #fff, 1858px 1386px #fff,
    809px 495px #fff, 1804px 170px #fff, 1221px 224px #fff, 829px 387px #fff,
    600px 320px #fff, 1112px 1904px #fff, 1023px 1631px #fff, 697px 1210px #fff,
    785px 1099px #fff, 303px 1249px #fff, 145px 1821px #fff, 1171px 1651px #fff,
    1869px 85px #fff, 1526px 573px #fff, 950px 179px #fff, 1049px 1364px #fff,
    1819px 691px #fff, 993px 1132px #fff, 554px 662px #fff, 1603px 1704px #fff,
    1552px 658px #fff, 1942px 643px #fff, 1453px 1609px #fff, 715px 1296px #fff,
    1811px 1270px #fff, 1178px 936px #fff, 1054px 1463px #fff, 687px 312px #fff,
    661px 393px #fff, 867px 1131px #fff, 1160px 801px #fff, 1577px 968px #fff,
    1966px 394px #fff, 743px 1172px #fff, 973px 995px #fff, 149px 409px #fff,
    511px 581px #fff, 651px 1556px #fff, 1132px 1766px #fff, 1837px 575px #fff,
    1606px 22px #fff, 1136px 559px #fff, 1887px 334px #fff, 102px 749px #fff,
    1009px 1349px #fff, 984px 1505px #fff, 1674px 135px #fff, 101px 1017px #fff,
    1443px 864px #fff, 1165px 488px #fff, 129px 546px #fff, 1024px 1204px #fff,
    1382px 1673px #fff, 1132px 178px #fff, 146px 57px #fff, 1506px 1364px #fff,
    1435px 9px #fff, 1215px 380px #fff, 1801px 1832px #fff, 1467px 103px #fff,
    845px 1518px #fff, 1661px 1179px #fff, 1955px 1158px #fff, 1556px 123px #fff,
    243px 565px #fff, 1511px 1774px #fff, 43px 591px #fff, 569px 1621px #fff,
    1118px 1134px #fff, 1695px 1897px #fff, 1644px 1994px #fff,
    688px 1709px #fff, 546px 1275px #fff, 758px 1451px #fff, 1914px 1872px #fff,
    1961px 1763px #fff, 990px 1229px #fff, 874px 1847px #fff, 914px 1987px #fff,
    738px 267px #fff, 1537px 928px #fff, 1047px 1707px #fff, 1968px 379px #fff,
    1076px 251px #fff, 1583px 682px #fff, 1076px 1213px #fff, 1964px 564px #fff,
    1611px 1739px #fff, 984px 1845px #fff, 155px 1106px #fff, 24px 1473px #fff,
    313px 1842px #fff, 1627px 808px #fff, 414px 663px #fff, 34px 263px #fff,
    1501px 982px #fff, 333px 87px #fff, 765px 70px #fff, 25px 1358px #fff,
    1323px 1452px #fff, 84px 516px #fff, 592px 1458px #fff, 1926px 205px #fff,
    1465px 930px #fff, 791px 889px #fff, 205px 1876px #fff, 460px 689px #fff,
    1095px 1236px #fff, 46px 599px #fff, 1698px 1799px #fff, 1642px 1642px #fff,
    260px 1519px #fff, 1422px 1966px #fff, 1526px 1568px #fff, 1268px 600px #fff,
    1380px 1919px #fff, 1359px 1869px #fff, 794px 1613px #fff,
    1771px 1501px #fff, 126px 1287px #fff, 1634px 705px #fff, 1532px 615px #fff,
    1347px 512px #fff, 1622px 1138px #fff, 526px 1738px #fff, 642px 500px #fff,
    500px 1692px #fff, 1724px 911px #fff, 1351px 513px #fff, 386px 1534px #fff,
    570px 1783px #fff, 891px 326px #fff, 1724px 1699px #fff, 866px 41px #fff,
    111px 1450px #fff, 1181px 350px #fff, 739px 965px #fff, 1384px 1148px #fff,
    1797px 1028px #fff, 248px 1473px #fff, 654px 1002px #fff, 298px 254px #fff,
    1231px 1336px #fff, 77px 943px #fff, 219px 741px #fff, 978px 881px #fff,
    202px 1235px #fff, 1318px 655px #fff, 804px 1015px #fff, 1519px 328px #fff,
    349px 816px #fff, 1459px 1549px #fff, 349px 1542px #fff, 50px 524px #fff,
    1984px 909px #fff, 1738px 1420px #fff, 1792px 197px #fff, 1485px 999px #fff,
    176px 1864px #fff, 1465px 82px #fff, 1938px 77px #fff, 1327px 737px #fff,
    131px 1098px #fff, 1444px 1798px #fff, 479px 512px #fff, 941px 1622px #fff,
    872px 691px #fff, 772px 1142px #fff, 353px 504px #fff, 1822px 226px #fff,
    1755px 1713px #fff, 1177px 898px #fff, 64px 702px #fff, 806px 800px #fff,
    442px 806px #fff, 1681px 1085px #fff, 1614px 1133px #fff, 834px 927px #fff,
    1564px 92px #fff, 1381px 26px #fff, 425px 462px #fff, 1011px 1922px #fff,
    99px 1205px #fff, 869px 1481px #fff, 516px 30px #fff, 32px 654px #fff,
    1833px 685px #fff, 311px 1288px #fff, 86px 1140px #fff, 594px 1105px #fff,
    1224px 269px #fff, 1061px 975px #fff, 73px 131px #fff, 1078px 1732px #fff,
    468px 305px #fff, 1809px 818px #fff, 1233px 535px #fff, 1895px 1633px #fff,
    74px 811px #fff, 416px 82px #fff, 1326px 1692px #fff, 1801px 179px #fff,
    118px 592px #fff, 74px 1683px #fff, 1395px 1991px #fff, 596px 1992px #fff,
    1504px 1670px #fff, 1787px 365px #fff, 432px 339px #fff, 353px 164px #fff,
    782px 1929px #fff, 1445px 1872px #fff, 1829px 473px #fff, 234px 505px #fff,
    1120px 1497px #fff, 244px 1196px #fff, 545px 1505px #fff, 132px 1602px #fff,
    490px 131px #fff, 1720px 1349px #fff, 1386px 1976px #fff, 1224px 1091px #fff,
    1448px 1516px #fff, 989px 943px #fff, 408px 618px #fff, 815px 793px #fff,
    267px 1196px #fff, 301px 1907px #fff, 1821px 1299px #fff, 1188px 1161px #fff,
    608px 534px #fff, 415px 477px #fff, 705px 1987px #fff, 797px 661px #fff,
    602px 777px #fff, 1113px 1413px #fff, 1208px 1584px #fff, 1932px 212px #fff,
    704px 444px #fff, 637px 651px #fff, 942px 1932px #fff, 393px 1178px #fff,
    1781px 467px #fff, 604px 774px #fff, 1703px 76px #fff, 1769px 1705px #fff,
    1140px 1673px #fff, 1281px 1734px #fff, 1288px 666px #fff, 1117px 858px #fff,
    28px 1860px #fff, 105px 1498px #fff, 417px 366px #fff, 1591px 1754px #fff,
    1269px 574px #fff, 337px 548px #fff, 596px 1114px #fff, 974px 507px #fff,
    1791px 297px #fff, 966px 914px #fff, 1408px 1234px #fff, 1465px 565px #fff,
    648px 1415px #fff, 1173px 1507px #fff, 838px 1904px #fff, 308px 755px #fff,
    792px 1572px #fff, 410px 362px #fff, 994px 1401px #fff, 716px 1918px #fff,
    689px 592px #fff, 94px 843px #fff, 1814px 1487px #fff, 453px 467px #fff,
    1213px 1757px #fff, 697px 1728px #fff, 1692px 286px #fff, 263px 97px #fff,
    117px 1658px #fff, 1047px 1775px #fff, 896px 1647px #fff, 1626px 1039px #fff,
    1631px 1728px #fff, 1488px 851px #fff, 953px 110px #fff, 84px 855px #fff,
    160px 1143px #fff, 1406px 72px #fff, 887px 1482px #fff, 1953px 795px #fff,
    109px 1913px #fff, 1960px 1981px #fff, 669px 920px #fff, 1904px 804px #fff,
    1296px 1011px #fff, 1736px 1973px #fff, 1352px 1365px #fff,
    1510px 255px #fff, 1465px 1078px #fff, 137px 1030px #fff, 1353px 1962px #fff,
    1792px 598px #fff, 776px 401px #fff, 1536px 622px #fff, 1064px 614px #fff,
    439px 85px #fff, 820px 1358px #fff, 55px 793px #fff, 564px 921px #fff,
    961px 1964px #fff, 1154px 122px #fff, 340px 1209px #fff, 666px 1594px #fff,
    788px 1226px #fff, 794px 1073px #fff, 1879px 1968px #fff, 319px 1181px #fff,
    812px 208px #fff, 1355px 1290px #fff, 1506px 1012px #fff, 1296px 1707px #fff,
    1831px 33px #fff, 1673px 385px #fff, 1312px 1765px #fff, 989px 475px #fff,
    75px 921px #fff, 77px 1814px #fff, 1262px 1916px #fff, 371px 1095px #fff,
    418px 721px #fff, 1280px 672px #fff, 1386px 88px #fff, 1213px 815px #fff,
    1412px 527px #fff, 119px 176px #fff, 1156px 354px #fff, 671px 1528px #fff,
    888px 1614px #fff, 1438px 922px #fff, 1439px 1631px #fff, 782px 641px #fff,
    294px 1671px #fff, 158px 106px #fff, 227px 248px #fff, 1565px 939px #fff,
    406px 1107px #fff, 120px 851px #fff, 1093px 253px #fff, 377px 1373px #fff,
    525px 1664px #fff, 175px 882px #fff, 1664px 954px #fff, 16px 1707px #fff,
    44px 1532px #fff, 1809px 1247px #fff, 778px 1003px #fff, 38px 1343px #fff,
    140px 1066px #fff, 747px 1826px #fff, 1659px 183px #fff, 1033px 217px #fff,
    1413px 428px #fff, 1214px 1499px #fff, 82px 1822px #fff, 933px 626px #fff,
    363px 126px #fff, 274px 958px #fff, 1140px 477px #fff, 1299px 1725px #fff,
    1679px 1195px #fff, 400px 201px #fff, 1047px 1073px #fff, 1232px 42px #fff,
    168px 1520px #fff, 828px 26px #fff, 1408px 1064px #fff, 985px 827px #fff,
    931px 529px #fff, 1336px 312px #fff, 40px 1424px #fff, 1891px 194px #fff,
    807px 1078px #fff, 1807px 1434px #fff, 807px 1704px #fff, 529px 1996px #fff,
    1923px 1012px #fff, 977px 23px #fff, 405px 1851px #fff, 376px 720px #fff,
    79px 1586px #fff, 592px 1804px #fff, 1682px 1617px #fff, 702px 1592px #fff,
    181px 413px #fff, 17px 586px #fff, 631px 184px #fff, 1339px 1564px #fff,
    61px 514px #fff, 912px 1708px #fff, 60px 1799px #fff, 854px 1629px #fff,
    625px 1688px #fff, 1289px 1421px #fff, 700px 1916px #fff, 491px 725px #fff,
    1817px 631px #fff, 187px 1158px #fff, 1958px 599px #fff, 692px 471px #fff,
    1949px 1053px #fff, 1220px 1491px #fff, 1423px 1667px #fff,
    666px 1474px #fff, 661px 1229px #fff, 1118px 742px #fff, 1592px 1427px #fff,
    424px 908px #fff, 1929px 1036px #fff, 164px 96px #fff, 474px 666px #fff,
    689px 548px #fff, 568px 1201px #fff, 1968px 1071px #fff, 337px 1721px #fff,
    712px 1404px #fff, 624px 1602px #fff, 763px 1623px #fff, 195px 516px #fff,
    206px 66px #fff, 591px 739px #fff, 217px 1868px #fff, 224px 1768px #fff,
    1611px 966px #fff, 1848px 1485px #fff, 169px 1125px #fff, 1133px 905px #fff,
    738px 1681px #fff, 1462px 957px #fff;
  border-radius: 50%;
}

#stars2 {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 1233px 2616px #fff, 1157px 1439px #fff, 1545px 324px #fff,
    1057px 207px #fff, 1533px 1760px #fff, 1912px 1118px #fff, 536px 1800px #fff,
    1342px 1926px #fff, 192px 510px #fff, 445px 1875px #fff, 1563px 1538px #fff,
    1175px 1122px #fff, 1681px 1327px #fff, 1998px 477px #fff, 817px 81px #fff,
    229px 1078px #fff, 1178px 908px #fff, 1692px 1276px #fff, 103px 1943px #fff,
    580px 1377px #fff, 1923px 1736px #fff, 1677px 117px #fff, 1640px 1548px #fff,
    542px 1104px #fff, 914px 803px #fff, 142px 673px #fff, 368px 739px #fff,
    451px 1695px #fff, 318px 188px #fff, 523px 1202px #fff, 1329px 984px #fff,
    303px 169px #fff, 501px 1470px #fff, 935px 767px #fff, 729px 148px #fff,
    356px 1280px #fff, 1769px 1328px #fff, 542px 265px #fff, 691px 1736px #fff,
    1716px 1552px #fff, 960px 432px #fff, 1560px 744px #fff, 1427px 874px #fff,
    237px 391px #fff, 80px 1867px #fff, 618px 1114px #fff, 966px 426px #fff,
    1312px 580px #fff, 1908px 983px #fff, 1098px 1836px #fff, 803px 698px #fff,
    1576px 645px #fff, 747px 698px #fff, 1982px 1216px #fff, 110px 146px #fff,
    1465px 689px #fff, 1107px 751px #fff, 1292px 890px #fff, 1160px 513px #fff,
    1466px 1027px #fff, 1751px 1080px #fff, 1814px 1867px #fff, 838px 928px #fff,
    1091px 1001px #fff, 1297px 1397px #fff, 209px 1313px #fff,
    1567px 1276px #fff, 1184px 914px #fff, 1256px 1742px #fff,
    1030px 1522px #fff, 1550px 572px #fff, 887px 335px #fff, 1794px 877px #fff,
    1016px 693px #fff, 314px 685px #fff, 1455px 1998px #fff, 462px 496px #fff,
    1332px 914px #fff, 112px 1464px #fff, 1630px 1618px #fff, 1016px 1891px #fff,
    1611px 875px #fff, 1847px 750px #fff, 1289px 1464px #fff, 1341px 1167px #fff,
    1579px 1609px #fff, 45px 1388px #fff, 1836px 1235px #fff, 196px 1811px #fff,
    1130px 1323px #fff, 1474px 31px #fff, 1029px 571px #fff, 1644px 1171px #fff,
    1702px 1986px #fff, 1583px 256px #fff, 1995px 353px #fff, 222px 776px #fff,
    647px 506px #fff, 177px 1185px #fff, 1873px 1210px #fff, 334px 1665px #fff,
    628px 967px #fff, 1165px 1717px #fff, 1108px 983px #fff, 1696px 641px #fff,
    1526px 702px #fff, 331px 1402px #fff, 1688px 547px #fff, 1558px 155px #fff,
    342px 1729px #fff, 644px 1937px #fff, 831px 1817px #fff, 1382px 1871px #fff,
    120px 244px #fff, 1458px 195px #fff, 1714px 353px #fff, 1000px 630px #fff,
    1347px 574px #fff, 1530px 260px #fff, 1661px 1180px #fff, 404px 973px #fff,
    776px 1062px #fff, 436px 975px #fff, 1203px 1158px #fff, 1570px 1099px #fff,
    1296px 212px #fff, 623px 250px #fff, 987px 765px #fff, 1105px 616px #fff,
    903px 1737px #fff, 1062px 266px #fff, 584px 1671px #fff, 63px 402px #fff,
    373px 754px #fff, 1638px 304px #fff, 1287px 940px #fff, 603px 130px #fff,
    1863px 850px #fff, 763px 890px #fff, 438px 1948px #fff, 1042px 1113px #fff,
    1086px 1072px #fff, 1414px 149px #fff, 1107px 762px #fff, 1225px 280px #fff,
    242px 87px #fff, 310px 1071px #fff, 1843px 905px #fff, 1344px 1520px #fff,
    1031px 870px #fff, 658px 325px #fff, 1099px 427px #fff, 232px 1959px #fff,
    1760px 1756px #fff, 75px 936px #fff, 540px 171px #fff, 657px 462px #fff,
    1207px 824px #fff, 1824px 948px #fff, 1931px 307px #fff, 356px 285px #fff,
    399px 434px #fff, 1893px 1633px #fff, 1968px 79px #fff, 716px 159px #fff,
    1276px 1624px #fff, 619px 1111px #fff, 755px 1896px #fff, 633px 563px #fff,
    1934px 216px #fff, 878px 94px #fff, 728px 1208px #fff, 1426px 479px #fff,
    1931px 1049px #fff, 195px 931px #fff, 1615px 1845px #fff, 720px 1339px #fff,
    1262px 702px #fff, 1376px 1664px #fff, 853px 1947px #fff, 1248px 1385px #fff,
    1624px 1124px #fff, 1747px 154px #fff, 407px 1707px #fff, 1180px 914px #fff,
    238px 1513px #fff, 1059px 1530px #fff, 18px 1279px #fff, 1625px 1297px #fff,
    1468px 1651px #fff, 1812px 440px #fff, 30px 587px #fff, 1980px 516px #fff,
    566px 1613px #fff, 579px 907px #fff, 1349px 716px #fff, 1130px 1390px #fff,
    1065px 1762px #fff, 1104px 1648px #fff, 929px 1617px #fff;
  animation: animStar 100s linear infinite;
}

#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 50%;

  box-shadow: 1233px 2616px #fff, 1157px 1439px #fff, 1545px 324px #fff,
    1057px 207px #fff, 1533px 1760px #fff, 1912px 1118px #fff, 536px 1800px #fff,
    1342px 1926px #fff, 192px 510px #fff, 445px 1875px #fff, 1563px 1538px #fff,
    1175px 1122px #fff, 1681px 1327px #fff, 1998px 477px #fff, 817px 81px #fff,
    229px 1078px #fff, 1178px 908px #fff, 1692px 1276px #fff, 103px 1943px #fff,
    580px 1377px #fff, 1923px 1736px #fff, 1677px 117px #fff, 1640px 1548px #fff,
    542px 1104px #fff, 914px 803px #fff, 142px 673px #fff, 368px 739px #fff,
    451px 1695px #fff, 318px 188px #fff, 523px 1202px #fff, 1329px 984px #fff,
    303px 169px #fff, 501px 1470px #fff, 935px 767px #fff, 729px 148px #fff,
    356px 1280px #fff, 1769px 1328px #fff, 542px 265px #fff, 691px 1736px #fff,
    1716px 1552px #fff, 960px 432px #fff, 1560px 744px #fff, 1427px 874px #fff,
    237px 391px #fff, 80px 1867px #fff, 618px 1114px #fff, 966px 426px #fff,
    1312px 580px #fff, 1908px 983px #fff, 1098px 1836px #fff, 803px 698px #fff,
    1576px 645px #fff, 747px 698px #fff, 1982px 1216px #fff, 110px 146px #fff,
    1465px 689px #fff, 1107px 751px #fff, 1292px 890px #fff, 1160px 513px #fff,
    1466px 1027px #fff, 1751px 1080px #fff, 1814px 1867px #fff, 838px 928px #fff,
    1091px 1001px #fff, 1297px 1397px #fff, 209px 1313px #fff,
    1567px 1276px #fff, 1184px 914px #fff, 1256px 1742px #fff,
    1030px 1522px #fff, 1550px 572px #fff, 887px 335px #fff, 1794px 877px #fff,
    1016px 693px #fff, 314px 685px #fff, 1455px 1998px #fff, 462px 496px #fff,
    1332px 914px #fff, 112px 1464px #fff, 1630px 1618px #fff, 1016px 1891px #fff,
    1611px 875px #fff, 1847px 750px #fff, 1289px 1464px #fff, 1341px 1167px #fff,
    1579px 1609px #fff, 45px 1388px #fff, 1836px 1235px #fff, 196px 1811px #fff,
    1130px 1323px #fff, 1474px 31px #fff, 1029px 571px #fff, 1644px 1171px #fff,
    1702px 1986px #fff, 1583px 256px #fff, 1995px 353px #fff, 222px 776px #fff,
    647px 506px #fff, 177px 1185px #fff, 1873px 1210px #fff, 334px 1665px #fff,
    628px 967px #fff, 1165px 1717px #fff, 1108px 983px #fff, 1696px 641px #fff,
    1526px 702px #fff, 331px 1402px #fff, 1688px 547px #fff, 1558px 155px #fff,
    342px 1729px #fff, 644px 1937px #fff, 831px 1817px #fff, 1382px 1871px #fff,
    120px 244px #fff, 1458px 195px #fff, 1714px 353px #fff, 1000px 630px #fff,
    1347px 574px #fff, 1530px 260px #fff, 1661px 1180px #fff, 404px 973px #fff,
    776px 1062px #fff, 436px 975px #fff, 1203px 1158px #fff, 1570px 1099px #fff,
    1296px 212px #fff, 623px 250px #fff, 987px 765px #fff, 1105px 616px #fff,
    903px 1737px #fff, 1062px 266px #fff, 584px 1671px #fff, 63px 402px #fff,
    373px 754px #fff, 1638px 304px #fff, 1287px 940px #fff, 603px 130px #fff,
    1863px 850px #fff, 763px 890px #fff, 438px 1948px #fff, 1042px 1113px #fff,
    1086px 1072px #fff, 1414px 149px #fff, 1107px 762px #fff, 1225px 280px #fff,
    242px 87px #fff, 310px 1071px #fff, 1843px 905px #fff, 1344px 1520px #fff,
    1031px 870px #fff, 658px 325px #fff, 1099px 427px #fff, 232px 1959px #fff,
    1760px 1756px #fff, 75px 936px #fff, 540px 171px #fff, 657px 462px #fff,
    1207px 824px #fff, 1824px 948px #fff, 1931px 307px #fff, 356px 285px #fff,
    399px 434px #fff, 1893px 1633px #fff, 1968px 79px #fff, 716px 159px #fff,
    1276px 1624px #fff, 619px 1111px #fff, 755px 1896px #fff, 633px 563px #fff,
    1934px 216px #fff, 878px 94px #fff, 728px 1208px #fff, 1426px 479px #fff,
    1931px 1049px #fff, 195px 931px #fff, 1615px 1845px #fff, 720px 1339px #fff,
    1262px 702px #fff, 1376px 1664px #fff, 853px 1947px #fff, 1248px 1385px #fff,
    1624px 1124px #fff, 1747px 154px #fff, 407px 1707px #fff, 1180px 914px #fff,
    238px 1513px #fff, 1059px 1530px #fff, 18px 1279px #fff, 1625px 1297px #fff,
    1468px 1651px #fff, 1812px 440px #fff, 30px 587px #fff, 1980px 516px #fff,
    566px 1613px #fff, 579px 907px #fff, 1349px 716px #fff, 1130px 1390px #fff,
    1065px 1762px #fff, 1104px 1648px #fff, 929px 1617px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  border-radius: 50%;

  box-shadow: 2px 11363px #fff, 384px 1342px #fff, 1454px 748px #fff,
    627px 1588px #fff, 110px 1101px #fff, 1536px 4px #fff, 1042px 918px #fff,
    392px 677px #fff, 289px 1647px #fff, 734px 672px #fff, 662px 764px #fff,
    448px 1769px #fff, 88px 902px #fff, 293px 684px #fff, 1931px 588px #fff,
    954px 67px #fff, 397px 615px #fff, 1147px 1580px #fff, 37px 542px #fff,
    585px 165px #fff, 1925px 238px #fff, 1284px 1867px #fff, 368px 463px #fff,
    634px 1689px #fff, 1104px 1571px #fff, 78px 933px #fff, 40px 619px #fff,
    237px 806px #fff, 1855px 1014px #fff, 504px 1483px #fff, 1022px 889px #fff,
    523px 1283px #fff, 549px 852px #fff, 826px 1076px #fff, 62px 258px #fff,
    406px 151px #fff, 818px 279px #fff, 633px 967px #fff, 1118px 665px #fff,
    575px 475px #fff, 1035px 534px #fff, 595px 266px #fff, 1348px 180px #fff,
    379px 927px #fff, 212px 1226px #fff, 512px 143px #fff, 496px 1830px #fff,
    1625px 970px #fff, 1988px 139px #fff, 1777px 1504px #fff, 1101px 891px #fff,
    1875px 1996px #fff, 679px 691px #fff, 1179px 1649px #fff, 1725px 345px #fff,
    1991px 1139px #fff, 852px 1001px #fff, 1596px 1412px #fff, 458px 1000px #fff,
    1884px 651px #fff, 1348px 1932px #fff, 365px 1118px #fff, 497px 1170px #fff,
    235px 650px #fff, 218px 1731px #fff, 982px 790px #fff, 1587px 1247px #fff,
    567px 467px #fff, 428px 357px #fff, 234px 813px #fff, 1407px 208px #fff,
    434px 363px #fff, 1423px 1028px #fff, 1911px 347px #fff, 918px 1023px #fff,
    1492px 1438px #fff, 1357px 1984px #fff, 236px 582px #fff, 882px 532px #fff,
    759px 729px #fff, 1683px 947px #fff, 1748px 1652px #fff, 1342px 1385px #fff,
    426px 429px #fff, 1147px 43px #fff, 960px 1277px #fff, 1110px 30px #fff,
    1931px 995px #fff, 1233px 1769px #fff, 1427px 1200px #fff, 1485px 835px #fff,
    1254px 1474px #fff, 1832px 630px #fff, 121px 582px #fff, 1258px 845px #fff,
    980px 1919px #fff, 2000px 92px #fff, 705px 1628px #fff, 462px 685px #fff,
    626px 1984px #fff;
  animation: animStar 150s linear infinite;
}

#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  border-radius: 50%;

  height: 3px;
  background: transparent;
  box-shadow: 2px 11363px #fff, 384px 1342px #fff, 1454px 748px #fff,
    627px 1588px #fff, 110px 1101px #fff, 1536px 4px #fff, 1042px 918px #fff,
    392px 677px #fff, 289px 1647px #fff, 734px 672px #fff, 662px 764px #fff,
    448px 1769px #fff, 88px 902px #fff, 293px 684px #fff, 1931px 588px #fff,
    954px 67px #fff, 397px 615px #fff, 1147px 1580px #fff, 37px 542px #fff,
    585px 165px #fff, 1925px 238px #fff, 1284px 1867px #fff, 368px 463px #fff,
    634px 1689px #fff, 1104px 1571px #fff, 78px 933px #fff, 40px 619px #fff,
    237px 806px #fff, 1855px 1014px #fff, 504px 1483px #fff, 1022px 889px #fff,
    523px 1283px #fff, 549px 852px #fff, 826px 1076px #fff, 62px 258px #fff,
    406px 151px #fff, 818px 279px #fff, 633px 967px #fff, 1118px 665px #fff,
    575px 475px #fff, 1035px 534px #fff, 595px 266px #fff, 1348px 180px #fff,
    379px 927px #fff, 212px 1226px #fff, 512px 143px #fff, 496px 1830px #fff,
    1625px 970px #fff, 1988px 139px #fff, 1777px 1504px #fff, 1101px 891px #fff,
    1875px 1996px #fff, 679px 691px #fff, 1179px 1649px #fff, 1725px 345px #fff,
    1991px 1139px #fff, 852px 1001px #fff, 1596px 1412px #fff, 458px 1000px #fff,
    1884px 651px #fff, 1348px 1932px #fff, 365px 1118px #fff, 497px 1170px #fff,
    235px 650px #fff, 218px 1731px #fff, 982px 790px #fff, 1587px 1247px #fff,
    567px 467px #fff, 428px 357px #fff, 234px 813px #fff, 1407px 208px #fff,
    434px 363px #fff, 1423px 1028px #fff, 1911px 347px #fff, 918px 1023px #fff,
    1492px 1438px #fff, 1357px 1984px #fff, 236px 582px #fff, 882px 532px #fff,
    759px 729px #fff, 1683px 947px #fff, 1748px 1652px #fff, 1342px 1385px #fff,
    426px 429px #fff, 1147px 43px #fff, 960px 1277px #fff, 1110px 30px #fff,
    1931px 995px #fff, 1233px 1769px #fff, 1427px 1200px #fff, 1485px 835px #fff,
    1254px 1474px #fff, 1832px 630px #fff, 121px 582px #fff, 1258px 845px #fff,
    980px 1919px #fff, 2000px 92px #fff, 705px 1628px #fff, 462px 685px #fff,
    626px 1984px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.croen {
  position: relative;
}

.croen img {
  position: absolute;
  width: 24px;
  top: 0px;
  left: -27px;
  transform: rotate(0deg);
}

/* new style launchpad */
.titte_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titte_section small {
  color: #fff;
  font-size: 25px;
  font-family: var(--main-Medium);
}

.titte_section h2 {
  font-size: 44px;
  font-family: var(--main-Bold);
  color: #fff;
  margin: 0;
  padding: 15px 0px;
  padding-top: 10px;
}

.titte_section p {
  color: #888b90;
  font-family: var(--main-Medium);
  margin: 0;
}

.titte_section h2 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card_projece {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 9%);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgb(255 255 255 / 16%);
  overflow: hidden;
  margin: 40px 0px;
}

.card_projece .project_imag {
  position: relative;
  margin: 15px;
  border-radius: 10px;
  overflow: hidden;
}

.card_projece .network_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 1px;
  border: 1px solid #fcb97f;
  border-radius: 22px;
}

.card_projece .network_icon img {
  width: 31px;
}

.project_name {
  display: flex;
  justify-content: flex-start;
  align-items: self-end;
  padding: 15px;
  margin-top: -54px;
  position: relative;
  z-index: 5;
}

.project_name .project_logo {
  padding: 0px;
  border: 1px solid #fcbf84;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.project_name .project_logo img {
  width: 60px;
}

.project_name h3 {
  color: #fff;
  font-size: 22px;
  font-family: var(--main-Medium);
  margin: 0;
}

.project_detiles {
  padding: 15px;
  padding-top: 0;
}

.project_detiles p {
  margin: 0;
  color: #fff;
  font-family: var(--main-Medium);
  margin: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.project_detiles p span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project_progress {
  padding: 15px;
  padding-top: 0px;
}

.project_progress p {
  margin: 0;
  color: #fff;
  font-family: var(--main-Medium);
  margin: 0;
  font-size: 16px;
  margin-bottom: 5px;
}

.project_progress h4 {
  font-size: 20px;
  font-family: var(--main-Bold);
  color: #fff;
  margin: 0;
  margin-bottom: 10px;
}

.project_progress .progress {
  border-radius: 10px;
  background: #2f2e2e;
}

.project_progress .progress .progress-bar {
  width: 50%;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  border-radius: 13px;
}

.project_progress h4 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 5px;
}

.button_sectino {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.button_sectino button {
  color: #fff;
  padding: 11px 34px;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
  margin-top: 0;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  /* margin-bottom: 15px; */
}

.button_sectino button:hover {
  color: #fff;
  padding: 11px 34px;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
  margin-top: 0;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  /* margin-bottom: 15px; */
}

.section_style {
  padding: 50px 0px;
}

.secure_feture {
  padding: 20px;
  margin-top: 20px;
}

.secure_feture img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(138deg)
    brightness(102%) contrast(103%);
  width: 51px;
  margin: 0 auto;
  display: block;
  margin-bottom: 16px;
}

.secure_feture h4 {
  color: #fff;
  font-size: 22px;
  font-family: var(--main-Medium);
  margin-bottom: 10px;
  text-align: center;
}

.secure_feture p {
  color: #888b90;
  font-family: var(--main-Medium);
  margin: 0;
  text-align: center;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.tokandetils .project_name {
  margin: 0;
  align-items: center;
}

.tokandetils .project_name p {
  color: #888b90;
  font-family: var(--main-Medium);
  margin: 0;
}

.network_icon_deiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.network_icon_deiv .network_icon {
  padding: 1px;
  border: 1px solid #fcb97f;
  border-radius: 22px;
  width: fit-content;
}

.network_icon_deiv .network_icon img {
  width: 33px;
}

.exact_date p {
  color: #fff;
  font-family: var(--main-Medium);
  margin: 0;
}

.completed_project {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 9%);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgb(255 255 255 / 16%);
  overflow: hidden;
  margin: 40px 0px;
  padding: 15px 0px;
  transition: all 0.3s;
  margin-bottom: 0;
}

.completed_project .project_progress {
  width: 100%;
}

.completed_project:hover,
.card_projece:hover {
  cursor: pointer;
  border: 1px solid #f4663e;
}

.card_projece {
  transition: all 0.3s;
}

.colo_white {
  background: linear-gradient(106.19deg, #fffbfa, #fffdfa);
  margin-top: 25px;
  border-radius: 50px 50px 0px 0px;
  padding-top: 70px;
}

.scrolled .nav_link_section a {
  color: #000;
}

.header.scrolled > header {
  width: 100%;
  z-index: 999;
  background: hsla(0, 0%, 5%, 0.005);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  border-bottom: 0.5px solid;
  border-bottom: 0.5px solid #1212124f !important;
  box-shadow: none !important;
}

.header.scrolled .blackshow {
  display: none;
}

.header .blackshow {
  display: block;
}

.header.scrolled .whiteshow {
  display: block;
}

.header .whiteshow {
  display: none;
}

.content_white_first h3 {
  color: #000;
  font-family: var(--main-Bold);
  font-size: 46px;
  margin-bottom: 30px;
}

.content_white_first h3 span {
  color: #f57348;
}

.content_white_first p {
  font-size: 19px;
  color: #000;
  font-family: var(--main-Medium);
  margin: 0;
  margin-bottom: 25px;
}

.content_white_first button {
  background: #050505;
  color: #fff;
  text-transform: capitalize;
  font-size: 17px;
  padding: 10px 19px;
  margin-top: 10px;
}

.applay_sectoion {
  height: 100vh;
  background: #050505;
  border-radius: 50px;
}

.applay_sectoion {
  height: 70vh;
  background: #050505;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.applay_sectoion h2 {
  font-family: var(--main-Bold);
  font-size: 53px;
  color: #fff;
  text-align: center;
}

.applay_sectoion h2 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.applay_sectoion button,
.applay_sectoion button:hover {
  background: #fff;
  text-transform: capitalize;
  color: #000;
  padding: 12px 37px;
  font-size: 18px;
  margin-top: 27px;
}

.title_section_project h1 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section_projec {
  padding: 125px 0px;
}

.title_section_project h1 {
  font-size: 67px;
  color: #fff;
  font-family: var(--main-Bold);
  text-align: center;
}

.card_gray {
  background-color: #e5e5e678;
  border-radius: 10px;
  box-shadow: none;
  padding: 27px 25px;
}

.card_gray .imag_section_detile {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.card_gray .imag_section_detile .image_coin_s {
  width: 15%;
  padding: 5px;
  border: 1px solid #f6794d;
  border-radius: 10px;
  margin-right: 12px;
}

.card_gray .imag_section_detile .image_coin_s img {
  width: 100%;
  margin: 0px auto;
  display: block;
}

.Content_dive {
  width: 85%;
}

.Content_dive h3 {
  font-family: var(--main-Bold);
  font-size: 24px;
}

.Content_dive p {
  color: #6f7277;
  margin-top: 8px;
  display: block;
  font-family: var(--main-Light);
}

.button_action a {
  color: #fff;
  padding: 7px 15px;
  border: 0px solid #ccc;
  margin-right: 10px;
  display: block;
  background: #050505;
  width: fit-content;
  border-radius: 10px;
  font-family: var(--main-Medium);
  text-decoration: none;
}

.button_action {
  display: flex;
  padding: 10px 0px;
}

.white_card_ {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
}

.white_card_ .conytengt_white {
  width: 47%;
  padding: 15px 10px;
  background: #f3f1f0;
  border-radius: 10px;
  margin: 5px;
}

.white_card_ .conytengt_white span {
  font-family: var(--main-Bold);
  font-size: 15px;
  color: #f4663e;
  margin-bottom: 5px;
  display: block;
}

.white_card_ .conytengt_white h4 {
  font-family: var(--main-Bold);
  font-size: 16px;
  margin: 0;
}

.card_gray.coin_price_detils {
  padding: 15px 10px;
}

.text_class_fkes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.token_title {
  display: flex;
  align-items: center;
}

.token_title span {
  width: 30px;
  height: 30px;
  font-family: var(--main-Bold);
  font-size: 20px;
  display: grid;
  place-content: center;
  background: #ccc;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  color: #fff;
  border-radius: 7px;
  margin-right: 8px;
}

.token_title p {
  margin: 0;
  font-family: var(--main-Bold);
  font-size: 14px;
}

.class_yehr h4 {
  font-family: var(--main-Bold);
  font-size: 18px;
  margin: 0;
}

.card_gray_heade {
  padding: 10px 0px;
  margin-top: 17px;
}

.text_sfdf {
  font-family: var(--main-Bold);
  font-size: 18px;
  margin: 0;
  padding: 4px 10px;
}

.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f2f2f2;
  width: 10rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.tabs button {
  margin-bottom: 1rem;
  padding: 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.tabs button.active {
  color: white;
  background-color: #333;
}

.content {
  display: flex;
  flex-direction: column;
}

.stickytaba {
  /* position: relative; */
  position: sticky;
  top: 90px;
}

.active_tabs_section {
  list-style: none;
  background-color: #e5e5e678;
  border-radius: 10px;
  box-shadow: none;
  overflow: hidden;
  margin-top: 15px;
}

.active_tabs_section li {
  margin: 0;
  font-family: var(--main-Bold);
  font-size: 18px;
  padding: 15px 20px;
  overflow: hidden;
  cursor: pointer;
}

.active_tabs_section li.active {
  background: #f4663e;
  color: #fff;
}

.active_tabs_section li i {
  font-size: 25px;
  margin-right: 5px;
}

.section_seleecd {
  background: rgb(243, 241, 240);
  padding: 25px 25px;
  margin: 14px 0px;
  border-radius: 10px;
}

.section_seleecd h2 {
  margin: 0;
  font-family: var(--main-Bold);
  font-size: 23px;
  margin-bottom: 11px;
}

.section_seleecd p {
  color: #6f7277;
  font-family: var(--main-Medium);
  margin-bottom: 15px;
}

.section_seleecd img {
  margin: 15px auto;
}

.content_side_br ul {
  padding: 0px 18px;
}

.content_side_br ul li {
  color: #6f7277;
  font-family: var(--main-Medium);
  margin-bottom: 5px;
}

.content_side_br ul li::marker {
  color: #f4663e;
}

.seprate_content {
  background-color: #e5e5e678;
  padding: 15px 20px;
  border-radius: 10px;
}

.seprate_content ul {
  list-style: none;
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
}

.seprate_content ul li a {
  width: 35px;
  display: block;
  height: 35px;
  display: grid;
  place-content: center;
  color: #000;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #f3663e;
  margin-right: 5px;
  font-size: 16px;
  text-decoration: none;
}

.seprate_content h3 {
  margin: 0;
  font-family: var(--main-Bold);
  font-size: 20px;
  margin-bottom: 11px;
}

.link_section1 {
  width: 100%;
  padding: 10px 13px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f4663e;
  color: #6f7277;
  font-family: var(--main-Medium);
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link_section1 a {
  display: flex;
  font-size: 24px;
  color: #f4663e;
  text-decoration: none;
}

.colo_section {
  background: #fff;
}

.applay_form_title h2 {
  font-size: 40px;
  color: #000;
  font-family: var(--main-Bold);
  text-align: center;
  margin-bottom: 40px;
}

.form_secrtion h3 {
  font-family: var(--main-Bold);
  font-size: 22px;
  margin: 0;
  padding: 10px 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc5e;
  padding-bottom: 15px;
}

.form_secrtion label {
  color: #888b90;
  font-size: 16px;
  font-family: var(--main-Medium);
  margin-bottom: 14px;
}

.form_secrtion .form-control {
  background: #fff;
  height: 53px;
  border: 1px solid #cccccc45;
}

.form_secrtion .marginf {
  margin-bottom: 15px;
}

.form_secrtion {
  padding: 20px;
}

.text_area_ {
  display: flex;
  flex-direction: column;
}

.text_area_ textarea {
  background: #fff;
  height: 100px;
  border: 1px solid #cccccc45;
}

.marginf .react-dropdown-select {
  background: #fff;
  height: 53px;
  border: 1px solid #cccccc45;
}

button.form_submit {
  text-transform: capitalize;
  color: #fff;
  background: #ccc;
  padding: 13px 35px;
  margin: 0 auto;
  display: block;
  margin-top: 27px;
  border-radius: 5px !important;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  color: #fff !important;
}

.login_form {
  display: flex;
  /* align-items: center; */
  min-height: 100vh;
}

.card_login {
  padding: 3em 3rem;
  border-radius: 1rem;
  flex-direction: column;
  background-color: #fff;
}

.card_login h3 {
  color: #000;
  font-size: 30px;
  margin-bottom: 17px;
  font-weight: 700;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 34px;
}

.card_login .form-control {
  height: 63px;
  border-radius: 6px;
  margin-bottom: 25px;
  border-color: #e4e4e4;
  padding: 21px;
}

.form_login_input label {
  box-sizing: border-box;
  margin: 0px 0px 4px;
  min-width: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  align-items: center;
  color: #8c92ab;
  cursor: auto;
}

.form_login_input button,
.form_login_input button:hover {
  padding: 19px 44px;
  font-size: 22px;
  background: rgba(5, 5, 5);
  /* font-family: Kanit; */
  font-weight: 600;
  line-height: 26.4px;
  border-radius: 12px;
  text-transform: capitalize;
  color: #fff;
  width: 100%;
  margin: 0px;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  outline: none;
  padding: 13px 24px;
  line-height: 26px;
  min-width: 80px;
  word-break: keep-all;
  color: #fff;
  border-radius: 5px;
  min-height: 24px;
  border: none;
  background: linear-gradient(180deg, #0bd3f0 0%, #0c2af4 100%), #ffc72b;
  white-space: normal;
  width: 100%;
  height: 63px;
}

.Butn_header,
.Butn_header:hover {
  text-transform: capitalize !important;
  padding: 5px 20px !important;
  box-shadow: none !important;
  border-radius: 15px !important;
  margin-right: 11px;
  text-decoration: none;
  background: #fcd535 !important;
  color: #000 !important;
  font-weight: 400;
  background: #0b49f3 !important;
  color: #fff !important;
}

/* binance UI */
.section_one {
  background-image: url(./Image/web-background-image1.png);
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
}

.button_section_width {
  width: 70%;
  margin-top: 10px;
}

.primery_color.secodery-color {
  background: #eaecef !important;
}

.content_botton_banner {
  padding: 26px 0px;
}

.content_botton_banner h2 {
  font-weight: 700;
  color: #1e2329;
}

.content_botton_banner p {
  color: #1e2329;
  font-weight: 300;
}

.two_section {
  background: #f5f5f7;
}

.two_section .inner {
  padding: 60px 0px;
  border-radius: 43px 43px 0px 0px;
  background: linear-gradient(176.23deg, #f6f7fa 0.42%, #faf7f6 99.1%);
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #fcd42f !important;
}

.slick-dots li button:before {
  opacity: 1;
  color: #f5f5f7 !important;
  font-size: 16px;
}

.slick-dots {
  bottom: -33px;
}

.heading_h2 {
  color: #1e2329;
  font-weight: 700;
  font-size: 40px;
  margin: 0;
  letter-spacing: 0.5px;
}

.hrf_text {
  margin: 0;
  color: #707a8a;
}

.table thead th {
  color: #1d1d1d70;
  font-weight: 400;
  font-size: 14px;
}

.coin_section img {
  width: 25px;
  height: 25px;
}

.coin_section span {
  color: #000;
  font-weight: 700;
  padding: 0px 7px;
}

.coin_section small {
  color: #6c7080;
  font-weight: 400;
  font-size: 14px;
}

.coin_section {
  display: flex;
  align-items: center;
}

td > p {
  color: #000;
  font-weight: 500;
  margin: 0;
}

td > p.green_text {
  color: #03a66d !important;
}

.tabel_bottm_ {
  padding: 15px 0px;
}

.tabel_bottm_ h3 {
  color: #1e2329;
  font-weight: 600;
  font-size: 24px;
}

.tabel_bottm_ button,
.tabel_bottm_ button:hover {
  text-transform: capitalize !important;
  padding: 10px 27px !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  margin-right: 11px;
  text-decoration: none;
  background: #fcd535 !important;
  color: #000 !important;
  font-weight: 500;
  margin-top: 14px;
}

.heading_p {
  color: #474d57;
  font-weight: 400;
  margin-top: 11px;
  font-size: 16px;
}

.section_commen {
  padding: 70px 0px;
  background: #060612;
}

.options img {
  width: 100%;
}

.heading_sub h3 {
  font-weight: 700;
  font-size: 21px;
  color: #1e2329;
  margin-bottom: 10px;
  text-align: center;
}

.heading_sub p {
  color: #474d57;
  text-align: center;
  font-weight: 400;
}

.phone_imge {
  margin: 0 auto;
  display: block;
  /* width: 100%; */
}

.options .row {
  margin-top: 40px;
  margin-bottom: 20px;
}

.options {
  margin-top: 50px;
}

.opton_price {
  padding: 15px 15px;
  background: #fafafa;
  border-radius: 10px;
  margin: 20px 0px;
  border: 2px solid #fafafa;
  transition: all 0.3s;
}

.opton_price:hover {
  border: 2px solid #fcd535;
  cursor: pointer;
}

.opton_price small {
  color: #1e2329;
  font-weight: 400;
  font-size: 14px;
}

.opton_price h3 {
  color: #03a66d;
  font-weight: 600;
  font-size: 21px;
}

.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  margin-top: 16px;
}

.card_footer .image_dd {
  color: #1e2329;
  font-weight: 700;
}

.card_footer .image_dd img {
  width: 27px;
  height: 27px;
  margin-right: 5px;
}

.card_footer .image_dd a.arrow_fun {
  width: 30px;
  height: 30px;
  display: grid;
  place-content: center;
  background: #eaecef;
  color: #999;
  border-radius: 31px;
}

.card_footer .image_dd a.arrow_fun i {
  display: flex;
}

a.arrow_fun {
  width: 32px;
  height: 32px;
  display: grid;
  place-content: center;
  background: #eaecef;
  color: #000;
  border-radius: 25px;
}

.image_section_app {
  width: 90%;
  margin: 50px auto;
}

.app_download_szecio .card_qr {
  padding: 10px 0px;
  background: #fafafa;
  border-radius: 8px;
}

.app_download_szecio .card_qr img {
  width: 74%;
  margin: 0 auto;
  display: block;
}

.contene span {
  font-weight: 400;
  color: #707a8a;
  margin-bottom: 8px;
  display: block;
}

.contene p {
  margin: 0;
  font-weight: 600;
  color: #1e2329;
  font-size: 20px;
}

.contene {
  margin: 11px 0px;
}

.contant_app_sec {
  padding: 47px 10px;
}

.contant_app_sec img {
  width: 56px;
  margin: 11px auto;
  display: block;
}

.contant_app_sec h4 {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 21px;
}

.widyth_50 {
  width: 29px;
}

.contant_last button {
  text-transform: capitalize !important;
  padding: 10px 30px !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  margin-right: 11px;
  text-decoration: none;
  background: #fff !important;
  color: #813ef2 !important;
  font-weight: 600;
  margin: 24px auto;
  display: block;
  margin-bottom: 0px;
  border-radius: 70px !important;
}

.contant_last h2 {
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  margin-bottom: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #fcd535;
  outline: 0;
  box-shadow: none;
}

.form-check-label {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex: 1 1 0%;
}

.form-check-label a {
  text-decoration: underline;
  color: rgb(30, 35, 41);
}

.pass_forgee,
.pass_forgee:hover {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  color: rgb(88 65 216);
  text-align: center;
  margin-top: 23px !important;
  text-decoration: none;
}

.form_login_input:focus-visible {
  border-color: #999;
}

.swcirrrr {
  background-image: url("./Image/new_image_home/bg_classs.png");
  padding: 60px 0px;
}

/* newpage_kryptonxa */
.only_border {
  background: transparent !important;
  border: 1px solid #813ef2;
  color: #0c2af4 !important;
}

.color_hss {
  color: #8c92ab;
  margin-bottom: 22px;
}

.form-check {
  margin-bottom: 22px;
}

.copywrigt ul li a {
  width: 35px;
  height: 35px;
  display: block;
  display: grid;
  place-content: center;
  color: #fff;
  background: linear-gradient(180deg, #0bd3f0 0%, #0c2af4 100%), #ffc72b;
  font-size: 22px;
  border-radius: 23px;
  text-decoration: none;
  margin: 0px 10px;
}

.copywrigt ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}

.flex_input_posion {
  position: relative;
}

.flex_input_posion i {
  position: absolute;
  top: 14px;
  right: 19px;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
}

.OTP_inpu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.OTP_inpu input {
  width: 65px !important;
  height: 32px;
  text-align: center;
  margin-right: 0 !important;
  height: 63px !important;
  border-radius: 6px;
  margin-bottom: 25px;
  border: 1px solid #e4e4e4;
  padding: 21px;
  margin: 0px 13px !important;
  margin-bottom: 25px !important;
}

.check {
  font-size: 65px;
  color: green;
}

/* .section_one {
  padding: 140px 0px;
} */

.body_hXuuO {
  -ms-flex-positive: 1;
  -ms-flex-negative: 0;
  margin-top: 64px;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  min-height: 80vh;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.lrtcss-wxjc90 {
  display: flex;
  background: rgb(255, 255, 255);
}

.lrtcss-q484j3 {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: calc(-60px + 100vh);
  border-bottom: 1px solid rgba(29, 29, 29, 0.08);
  position: sticky;
  top: 64px;
}

.lrtcss-yqpjhy {
  list-style: none;
  margin: 0px;
  width: 264px;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  scrollbar-width: none;
  padding: 20px 16px 0px;
  border-right: 1px solid rgba(29, 29, 29, 0.08);
  background: rgb(249, 249, 249);
  transition: all 0.3s ease 0s;
}

.lrtcss-1p2kb7b {
  flex: 1 1 0%;
  min-width: 0px;
  border-bottom: 1px solid rgba(29, 29, 29, 0.08);
}

.lrtcss-sbd8h {
  display: flex;
  flex-direction: column;
  padding: 32px 30px 48px;
}

.sidemenu {
  list-style: none;
  margin: 0;
}

.sidemenu li {
  margin-bottom: 10px;
}

.sidemenu a {
  display: flex;
  font-size: 15px;
  padding: 11px 20px;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.sidemenu a span {
  font-weight: 400;
  color: #000;
}

.sidemenu a i {
  margin-right: 12px;
  font-size: 20px;
  color: #cecece;
}

.sidemenu a.active {
  background: #813ef21f;
  border-radius: 50px;
}

.sidemenu a.active span {
  color: #813ef2;
}

.sidemenu a.active i {
  color: #813ef2;
}

.Dashcard {
  padding: 28px 32px;
  background: rgba(29, 29, 29, 0.02);
  border-radius: 24px;
  border: none;
}

.lrtcss-1fttcpj {
  display: flex;
  flex-direction: column;
}

.lrtcss-1j8rvpu {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 14px;
}

.lrtcss-98pbtq {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  background-color: rgb(1, 188, 141);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  margin-right: 16px;
  flex-shrink: 0;
}

.lrtcss-sq6w90 {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: rgb(29, 29, 29);
  margin-bottom: 0px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.modal-backdrop {
  z-index: 5555;
}

.lrtcss-1cp6ea7 {
  flex: 1 1 0%;
  min-width: 40px;
}

.lrtcss-16euqaz {
  font-size: 16px;
  margin-top: 12px;
  cursor: pointer;
}

.lrtcss-1gnhm8d {
  flex-shrink: 0;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
}

.lrtcss-112vdob {
  display: flex;
  align-items: center;
  background: rgba(29, 29, 29, 0.04);
  border-radius: 38px;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: rgb(29, 29, 29);
  padding: 7px 16px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  gap: 6px;
}

.lrtcss-1iijojv {
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
}

.lrtcss-1j3ym55 {
  flex-shrink: 0;
}

.lrtcss-11vn8f6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: rgba(29, 29, 29, 0.4);
  margin-bottom: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
}

.lrtcss-8d5juj {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: rgb(29, 29, 29);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  word-break: break-word;
  text-align: left;
}

.lrtcss-1ir9ty7 {
  margin-left: 4px;
  cursor: pointer;
  flex-shrink: 0;
}

.lrtcss-1j3ym55 + div {
  margin-left: 32px;
}

.lrtcss-131r450,
.lrtcss-131r450:hover {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: rgba(29, 29, 29, 0.4);
  margin-bottom: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}

.lrtcss-q2kz5y {
  font-size: 12px;
  margin-left: 2px;
  color: rgba(140, 140, 140, 0.6);
}

.lrtcss-xvls7q {
  color: rgb(248, 178, 0);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
}

.lrtcss-1oby5b8 {
  margin: 1px;

  fill: rgb(248, 178, 0);
  width: 14px;
  margin-right: 7px;
}

.lrtcss-105sj3w {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  word-break: break-word;
  text-align: left;
  color: rgb(248, 178, 0);
}

.lrtcss-105sj3w svg {
  margin-right: 10px;
}

.lrtcss-8d5juj {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: rgb(29, 29, 29);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  word-break: break-word;
  text-align: left;
}

.lrtcss-sstw6x {
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
  flex-shrink: 0;
}

.bg_trance {
  background: transparent;
  border: 1px solid rgba(29, 29, 29, 0.08);
}

.lrtcss-17ouo08 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.lrtcss-135twnz {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: rgba(29, 29, 29, 0.4);
  flex: 1 1 0%;
  margin-bottom: 0px;
}

.lrtcss-kernjd,
.lrtcss-kernjd:hover {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: rgba(29, 29, 29, 0.4);
  cursor: pointer;
  text-decoration: none;
}

.lrtcss-19hp5no {
  width: 1px;
  height: 12px;
  background-color: rgba(29, 29, 29, 0.16);
  margin: 0px 12px;
}

.proice h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: rgb(29, 29, 29);
  margin-right: 8px;
  margin-left: 4px;
}

button.priomary {
  background: #813ef2;
  text-transform: capitalize;
  color: #fff;
  padding: 5px 20px;
  border-radius: 50px;
}

button.priomary-outline {
  color: #813ef2;
  border: 1px solid #813ef2;
  padding: 5px 20px;
  border-radius: 50px;
  text-transform: capitalize;
}

.flexbutton {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.refframnl_card {
  padding: 41px;
  background: #fbfbfb;
  border-radius: 20px;
}

.content_card h4 {
  font-size: 20px;
  font-weight: 600;
}

.content_card p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.content_card a,
.content_card a:hover {
  color: #813ef2;
  text-decoration: none;
  margin-top: 12px;
  display: block;
}

.dots .dot {
  width: 12px;
  display: block;
  height: 4px;
  background: #9999994f;
  border-radius: 50px;
}

.dots .dot.active {
  width: 12px;
  display: block;
  height: 4px;
  background: #999999;
  border-radius: 50px;
}

.dots {
  display: flex;
  gap: 5px;
  margin-top: 9px;
}

.heading_section {
  margin: 0;
  font-weight: 500;
  color: #1d1d1d;
}

.heading_section {
  padding: 20px 0px;
}

.lrtcss-1urys0g {
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(29, 29, 29);
  padding: 24px 64px;
  font-weight: 600;
  font-size: 24px;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(29, 29, 29, 0.08);
}

.lrtcss-1wiku5 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px 20px;
  height: 40px;
  border-radius: 39px;
  font-weight: 600;
  font-size: 14px;
  background: rgba(248, 178, 0, 0.08);
  color: rgb(248, 178, 0);
}

.lrtcss-1wiku5 span {
  margin-left: 6px;
}

.ma-000 {
  margin-top: 17px;
}

.lrtcss-ac8m87 {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 40px 32px 0px;
}

.header_content h3 {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  color: rgb(29, 29, 29);
}

.header_content p {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
  line-height: 130%;
  color: rgba(29, 29, 29, 0.4);
}

.header_content {
  width: 100%;
  padding: 0px 32px;
  margin-bottom: 12px;
}

.lrtcss-14z62gk {
  width: 100%;
  padding: 0px 16px;
}

.lrtcss-1nkcrl4 {
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 26px 0px;
  border-bottom: 1px solid rgba(29, 29, 29, 0.04);
}

.lrtcss-1945s3i {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.lrtcss-xjkkcr {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  background: rgba(29, 29, 29, 0.04);
  border-radius: 50%;
  flex-shrink: 0;
}

.lrtcss-xjkkcr img {
  width: 36px;
  height: 36px;
}

.lrtcss-1c963u1 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: rgb(29, 29, 29);
}

.lrtcss-6kgahy {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: rgba(29, 29, 29, 0.6);
  margin-top: 2px;
}

.lrtcss-mivjb5 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-left: 56px;
  width: 450px;
  flex-shrink: 0;
}

.lrtcss-9h3gn9 {
  width: 200px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: rgb(29, 29, 29);
  margin-right: 56px;
}

.lrtcss-9h3gn9 svg {
  color: rgb(248, 178, 0);
}

.lrtcss-9h3gn9 span {
  margin-left: 5px;
}

.lrtcss-1o5xb0b {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.lrtcss-1d4kpr5 {
  display: inline-flex;
  flex-shrink: 0;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 24px;
  outline: none;
  cursor: pointer;
  width: auto;
  opacity: 1;
  pointer-events: auto;
  font-family: Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont,
    "Microsoft YaHei";
  font-weight: 700;
  height: 32px;
  font-size: 14px;
  padding: 0px 20px;
  color: rgb(29, 29, 29);
  background: transparent;
  border: 1px solid rgb(29, 29, 29);
}

.grow_1 {
  flex-grow: 1;
}

/* Market Table */
.table.market {
  margin-top: 20px;
  border: 1px solid;
}

th,
td {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}

.table_img1 img {
  width: 32px;
  height: max-content;
}

.table td {
  color: #000;
  vertical-align: -webkit-baseline-middle;
}

.table_img1 {
  display: flex;
}

.table_img1 span {
  color: #1d1d1d66;
  font-weight: 400;
  font-size: 14px;
}

.table_img1 .ox {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.table .table_img1 {
  gap: 6px;
}

.market-header {
  background: transparent;
}

.table.market th {
  padding: 17px;
}

.table.market td {
  color: #000;
  text-align: left;
  padding: 18px 14px;
}

.scroll_section {
  min-height: 100vh;
  padding: 120px 0px;
  padding-bottom: 0;
}

.headeing_banner h1 {
  text-align: center;
  font-weight: 600;
  font-size: 64px;
  margin-bottom: 10px;
  color: #212121;
  margin-bottom: 30px;
}

.headeing_banner p {
  font-size: 24px;
  text-align: center;
  color: #565656;
}

.qr-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  margin: 40px 0px;
}

.app_link {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.scroll_section {
  background-image: url("./Image/new_image_home/bg_bottom_gradiont.png");
  background-size: 100% 100%;
  background-position: bottom;
}

.rotate_image {
  width: 750px;
  height: 750px;
  /* background: rgb(0, 0, 0); */
  background-image: url("./Image/new_image_home/cicule_image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  /* margin: 0 auto; */
}

.section_cicule {
  position: relative;
  padding: 80px 0px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile_image img {
  width: 70%;
  margin: 0 auto;
  display: block;
}

.crypto_news {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  gap: 50px;
  padding: 12px 0px;
}

.crypto_news {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  gap: 50px;
  padding: 12px 0px;
}

.price_section small {
  font-size: 14px;
  font-weight: 500;
  color: #34cd89;
  font-size: 13px;
  padding: 4px 5px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #277c56;
}

.price_section span {
  font-size: 17px;
  font-weight: 400;
  color: #87909f;
  font-size: 16px;
}

.price_section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.crypto_news {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 50px;
  padding: 15px 0px;
  padding-bottom: 5px;
  padding-bottom: 9px;
}

.crypto_news h3 {
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  color: #fff;
  letter-spacing: 0.5px;
}

.marquee_section {
  border-top: none;
  border-bottom: none;
  padding: 5px 0px;
}

.marquee_section {
  background: linear-gradient(
    177deg,
    rgba(2, 0, 36, 0.4767156862745098) 0%,
    rgba(5, 6, 17, 1) 100%
  );
  margin-top: -7px;
  position: relative;
  z-index: 5;
  padding: 40px 0px;
}

.margee_flex {
  display: flex;
  gap: 35px;
  align-items: center;
}

.price_section small.down {
  color: #e53e3e;
  background: transparent;
  border: 1px solid #e53e3e;
}

.get_startr {
  padding: 10px 0px;
}

.get_startr button,
.get_startr button:hover {
  text-transform: capitalize;
  margin: 0 auto;
  display: block;
  padding: 11px 55px;
  font-size: 18px;
  font-weight: 500;
  background: #0b49f3;
  color: #fff;
  border-radius: 50px;
}

button.trade_button {
  text-transform: capitalize;
  color: #0b49f3;
  border: 1px solid #0b49f3;
  padding: 2px 16px;
  border-radius: 20px;
  margin-left: auto;
}

.card_table {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(20, 20, 43, 0.01),
    0 3px 6px rgba(51, 71, 255, 0.01), 0 4px 22px rgba(21, 19, 91, 0.04);
  background-color: #fff;
  padding: 20px;
}

.table_tabe ul li {
  display: flex;
  border: none;
}

.table_tabe ul {
  border: none;
}

.table_tabe ul li a {
  display: flex;
  padding: 10px 39px;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  color: #212121;
  text-decoration: none;
}

.table_tabe ul li a.active {
  color: #0b49f3;
}

.table_tabe ul li a.active::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  background: #0b49f3;
  bottom: 0;
  left: 0;
}

button.veiw_btn,
button.veiw_btn:hover {
  text-transform: capitalize;
  background: #0b49f3;
  color: #fff;
  padding: 9px 19px;
  font-size: 13px;
  border-radius: 40px;
}

img.new_icon {
  width: 80px;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}

.Steps_section {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.mar-top-minie {
  margin-top: -1000px;
}

.mar-top-minie-2 {
  margin-top: -500px;
}

.mar-top-minie-3 {
  margin-top: -700px;
}

.mar-top-minie-4 {
  margin-top: -1000px;
}

.content_center_mobile h3 {
  color: #1e2329;
  font-weight: 700;
  font-size: 40px;
}

.vh-100 {
  height: 100vh;
  text-align: center;
}

#target-section {
  /* height: 100vh; */
  padding: 50px 0px;
}

.image_scroll .img_1 img {
  width: 100% !important;
  /* height: 20% !important; */
  margin: 0 auto;
  display: block;
}

#target-section.active h1 {
  color: #fff;
  transition-duration: 0.5s ease;
}

.Steps_section .frame-1 {
  border: none;
  position: absolute;
  width: 160px;
  left: 30px;
}

.card_table_border {
  border: 1px solid #73737305;
}

.Steps_section .frame-2 {
  border: none;
  position: absolute;
  width: 160px;
  right: 0;
  bottom: 30px;
}

.padin_bottom_new {
  padding-bottom: 50px;
}

.secutiry_deture {
  /* height: 100vh; */
  overflow: hidden;
}

.section_card_with_image {
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* margin-bottom: 36px; */
  box-shadow: 0 1px 4px rgba(20, 20, 43, 0.01),
    0 3px 6px rgba(51, 71, 255, 0.01), 0 4px 22px rgba(21, 19, 91, 0.04);
  margin-bottom: 33px;
}

.section_card_with_image img {
  width: 77px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.section_card_with_image h4 {
  font-weight: 700;
  font-size: 21px;
  color: #1e2329;
  margin-bottom: 10px;
}

.section_card_with_image p {
  color: #474d57;
  font-weight: 400;
}

.bg_img_nrw {
  background-image: url("./Image/new_image_home/X_Mockup-2.png");
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right;
  background-position-y: 42px;
  background-position-x: 490px;
  min-height: 290px;
  display: flex;
  align-items: center;
}

.content_feturee h3 {
  text-align: center;
  color: #1e2329;
  font-weight: 700;
  font-size: 40px;
  margin: 0;
  margin-bottom: 20px;
}

.section_contner {
  padding: 60px 0px;
  background: #eff6fa;
}

.width_innwe {
  width: 60%;
}

.min-hew {
  min-height: 290px;
}

.headeing_banner {
  position: relative;
}

.crypto_icon {
  position: absolute;
}

.crypto_icon.one_1 {
  position: absolute;
  top: -70px;
  left: -10px;
}

.crypto_icon.two_2 {
  right: -50px;
  top: 190px;
}

.blog_card:hover {
  text-decoration: none;
}

.blog_card {
  border: 1px solid #eff0f6;
  background-color: white;
  border-radius: 24px;
  padding: 32px 32px 50px;
  transition: box-shadow 0.3s, transform 0.3s;
  overflow: hidden;
  display: block;
  text-decoration: none;
  box-shadow: 0 1px 4px rgba(20, 20, 43, 0.01),
    0 3px 6px rgba(51, 71, 255, 0.01), 0 4px 22px rgba(21, 19, 91, 0.04);
}

.image_wraper {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.card-post-content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.content-top.card-post {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
  color: #474d57;
  font-weight: 600;
  font-size: 14px;
}

.card-post-about-divider {
  width: 20px;
  min-height: 1px;
  background-color: #dcddeb;
  margin-left: 16px;
  margin-right: 16px;
}

.blog_card h4 {
  color: #1e2329;
  font-weight: 600;
}

.blog_card button {
  width: fit-content;
  padding: 0;
  text-transform: capitalize;
  display: flex;
  gap: 6px;
  color: #0b49f3;
}

.image_wraper img {
  width: 100%;
}

.bgwhite {
  background-color: #fff;
}

.blog_card:hover {
  transform: translate3d(0, 6px, 0.01px);
  box-shadow: 0 1px 2px rgba(20, 20, 43, 0.01),
    0 2px 2px rgba(51, 71, 255, 0.01), 0 2px 2px rgba(21, 19, 91, 0.04);
}

.bg_gradiont_section {
  background-image: linear-gradient(
    to bottom,
    #eff2f6 30%,
    rgba(255, 255, 255, 0)
  );
}

.options {
  background-image: linear-gradient(
    to bottom,
    #eff2f6 30%,
    rgba(255, 255, 255, 0)
  );
  padding: 30px 20px;
  border-radius: 30px;
}

.options a,
.options a:hover {
  text-align: center;
  width: fit-content;
  margin: auto;
  display: block;
  background: #0b49f3;
  padding: 9px 15px;
  font-weight: 500;
  color: #fff;
  border-radius: 20px;
  margin-top: 20px;
  text-decoration: none;
}

.toggle-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-slot {
  position: relative;
  height: 30px;
  width: 65px;
  border: 1px solid #a6b0cc;
  border-radius: 10em;
  background-color: white;
  box-shadow: 0px 10px 25px #e4e7ec;
  transition: background-color 250ms;
  margin-left: 7px;
}

.toggle-checkbox:checked ~ .toggle-slot {
  background-color: #374151;
}

.toggle-button {
  transform: translate(42px, 8px);
  position: absolute;
  height: 13px;
  width: 14px;
  border-radius: 50%;
  background-color: #ffeccf;
  box-shadow: inset 0px 0px 0px 0.75em #ffbb52;
  transition: background-color 250ms, border-color 250ms,
    transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .toggle-button {
  background-color: #485367;
  box-shadow: inset 0px 0px 0px 0.75em white;
  transform: translate(9px, 8px);
}

.sun-icon {
  position: absolute;
  height: 16px;
  width: 16px;
  color: #ffbb52;
}

.sun-icon-wrapper {
  position: absolute;
  height: 16px;
  width: 16px;
  opacity: 1;
  transform: translate(9px, 7px) rotate(15deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
  opacity: 0;
  transform: translate(42px, 8px) rotate(0deg);
}

.moon-icon {
  position: absolute;
  height: 13px;
  width: 13px;
  color: white;
}

.moon-icon-wrapper {
  position: absolute;
  height: 13px;
  width: 13px;
  opacity: 0;
  transform: translate(42px, 8px) rotate(0deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2.5, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
  opacity: 1;
  transform: translate(40px, 8px) rotate(-15deg);
}

.link_section {
  margin-left: 30px;
}

/* Wallet */

.wallet_tabs {
  padding-top: 80px;
}

.nav-item.earn {
  margin-left: 5px;
}

.nav-item.spot {
  background: #1140ff !important;
  border: 1px solid #e0e2ee !important;
  border-radius: 6px !important;
  color: #fff !important;
}

.nav-item.spot button {
  background: #0c2af4 !important;
}

.wallet_tabs button {
  padding: 8px 30px;
}

.balance_amount {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0px 40px;
}

.coin_img {
  width: 35px;
}

.wallet_balance {
  margin-top: 70px;
  padding: 35px 20px;
  padding-bottom: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 54px #e2f0ff;
}

.balance_amount h4 {
  color: #7c7e7e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.balance_amount h3 {
  font-style: normal;
  font-weight: 500;
  color: #2a2a2a;
  font-size: 25px;
}

.balance_amount p span {
  color: #111 !important;
}

.amount h4 {
  color: #111 !important;
}

.amount p {
  color: #111 !important;
}

.fiat-ac h4 {
  color: #111;
  font-size: 13px;
}

.crypto_ac h4 {
  color: #111;
  font-size: 13px;
}

.nav-pills .nav-link.spot-btn.active {
  color: #fff;
  background-color: #1140ff !important;
  border: none;
}

.nav-link.earn-btn {
  background-color: initial !important;
  border: 1px solid #e0e2ee !important;
  border-radius: 6px;
  color: #111 !important;
}

.textsuccess {
  font-size: 25px;
  font-weight: 600;
  color: #00c795;
}

.count {
  display: flex;
  flex-direction: column;
}

.nav-link.view3 {
  color: #813ef2;
  border: 1px solid #813ef2;
  background-color: transparent;
}

.dark .nav-link.view3 {
  background-color: initial;
  border: 1.5px solid #5148d8;
  color: #fff;
  border-radius: 6px;
  min-height: 40px;
  min-width: 100px;
  margin-bottom: 10px;
}

.balance_tab {
  text-align: right;
}

.zero_balance {
  display: flex;
  justify-content: center;
}

.input_border {
  display: flex;
  border: 1px solid #2e323d78;
  padding: 5px 15px;
  gap: 5px;
  border-radius: 10px;
  margin-left: 10px;
}

.wallet-table {
  overflow-x: auto;
  margin-top: 50px;
}

.balance_input {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-right: 20px;
}

.balance_input input {
  background: transparent;
  border: none;
  color: #fff !important;
}

/* P2P */

.p2p_header {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: rgb(29, 29, 29);
}

.p2p_header h1 {
  color: rgb(29, 29, 29);
  font-size: 56px;
  font-weight: 700;
}

.p2p_header h1 span {
  color: rgb(11 73 243);
  padding: 0px 10px;
}

.p2p_tabs .nav-link.active {
  position: relative;
  color: #495057;
  background-color: transparent;
  border-color: transparent;
}

.p2p_tabs .nav-link {
  padding-left: 0;
  padding-right: 25px;
}

.history .p2p_tabs .nav-link {
  padding: 12px 0px;
}

.history .p2p_tabs .nav-tabs {
  border-bottom: none;
  gap: 25px;
}

.dark .no_records_text {
  text-align: center;
  color: #fff;
  font-weight: 400;
}

.dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdown1 button {
  padding: 0px 12px;
  font-weight: 400;
  background: transparent;
  color: #fff;
  border: none;
}

.backicon,
.backicon:hover {
  color: #999;
  font-size: 33px;
  /* margin-bottom: -50px; */
}

.headeing_with_back {
  display: flex;
  align-items: center;
  gap: 10px;
}

.warning_alter {
  background: #cccccc14;
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.warning_alter p {
  margin: 0;
  color: #fff;
  font-weight: 200;
  font-size: 15px;
  margin-bottom: 7px;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 340px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 340px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: unset;
  margin-top: 0px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 60px;
  border-radius: 20px;
  max-height: calc(-100px + 100vh);
  overflow: auto;
  margin-top: 5px;
}

.dropdown-content1 a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.dropdown-content1 a img {
  width: 50px;
}

.dropdown-content1 a span {
  color: #999;
  font-weight: 300;
}

.dropdown-content1 a p {
  margin: 0;
  color: #000;
  font-weight: 800;
}

/* .dropdown1:hover .dropdown-content1 {
  display: block;
}
/* .dropdown1:target .dropdown-content1 {
  display: block;
} */

/* .dropdown1:visited .dropdown-content1 {
  display: block;
} */

.p2p_tabs .nav-tabs .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 1px;
  background: rgb(29, 29, 29);
  border-radius: 6px;
  height: 6px;
  width: 32px;
}

.p2p_tabs .nav-item a {
  color: rgba(29, 29, 29, 0.4);
  font-size: 18px;
  font-weight: 500;
}

.p2p_tabs .nav-item a:hover {
  border: none !important;
}

.p2p_dropdown .dropdown.amount {
  display: flex;
  gap: 15px;
}

.modal-content.p2p .modal-body {
  padding-bottom: 55px;
}

.wallet_tab ul li {
  display: flex;
  align-items: center;
}

.p2p_tabs .nav-tabs {
  border-bottom: none;
}

.p2p_table .table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.p2p_table {
  overflow: hidden;
  overflow-x: auto;
}

.p2p_row_img img {
  width: 34px;
}

.p2p_row_button button {
  padding: 9px 45px;
  border-radius: 20px;
  background: #01bc8d;
  border: none;
  color: #fff;
  font-weight: 600;
}

.row_profile {
  align-items: center;
  border: 1px solid rgba(29, 29, 29, 0.2);
  border-radius: 100%;
  color: #1d1d1d;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-top: 2px;
  width: 40px;
}

.p2p_row_trades {
  display: flex;
  gap: 10px;
}

.p2p_row_price h3 {
  color: #00142a;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  gap: 5px;
}

.p2p_row_price h3 span {
  color: rgba(1, 8, 30, 0.38);
  font-size: 14px;
}

.profile_content h3 {
  color: #00142a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 2px;
}

.profile_content p {
  color: #9b9ea4;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.p2p_order_limit h3 {
  font-size: 14px;
  display: flex;
  gap: 8px;
  margin-top: 0px;
}

.p2p_order_limit h3 span {
  color: rgba(29, 29, 29, 0.4);
  font-size: 12px;
  font-weight: 400;
}

.trade_fees span {
  background: rgba(36, 174, 143, 0.08);
  border-radius: 2px;
  color: #24ae8f;
  font-size: 12px;
  padding: 1px 6px;
}

.nav-item.buy .buy-btn {
  height: 34px;
  padding: 0px 16px;
  margin-left: 12px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 500;
  border-radius: 100px;
  color: rgba(29, 29, 29, 0.4);
  background: rgba(29, 29, 29, 0.02);
  border: 1px solid rgba(29, 29, 29, 0.12);
}

.dropdown.amount button {
  background: transparent;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  color: rgb(29, 29, 29);
  margin-left: 50px;
}

.p2p_row_button_sell button {
  padding: 8px 40px;
  border-radius: 20px;
  background: #f65454;
  border: none;
  color: #fff;
  font-weight: 600;
}

.lrtcss-sbd8h.eagejp24.market {
  overflow: auto;
}

.lrtcss-sbd8h.eagejp24.p2p_bg {
  background: transparent;
  position: relative;
}

/* .p2p_header::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 28%;
  width: 50%;
  height: 15%;
  background-image: url("./Image/Table_image/p2p_background.png");
  background-repeat: no-repeat;
  opacity: 0.4;
} */

.ri-bookmark-3-fill {
  color: #d6f580;
}

.ri-medal-line {
  color: gold;
}

.ui.pagination.menu {
  float: right;
}

.p2p_pagination {
  display: flex;
  justify-content: center;
}

.p2p_pagination a {
  font-size: 12px;
  min-width: 1em;
}

.p2p_dropdown {
  display: flex;
  gap: 50px;
}

/* P2P */

.p2p_header {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: rgb(29, 29, 29);
}

.p2p_header h1 {
  color: rgb(29, 29, 29);
  font-size: 56px;
  font-weight: 700;
}

.p2p_header h1 span {
  color: rgb(88 65 216);
  padding: 0px 10px;
}

.wallet_tab ul.nav {
  gap: 15px;
}

.p2p_tabs .nav-link.active {
  position: relative;
  color: #495057;
  background-color: transparent;
  border-color: transparent;
}

.p2p_tabs .nav-tabs .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 1px;
  background: rgb(29, 29, 29);
  border-radius: 6px;
  height: 6px;
  width: 32px;
  bottom: -2px;
  left: 30%;
  transform: translate(-50%, -50%);
}

.p2p_tabs .nav-item a {
  color: rgba(29, 29, 29, 0.4);
  font-size: 18 px;
  font-weight: 500;
}

.p2p_tabs .nav-tabs {
  border-bottom: none;
}

.p2p_table .table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.p2p_table {
  overflow: hidden;
  overflow-x: auto;
}

.p2p_row_img img {
  width: 34px;
}

.p2p_row_button button {
  padding: 8px 30px;
  border-radius: 20px;
  background: #01bc8d;
  border: none;
  color: #fff;
  font-weight: 500;
}

.row_profile {
  align-items: center;
  border: 1px solid rgba(29, 29, 29, 0.2);
  border-radius: 100%;
  color: #1d1d1d;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-top: 2px;
  width: 40px;
}

.p2p_row_trades {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.p2p_row_price h3 {
  color: #00142a;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  gap: 5px;
}

.p2p_row_price h3 span {
  color: rgba(1, 8, 30, 0.38);
  font-size: 14px;
}

.profile_content h3 {
  color: #00142a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.profile_content p {
  color: #9b9ea4;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.p2p_order_limit h3 {
  font-size: 14px;
  display: flex;
  gap: 8px;
  margin-bottom: 5px;
}

.p2p_order_limit h3 span {
  color: #87909f;
  font-size: 12px;
  font-weight: 400;
}

.trade_fees span {
  background: rgba(36, 174, 143, 0.08);
  border-radius: 2px;
  color: #24ae8f;
  font-size: 12px;
  padding: 1px 6px;
}

element.style {
  display: flex;
  gap: 14px;
}

.nav-item.buy .buy-btn {
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  margin-left: 0px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 500;
  border-radius: 100px;
  color: rgba(29, 29, 29, 0.4);
  background: rgba(29, 29, 29, 0.02);
  border: 1px solid rgba(29, 29, 29, 0.12);
}

.nav-item.buy .buy-btn:hover {
  background: #813ef2;
  color: #fff;
}

.dark .nav-item.sell .buy-btn {
  color: #fff;
  border: 1px solid #813ef2;
  border-radius: 100px;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  font-size: 14px;
}

.dark .nav-item.sell .buy-btn:hover {
  background: #813ef2;
  color: #fff;
}

.dropdown.amount button {
  background: transparent;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  color: rgb(29, 29, 29);
  margin-left: 50px;
}

.p2p_row_button_sell button {
  padding: 8px 30px;
  border-radius: 20px;
  background: #f65454;
  border: none;
  color: #fff;
  font-weight: 500;
}

.trade_fees,
.p2p_row_button_sell,
.p2p_row_button {
  text-align: right;
}

.lrtcss-sbd8h.eagejp24.market {
  overflow: auto;
}

.lrtcss-sbd8h.eagejp24.p2p_bg {
  background: transparent;
  position: relative;
}

/* .p2p_header::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 28%;
  width: 50%;
  height: 15%;
  background-image: url("./Image/Table_image/p2p_background.png");
  background-repeat: no-repeat;
  opacity: 0.4;
} */

.ri-bookmark-3-fill {
  color: #d6f580;
}

.ri-medal-line {
  color: gold;
}

.ui.pagination.menu {
  float: right;
}

.p2p_pagination {
  display: flex;
  justify-content: center;
}

.p2p_pagination a {
  font-size: 12px;
  min-width: 1em;
}

.p2p_dropdown {
  display: flex;
  gap: 50px;
}

/* Trading Page */

.trading_heading {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}

.heading_button {
  display: flex;
  gap: 20px;
}

.heading_button .active {
  background: #1d1d1d;
  color: #fff;
}

.heading_button button {
  flex-shrink: 0;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 24px;
  outline: none;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  padding: 0px 24px;
  color: rgb(29, 29, 29);
  background: transparent;
  border: 1px solid rgb(29, 29, 29);
}

.trading_assets {
  justify-content: space-between;
  margin-top: 60px;
}

.total_assets span {
  color: rgba(29, 29, 29, 0.4);
  font-size: 14px;
}

.ri-eye-line {
  margin-left: 1px;
}

.total_assets h3 {
  margin-right: 4px;
  color: rgb(29, 29, 29);
  font-weight: 700;
  font-size: 36px;
}

.act_detail {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  width: auto;
  font-weight: 700;
  height: 40px;
  font-size: 14px;
  padding: 0px 24px;
  color: rgb(29, 29, 29);
  background: rgba(29, 29, 29, 0.04);
}

.search_bar {
  position: relative;
  background: transparent;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 1;
  cursor: text;
  padding: 0px 16px 0px 12px;
}

.x-content {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 25px;
}

.trade_token_information {
  margin-top: 40px;
  margin-bottom: 45px;
  margin-left: 9px;
  padding: 16px;
  display: flex;
  border-radius: 8px;
  font-weight: 500;
  background: rgba(29, 29, 29, 0.02);
  align-items: flex-start;
}

.green-text {
  color: #24ae8f;
  font-size: 16px;
}

.hide_btn {
  color: rgba(29, 29, 29, 0.6);
  font-weight: 400;
  font-size: 16px;
}

.trading_accout_page .lrtcss-sbd8h {
  background: #fff;
}

.table_logo img {
  width: 40px;
}

.table_content {
  display: flex;
  gap: 5px;
}

.table_text h3 {
  font-size: 16px;
  font-weight: 500;
}

.table_text p {
  font-size: 14px;
  margin-top: -12px;
  color: rgba(29, 29, 29, 0.3);
}

.text_color {
  color: rgb(1, 188, 141);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.action {
  display: flex;
  justify-content: flex-end;
}

.modal_available {
  display: flex;
  gap: 20px;
}

.modal-content.p2p {
  background: #fff;
}

.payment_methods img {
  width: 40px;
}

.modal_available h3 {
  font-size: 16px;
  color: rgba(29, 29, 29, 0.4);
}

.modal_available p {
  font-size: 16px;
  font-weight: 500;
}

.buy-amt {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ececec;
  padding: 10px 20px;
  border: 2px solid #5bcfb0;
  border-radius: 5px;
}

.usd-amt {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ececec;
  padding: 10px 20px;
  border-radius: 5px;
}

.place-order {
  margin-top: 20px;
}

.place-order button {
  border: none;
  background: #5bcfb0;
  padding: 15px 130px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.modal-content.p2p .modal-header .close {
  color: #000;
  font-size: 28px;
  margin-right: 5px;
}

.modal-content.p2p .modal-header {
  border: none;
}

.modal-content.p2p .profile_content p {
  /* line-height: 0px; */
  margin-bottom: 25px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.bg_trans_non {
  background: transparent;
  border: none;
  width: 100%;
}

.p2p_modal_contant h3 {
  color: rgba(29, 29, 29, 0.4);
  font-size: 16px;
}

.p2p_modal_contant p {
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
}

.modal_text {
  padding: 0 60px;
}

.buy_order {
  padding: 0px 60px;
}

.modal_available.sell {
  display: inline;
}

.star {
  display: flex;
  gap: 20px;
}

.star-1 {
  background: #ececec;
  padding: 10px 15px;
  font-size: 24px;
  margin-bottom: 18px;
}

.buy-amt.sell {
  border: 2px solid red;
}

/* P2P Works */

.p2p_works {
  margin-top: 100px;
  text-align: center;
}

.p2p_works_title {
  margin: 0px 0px 24px;
  color: rgb(29, 29, 29);
  font-weight: 700;
  font-size: 36px;
}

.p2p_buy_btn .nav-link.active {
  padding: 8px 16px;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  color: rgb(255, 255, 255);
  background: rgb(29, 29, 29);
  border: 1px solid transparent;
}

.p2p_buy_btn .nav-link {
  padding: 8px 16px;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  color: rgba(29, 29, 29, 0.4);
  background: rgba(29, 29, 29, 0.02);
  border: 1px solid rgba(29, 29, 29, 0.12);
  justify-content: center;
}

.nav-tabs {
  border-bottom: none !important;
}

.nav.nav-tabs.p2p_work {
  justify-content: center;
}

.p2p_buy_btn {
  justify-content: center;
  align-items: center;
}

.accordion > .card > .card-header {
  padding: 32px 40px;
  margin-top: 25px;
  border-radius: 16px;
}

.p2p_sell_btn .nav-link {
  padding: 8px 16px;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  color: rgba(29, 29, 29, 0.4);
  background: rgba(29, 29, 29, 0.02);
  border: 1px solid rgba(29, 29, 29, 0.12);
}

.p2p_sell_btn .nav-link.active {
  padding: 8px 16px;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  color: rgb(255, 255, 255);
  background: rgb(29, 29, 29);
  border: 1px solid transparent;
}

.line_icon {
  flex: 1 1 0%;
  height: 1px;
  background: rgba(29, 29, 29, 0.08);
  opacity: 1;
}

.line_container {
  position: relative;
  left: -32px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  transform: translateX(50%);
  gap: 40px;
}

.p2p_works_steps {
  justify-content: space-evenly;
  margin-top: 30px;
  gap: 28px;
}

.kucoin_title {
  color: rgb(29, 29, 29);
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 64px;
  margin-top: 100px;
}

.about-kucoin {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  gap: 30px;
}

.about-kucoin2 {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 50px;
  margin-top: 30px;
}

.tutorial-heading {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.tutorial-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 130%;
  color: rgb(29, 29, 29);
  flex: 1 1 0%;
  margin: 0px;
}

.tutorial-guides {
  display: flex;
  align-items: center;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  width: auto;
  opacity: 1;
  font-weight: 700;
  font-size: 14px;
  padding: 0px 20px;
  color: rgb(29, 29, 29);
  background: rgba(29, 29, 29, 0.04);
}

.tutorial-steps {
  margin-top: 42px;
  display: flex;
  gap: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
input,
button,
a,
div {
  font-family: "Roboto", sans-serif !important;
}

.view-green {
  color: #00c795;
  text-align: center;
  margin-top: 25px;
}

.tutorial-step1 {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  padding: 28px 24px;
  background: rgba(29, 29, 29, 0.02);
}

.accordion .card-header:after {
  font-family: "FontAwesome";
  content: "\f068";
  float: right;
}

.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067";
}

.card-header.collapsed.p2p {
  position: relative;
  padding: 32px 40px;
  background: rgb(29 29 2 / 2%);
  margin-top: 25px;
}

.trading_accordion_section .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  margin-top: 0px;
  padding: 24px 20px 20px;
  color: rgba(29, 29, 29, 0.4);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  border-top: 1px solid rgba(29, 29, 29, 0.04);
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 16px;
}

.card.p2p {
  border: none;
}

.card-title.p2p {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333;
}

.trading_accordion h1 {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 36px;
  margin-top: 100px;
}

.p2p_work_section {
  background: rgba(1, 188, 141, 0.04);
  padding: 2px 0px;
  padding-bottom: 60px;
}

.tutorial-steps h3 {
  color: rgb(29, 29, 29);
  font-weight: 500;
  font-size: 20px;
}

.tutorial-steps p {
  margin: 16px 0px;
  color: rgba(29, 29, 29, 0.4);
  font-weight: 400;
  font-size: 14px;
}

.tutorial-step1.padding {
  padding-bottom: 54px;
}

.about-kucoin h3 {
  color: rgb(29, 29, 29);
  font-weight: 500;
  font-size: 20px;
}

.about-kucoin p {
  color: rgb(29, 29, 29);
  font-weight: 500;
  font-size: 20px;
}

.about-kucoin p {
  margin: 16px 0px;
  color: rgba(29, 29, 29, 0.4);
  font-weight: 400;
  font-size: 14px;
}

.about-kucoin2 p {
  margin: 16px 0px;
  color: rgba(29, 29, 29, 0.4);
  font-weight: 400;
  font-size: 14px;
}

.work_step1 h3 {
  color: rgb(29, 29, 29);
  font-weight: 500;
  font-size: 20px;
}

.work_step1 p {
  margin: 16px 0px;
  color: rgba(29, 29, 29, 0.4);
  font-weight: 400;
  font-size: 14px;
}

.p2p_header p {
  color: rgba(29, 29, 29, 0.6);
  font-weight: 400;
  font-size: 16px;
}

.view-green {
  color: #813ef2;
  cursor: pointer;
}

/* P2p Chat page */

.place_order_header {
  margin-top: 80px;
  padding-right: 0px;
}

.lrtcss-1p2kb7b.e161ieae0.chat {
  border-bottom: none;
}

.p2p_chat_section {
  margin-top: 80px;
}

.p2p_chat {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 10px;
}

.order-text {
  color: #979aa2;
  font-weight: 400;
  font-size: 14px;
  background: #fafafc;
  padding: 12px;
  border-radius: 6px;
}

.text-buy {
  color: #53b990;
  font-size: 20px;
  font-weight: 500;
}

.text-usdt {
  font-size: 22px;
  font-weight: 600;
}

.p2p_buy_box img {
  width: 28px;
}

.msg-box {
  background: #fff;
  height: 400px;
  margin-top: 30px;
  padding: 20px;
  overflow: hidden;
  overflow-y: scroll;
}

.sender p {
  width: 90%;
  background: #f2f9f6;
  padding: 14px;
  border: 1px solid #53b990;
  border-radius: 6px;
}

.receiver p {
  text-align: right;
  margin-top: 25px;
  padding: 14px;
  border-radius: 6px;
  margin-left: 35px;
  background: #f5f6f6;
  border: 1px solid #bcbcbc;
}

input:focus-visible {
  outline: none;
}

.ri-shield-star-fill {
  color: #53b990;
}

.usd-bold {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  gap: 10px;
}

.ri-file-copy-line {
  font-size: 14px;
  color: rgba(29, 29, 29, 0.4);
}

.total.amt-usd {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 12px;
}

.total-text.amt {
  border-bottom: 1px #fafafb;
}

.p2p_chat_section {
  background: #fafafb;
  height: 600px;
  padding: 2px;
  box-shadow: 2px 2px 2px 2px #fafafb;
}

.buy_box_logo {
  margin-top: 30px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.total {
  display: flex;
  justify-content: space-between;
}

.payment-method {
  display: flex;
  justify-content: space-between;
}

.cancel {
  color: #89cfb2;
}

.pay-btn button {
  border: none;
  background: #53b990;
  padding: 15px 130px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.volcano {
  margin-bottom: 0px;
  margin-top: 20px !important;
}

.msg-text-box {
  border: none;
  background: #fff;
  box-shadow: 1px 1px 1px 3px #fafafa;
  height: 107px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0px 12px;
}

.msg-text-box input {
  border: none;
  font-size: 16px;
  background: #fafafb;
  margin: 0;
  padding: 20px 55px;
  border-radius: 6px;
  flex-grow: 1;
}

.place_order_content h1 {
  font-size: 36px;
}

.ri-image-2-fill {
  font-size: 30px;
  color: #bfbfcb;
}

.place_order_content p {
  color: #9b9ea4;
  font-weight: 400;
  font-size: 14px;
}

.place_order_content p span {
  color: #89cfb2;
  background: #fafafc;
  padding: 5px;
  font-weight: 500;
}

.chat_profile {
  align-items: center;
  border: 1px solid rgba(29, 29, 29, 0.2);
  border-radius: 100%;
  color: #1d1d1d;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-top: 2px;
  width: 40px;
}

.total-text {
  color: rgba(29, 29, 29, 0.4);
  font-weight: 500;
}

.sub-text {
  color: #545b66;
  font-weight: 500;
  display: flex;
  gap: 8px;
}

.card-header.collapsed.p2p.chat {
  position: relative;
  padding: 32px 40px;
  border-radius: 16px;
  background: none;
  margin-top: 25px;
}

/* COnvert Page Style */

.convert-image {
  width: 100%;
  height: auto;
}

.convert-section {
  background-color: rgb(247, 248, 251);
}

.convert-content {
  display: flex;
  justify-content: space-between;
}

.contvert-text {
  font-size: 28px;
  font-weight: 500;
  color: rgb(0, 13, 29);
  margin-bottom: 0px;
}

.history-icon {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: rgba(0, 13, 29, 0.6);
  gap: 6px;
}

.convert-form {
  justify-content: center;
  margin-top: -220px;
}

.swap-convert {
  padding: 40px 32px;
  background: #fff !important;
  border-radius: 20px;
}

.available-balance {
  color: rgba(0, 13, 29, 0.6);
  height: 32px;
  background: rgba(0, 13, 29, 0.04);
  border-radius: 22px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
}

.pay_balance {
  display: flex;
  justify-content: space-between;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.input input {
  color: rgb(0, 13, 29);
  font-weight: 500;
  font-size: 20px;
  width: 100%;
  background: transparent;
  border: none;
  opacity: 0.5;
}

.textright {
  text-align: right !important;
}

.max-dropdown {
  display: flex;
  align-items: center;
}

.max-text {
  align-items: center;
  color: rgb(131 109 255);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  margin-right: 10px;
}

.pay-currency {
  padding: 16px 24px;
  background: rgba(0, 13, 29, 0.02);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.swap-icon {
  display: flex;
  justify-content: center;
  /* margin-top: 30px; */
  font-size: 35px;
}

.price-icon {
  display: flex;
  justify-content: space-between;
}

.confirm-btn button {
  border: none;
  background: #5bcfb0;
  padding: 15px 130px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.without_data button {
  cursor: no-drop;
  opacity: 0.5;
}

.convert-form {
  margin-bottom: 50px;
  background: #f3f6f7;
  padding-bottom: 50px;
}

.ui.dropdown > .text > .image,
.ui.dropdown > .text > img {
  margin-right: 6px;
}

.pay {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 13, 29);
  margin-top: 10px;
}

.get-text {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 13, 29);
  margin-bottom: 5px;
}

.price-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 13, 29, 0.4);
}

.usdt-btc {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 13, 29, 0.4);
}

.convert_section {
  background: #f3f6f7;
}

.trading_accordion_section {
  padding-bottom: 60px;
}

.card.swap {
  display: flex;
  background: transparent;
  border-radius: 0.25rem;
  border: none;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}

.accordion > .card > .card-header.swap {
  background: transparent;
  padding: 14px 12px;
  border-radius: 0px;
}

.card-title.p2p.swap {
  font-size: 1.125rem;
}

.card-body.swap {
  color: rgba(0, 13, 29, 0.6);
  font-weight: 400;
  font-size: 0.875rem;
  border-top: 1px solid rgba(29, 29, 29, 0.04);
  background-color: transparent;
  border-radius: 16px;
}

.faq-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.faq-title {
  color: rgb(0, 13, 29);
  font-size: 36px;
  font-weight: 500;
}

.faq-text {
  color: #5841d8;
  font-size: 14px;
  font-weight: 500;
}

.accordion .card-header.collapsed.swap:after {
  content: "\f107";
  color: rgb(115, 126, 141);
}

.accordion .card-header.swap:after {
  content: "\f106";
  color: #24ae8f;
}

.input {
  display: flex;
  flex-grow: 1;
}

.table_overflow {
  display: block;
}

.card_table.table_tabe {
  overflow: hidden;
  overflow-x: auto;
}

.home_id {
  transform: none;
}

.table_responsive {
  overflow: hidden;
  overflow-x: auto;
}

.color_text_over {
  border: 1px solid #5841d8;
  padding: 2px 10px;
  border-radius: 5px;
  margin-left: 6px;
  font-size: 11px;
  color: #5841d8;
}

.dark .fetures_account .table.market td.colorpurpule {
  text-align: center;
  color: #5841d8;
}

.dark .fetures_account .table.market td {
  color: #fff;
  font-weight: 400;
}

/* dark mode */
body.dark {
  background: #0d0e0f;
}

.deposit_history {
  overflow: hidden;
  overflow-x: auto;
}

.heading_h2 {
  font-size: 22px;
}

.table_tabe ul li a {
  padding: 8px 19px;
  font-size: 13px;
}

.flexbutton button {
  min-width: 58px;
  font-size: 12px;
  padding: 8px 31px;
}

.flexbutton {
  gap: 10px;
  margin-left: -29px;
}

.Dashcard.bg_trance .lrtcss-17ouo08 {
  flex-direction: column;
}

.banner_V > div {
  width: 100% !important;
  height: 100% !important;
}

.dark header {
  background: #0a0b14b8 !important;
  backdrop-filter: blur(10px);
}

.dark .nav_link_section a {
  color: #fff;
}

.dark .Butn_header,
.dark .Butn_header:hover {
  background: #5841d8 !important;
  color: #fff !important;
}

.dark .toggle-slot {
  height: 34px;
  border: 1px solid #4d2892;
  background-color: #0b0c15;
  box-shadow: 0px 10px 25px #e4e7ec00;
  padding-top: 2px;
  margin-left: 11px;
}

.dark .toggle-button {
  background-color: #5841d8;
  box-shadow: inset 0px 0px 0px 0.75em #5841d8;
}

.dark .sun-icon {
  color: #5841d8;
}

.banner_v_section {
  position: relative;
  height: 600px;
  background-image: url("./Image/Demo-bg.png");
  background-position: cover;
}

.banner_v_section .postion_absalue {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.content_section h1 {
  font-weight: 500;
  font-size: 50px;
  color: #fff;
  letter-spacing: 0.4px;
  margin-bottom: 20px;
}

.content_section p {
  font-size: 18px;
  color: #cfcfcf;
  font-weight: 400;
}

.content_section button {
  text-transform: capitalize;
  background: #5841d8 !important;
  padding: 7px 25px;
  font-size: 16px;
  color: #fff !important;
  font-weight: 400;
  border-radius: 24px !important;
  margin-top: 8px;
}

.dark .margwwww {
  padding: 5px 8px;
  background: #11142199;
  border: 1px solid #222429;
  border-radius: 9px;
}

.dark .section_commen {
  background: #060612;
}

.dark .heading_h2 {
  color: #fff;
  text-transform: capitalize;
}

.dark .card_table {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .table thead th {
  color: #87909f;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  /* padding-right: 15px; */
}

.dark .table_tabe ul li a {
  font-size: 15px;
  color: #6e7583;
  font-weight: 500;
}

.dark .table td,
.dark .table th {
  /* border: 1px solid #dee2e60f !important; */
  color: #fff;
  border-top: 1px solid #dee2e60f !important;
}

.dark .dark .table_img1 {
  padding-bottom: 34px !important;
}

.dark .table td {
  text-align: center;
}

.dark .table_tabe ul li a.active::after {
  background: #813ef2;
  border-radius: 16px;
}

.dark .table_tabe ul li a.active {
  color: #fff;
}

.dark .coin_section span {
  color: #fff;
  font-weight: 400;
  padding: 0px 7px;
  text-align: left !important;
}

.dark .coin_section small {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: -2px;
}

.dark td > p {
  color: #fff;
  font-weight: 400;
  margin: 0;
  font-size: 14px;
}

.dark button.trade_button {
  color: #fff;
  border: 1px solid #813ef2;
}

.dark button.trade_button.active,
.dark button.trade_button:active {
  background: #813ef2;
}

.dark .heading_p {
  color: #87909f;
}

.dark .heading_sub h3 {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
}

.dark .heading_sub p {
  color: #87909f;
  text-align: center;
  font-weight: 400;
}

.dark .section_contner {
  padding: 60px 0px;
  background: #060612;
  padding-top: 0;
}

.dark .content_feturee h3 {
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 25px;
  letter-spacing: 0.5px;
}

.dark .section_card_with_image {
  margin-bottom: 33px;
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .section_card_with_image h4 {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  margin-bottom: 8px;
}

.dark .section_card_with_image p {
  color: #87909f;
  font-weight: 400;
}

.dark .secutiry_deture {
  background: #060612;
}

.dark .content_center_mobile h3 {
  color: #ffff;
  font-weight: 700;
  font-size: 35px;
  letter-spacing: 0.5px;
  text-align: center;
}

.home_id {
  margin-top: 59px;
}

.dark .link_section h4 {
  color: #fff;
  font-weight: 400;
}

.dark .logo_section_f p {
  color: #87909f;
  font-size: 14px;
}

.dark .link_section ul a {
  color: #87909f;
}

.dark .link_section ul a:hover {
  color: #5841d8;
}

.dark .email_form {
  background: #000;
  margin-bottom: 11px;
  border-radius: 10px;
  border: 1px solid;
}

.dark .email_form button {
  background: #5841d8;
  height: 42px;
  padding: 0px 11px;
  font-size: 15px;
  margin: 0;
  border: none;
  color: #fff;
  font-weight: 400;
  border-radius: 10px;
}

.dark .email_form {
  box-shadow: none;
}

.dark .email_form input {
  color: #fff;
}

.dark .social a {
  color: #5841d8;
}

.dark .note_footer {
  color: #87909f;
}

.dark .copywrigt p {
  color: #87909f;
}

.dark .banner_V {
  height: 563px;
}

.dark .login_form {
  background: #060612;
  min-height: 100vh;
  /* justify-content: center; */
  padding-top: 70px;
}

.mt25 {
  margin-top: 25% !important;
}

.dark .card_login {
  padding: 20px 30px;
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a3b;
}

.dark .card_login h3 {
  color: #fff;

  margin-bottom: 5px;
  font-weight: 400;
  font-size: 30px;
}

.dark .card_login .form-control {
  height: 53px;
  border-radius: 6px;
  margin-bottom: 14px;
  border-color: #e4e4e42e;
  padding: 21px;
  width: 100%;
  background: transparent;
  color: #fff;
}

.dark .card_login textarea {
  height: 150px;
  border-radius: 6px;
  margin-bottom: 14px;
  border-color: #e4e4e42e;
  padding: 21px;
  width: 100%;
  background: transparent;
  color: #fff;
}

.dark .color_hss a {
  color: #5841d8;
  margin-bottom: 22px;
}

.dark .form_login_input button,
.dark .form_login_input button:hover {
  padding: 19px 44px;
  font-size: 22px;
  background: rgba(5, 5, 5);
  /* font-family: Kanit; */
  font-weight: 600;
  line-height: 26.4px;
  border-radius: 12px;
  text-transform: capitalize;
  color: #fff;
  width: 100%;
  margin: 0px;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  outline: none;
  padding: 0px 24px;
  line-height: 26px;
  min-width: 80px;
  word-break: keep-all;
  color: #fff;
  border-radius: 15px;
  min-height: 24px;
  border: none;
  background: #5841d8;
  white-space: normal;
  width: 100%;
  height: 53px;
  font-weight: 400;
}

.card_login .custom-control-label {
  color: #8c92ab;
}

.card_login .custom-control-label::before {
  background-color: #0b0b18;
  border: #5841d8 solid 1px;
}

.card_login .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #5740d6;
  background-color: #5841d8;
}

.card_login .custom-control-label a {
  color: #5841d8;
}

.position_eye {
  position: relative;
}

.position_eye .eye_content {
  width: fit-content;
  position: absolute;
  right: 14px;
  top: 14px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.lable_text {
  color: #8c92ab;
  font-size: 14px;
}

.dark .dropdown-content1 {
  border: 1px solid #2e323d78;
  border-radius: 10px;
  background-color: #181c2a;
}

.dark .dropdown-content1 a p {
  margin: 0;
  color: #fff;
  font-weight: 800;
}

.dark .dropdown-content1 a p span {
  color: #87909f;
}

.dark .top_coin_section h3 {
  color: #fff;
}

.border_non {
  border: none !important;
}

.lable_new_section {
  color: #87909f;
  font-weight: 500;
}

.p2p_row_price .text-success {
  color: #28a745 !important;
  margin-top: 10px;
}

.withdraw_fees {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.fees_value {
  color: #87909f;
}

.fees_text {
  color: #fff;
}

.hash_code {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto !important;
}

/* David */
/* Responsive */

@media only screen and (min-device-width: 900px) and (max-device-width: 1024px) {
  .convert-form {
    margin-top: -180px;
  }

  .convert-image {
    width: 100%;
    height: 400px;
  }

  .swap-convert {
    margin-top: -10px;
  }

  .contvert-text {
    font-size: 28px;
  }

  .history-icon {
    font-size: 18px;
  }

  .input input {
    font-size: 24px;
  }

  .max-text {
    font-size: 16px;
  }

  .ui.inline.dropdown > .text {
    font-size: 16px;
  }

  .max-dropdown {
    margin-left: -50px;
  }

  .price-text {
    font-size: 14px;
  }

  .usdt-btc {
    font-size: 14px;
  }

  .faq-text {
    font-size: 14px;
  }

  .faq-title {
    font-size: 22px;
  }

  .card-title.p2p.swap {
    font-size: 14px;
  }

  .p2p_dropdown {
    display: flex;
    gap: 50px;
    margin-left: 50px;
  }

  .dropdown.amount {
    display: flex;
    gap: 50px;
    margin-top: 20px;
  }

  .table thead th {
    min-width: 250px;
  }

  .p2p_pagination {
    margin-top: 20px;
  }

  .card-header.collapsed.p2p.chat {
    padding: 32px 10px;
  }

  .banner_left_content {
    padding-left: 122px !important;
  }

  .email_form input {
    width: 60%;
  }

  .msg-text-box input {
    padding: 20px 30px;
  }

  .dark .p2p_chat_section {
    margin-right: 0px;
  }

  .dark .p2p_chat_section {
    padding: 27px 0px;
  }
}

@media (max-width: 900px) {
  .convert-form {
    margin-top: 80px;
  }

  .nav_dropdown-content1 img {
    width: 25px !important;
  }
  .content_nav p {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
  .convert-image {
    width: 100%;
    height: 330px;
  }

  .swap-convert {
    margin-top: -240px;
  }

  .contvert-text {
    font-size: 28px;
  }

  .history-icon {
    font-size: 18px;
  }

  .input input {
    font-size: 24px;
  }

  .max-text {
    font-size: 16px;
  }

  .ui.inline.dropdown > .text {
    font-size: 16px;
  }

  .max-dropdown {
    margin-left: -50px;
  }

  .price-text {
    font-size: 14px;
  }

  .usdt-btc {
    font-size: 14px;
  }

  .faq-text {
    font-size: 14px;
  }

  .faq-title {
    font-size: 22px;
  }

  .card-title.p2p.swap {
    font-size: 14px;
  }

  .p2p_header h1 {
    font-size: 48px;
  }

  .p2p_header p {
    font-size: 14px;
  }

  .table thead th {
    min-width: 260px;
  }

  .p2p_pagination {
    margin-top: 20px;
  }

  .tutorial-steps {
    flex-direction: column;
  }

  .about-kucoin {
    flex-direction: column;
  }

  .about-kucoin2 {
    flex-direction: column;
  }

  .p2p_works {
    min-width: 100%;
  }

  .p2p_dropdown {
    width: 100%;
  }

  .p2p_dropdown li {
    width: 100%;
  }

  .ui.selection.dropdown {
    display: flex;
    margin: 20px;
  }

  .KuxMenu-root.lrtcss-yqpjhy {
    display: none;
  }

  .balance_amount {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0px;
  }

  .place_order_header {
    padding-right: 0px;
  }

  .p2p_chat_section {
    padding: 0px;
    margin-left: 36px;
  }

  .card-header.collapsed.p2p.chat {
    padding: 25px 17px;
  }

  .headeing_banner h1 {
    font-size: 52px;
  }

  .headeing_banner p {
    font-size: 18px;
  }

  .crypto_icon.two_2 {
    right: -30px;
    top: 300px;
  }

  .crypto_icon.one_1 {
    position: absolute;
    top: -150px;
    left: 80px;
  }
}

@media (max-width: 700px) {
  .mobile_image {
    position: static;

    transform: none;
  }

  .section_card_with_image {
    margin-bottom: 30px;
  }

  .blog_card {
    margin-bottom: 30px;
  }

  .vh-100 {
    height: auto !important;
    text-align: center;
    padding: 50px 0px;
    padding-bottom: 0px;
  }

  .card_table {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 1px 4px rgba(20, 20, 43, 0.01),
      0 3px 6px rgba(51, 71, 255, 0.01), 0 4px 22px rgba(21, 19, 91, 0.04);
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
  }

  .convert-form {
    margin-top: 80px;
  }

  .convert-image {
    width: 100%;
    height: 210px;
  }

  .swap-convert {
    margin-top: -80px;
  }

  .contvert-text {
    font-size: 20px;
  }

  .history-icon {
    font-size: 14px;
  }

  .input input {
    font-size: 18px;
  }

  .max-text {
    font-size: 14px;
  }

  .max-dropdown {
    margin-left: -50px;
  }

  .price-text {
    font-size: 12px;
  }

  .usdt-btc {
    font-size: 12px;
  }

  .faq-text {
    font-size: 14px;
  }

  .faq-title {
    font-size: 22px;
  }

  .card-title.p2p.swap {
    font-size: 14px;
  }

  .p2p_chat_section {
    padding: 0px;
    margin-left: 60px;
  }

  .card-title.p2p {
    font-size: 20px;
  }

  .image_scroll .img_1 img {
    display: none;
  }

  .crypto_news h3 {
    font-size: 14px;
  }

  .price_section span {
    font-size: 14px;
  }

  .price_section small {
    font-size: 13px;
    padding: 2px 4px;
  }
}

@media (max-width: 500px) {
  .nav_dropdown-content1 img {
    display: none !important;
  }

  .rotate_image {
    display: none;
  }

  .ui.pagination.menu {
    margin-top: 30px;
  }

  .line_icon {
    display: none;
  }

  .convert-form {
    margin-top: 50px;
  }

  .ui.inline.dropdown > .text {
    font-size: 14px;
  }

  .ui.dropdown > .text > .image,
  .ui.dropdown > .text > img {
    width: 25px;
    height: auto;
    margin-right: 6px;
  }

  .input {
    width: 60%;
  }

  .convert-image {
    width: 100%;
    height: 170px;
  }

  .swap-convert {
    margin-top: -53px;
  }

  .contvert-text {
    font-size: 20px;
  }

  .history-icon {
    font-size: 14px;
  }

  .input input {
    font-size: 16px;
  }

  .max-text {
    font-size: 14px;
  }

  .max-dropdown {
    margin-left: -50px;
  }

  .price-text {
    font-size: 12px;
  }

  .usdt-btc {
    font-size: 12px;
  }

  .faq-text {
    font-size: 12px;
  }

  .faq-title {
    font-size: 22px;
  }

  .card-title.p2p.swap {
    font-size: 14px;
  }

  .about-kucoin {
    flex-direction: column;
  }

  .about-kucoin2 {
    flex-direction: column;
  }

  .p2p_works_steps {
    flex-direction: column;
  }

  .dropdown.amount {
    display: flex;
    flex-direction: column;
  }

  .profile_content h3 {
    font-size: 14px;
  }

  .profile_content p {
    font-size: 12px;
  }

  .p2p_works_title {
    font-size: 24px;
  }

  .work_step1 p {
    font-size: 13px;
  }

  .tutorial-title {
    font-size: 20px;
  }

  .tutorial-steps h3 {
    font-size: 16px;
  }

  .tutorial-step1.padding {
    padding-bottom: 40px;
  }

  .trading_accordion h1 {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 150%;
  }

  .card-title.p2p {
    font-size: 14px;
  }

  .card-header.collapsed.p2p {
    padding: 22px 10px;
  }

  .p2p_header h1 {
    font-size: 25px;
  }

  .p2p_header p {
    font-size: 13px;
  }

  .p2p_tabs .nav-item a {
    font-size: 16px;
  }

  .p2p_tabs .nav-tabs .nav-link.active::after {
    width: 25px;
  }

  .nav-item.buy .buy-btn {
    height: 28px;
    padding: 0px 10px;
    margin-left: 0px;
  }

  .ui.selection.dropdown {
    min-width: 14em;
    margin: 10px;
    min-width: 5em;
  }

  .kucoin_title {
    line-height: 40px;
  }

  .card-title.p2p {
    font-size: 14px;
  }

  .trading_accordion_section .card-body {
    font-size: 14px;
  }

  .tutorial-steps {
    flex-direction: column;
  }

  .table thead th {
    min-width: 280px;
  }

  .lrtcss-sbd8h {
    padding: 15px 34px;
  }

  .wallet_balance {
    padding: 30px;
  }

  .fiat-ac {
    margin-right: 100px;
    margin-top: 20px;
  }

  .place_order_content h1 {
    font-size: 26px;
  }

  .card-header.collapsed.p2p.chat {
    word-break: break-word;
    padding: 22px 10px;
  }

  .payment-dropdown .ui.selection.dropdown {
    min-width: 13em;
  }

  .p2p_chat_section {
    padding: 0px;
    margin-left: 4px;
  }

  .rotate_image {
    width: 100%;
    height: auto;
  }

  .headeing_banner h1 {
    font-size: 34px;
  }

  .headeing_banner p {
    font-size: 18px;
  }

  .heading_h2 {
    font-size: 28px;
  }

  .heading_sub p {
    font-size: 14px;
  }

  .content_feturee h3 {
    font-size: 28px;
  }

  .crypto_icon.two_2 {
    right: 17px;
    top: 365px;
  }

  .crypto_icon {
    width: 60px;
  }

  .crypto_icon.one_1 {
    top: -130px;
    left: 80px;
  }

  img.new_icon {
    margin: 0 auto;
    display: block;
  }

  .width_innwe {
    width: 100%;
  }

  .bg_img_nrw {
    background-size: 100%;
    background-position: center;
    background-position-y: 220px;
    padding-bottom: 180px;
  }

  .section_card_with_image img {
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
  }

  .content_center_mobile h3 {
    font-size: 28px;
    margin-top: 50px;
  }

  .section_card_with_image img {
    width: 55px;
  }

  .ineer_image {
    text-align: center;
  }

  .section_card_with_image h4 {
    font-size: 18px;
  }

  .section_card_with_image p {
    font-size: 13px;
  }

  button.veiw_btn,
  button.veiw_btn:hover {
    margin: 10px auto;
  }

  .table_tabe ul li a {
    padding: 10px 34px;
  }

  .heading_h2 {
    font-size: 22px;
    margin: 0 auto;
    text-align: center;
  }

  .secutiry_deture {
    height: auto;
  }

  .contant_last h2 {
    font-size: 28px;
  }

  .link_section {
    margin-left: 0px;
  }

  .staking_right_section {
    max-width: 100% !important;
  }
}

@media (max-width: 400px) {
  .px-5 {
    padding: 0 1rem !important;
  }
  .convert-form {
    margin-top: 30px;
  }

  .convert-image {
    width: 100%;
    height: 140px;
  }

  .swap-convert {
    margin-top: -35px;
  }

  .contvert-text {
    font-size: 20px;
  }

  .history-icon {
    font-size: 14px;
  }

  .input input {
    font-size: 16px;
  }

  .max-text {
    font-size: 14px;
  }

  .max-dropdown {
    margin-left: -40px;
  }

  .price-text {
    font-size: 12px;
  }

  .usdt-btc {
    font-size: 12px;
  }

  .faq-text {
    font-size: 12px;
    margin-left: 50px;
  }

  .faq-title {
    font-size: 22px;
  }

  .card-title.p2p.swap {
    font-size: 14px;
  }

  /* P2p Responsive */

  .p2p_header h1 {
    font-size: 25px;
  }

  .p2p_header p {
    font-size: 13px;
  }

  .p2p_tabs .nav-item a {
    font-size: 16px;
  }

  .p2p_header p {
    font-size: 13px;
  }

  .p2p_tabs .nav-item a {
    font-size: 16px;
  }

  .p2p_tabs .nav-tabs .nav-link.active::after {
    width: 25px;
  }

  .nav-item.buy .buy-btn {
    height: 28px;
    padding: 0px 10px;
    margin-left: 0px;
  }

  .ui.selection.dropdown {
    min-width: 14em;
    margin: 10px;
    min-width: 5em;
  }

  .kucoin_title {
    line-height: 40px;
  }

  .card-title.p2p {
    font-size: 14px;
  }

  .trading_accordion_section .card-body {
    font-size: 14px;
  }

  .tutorial-steps {
    flex-direction: column;
  }

  .table thead th {
    min-width: 280px;
  }

  .p2p_buy_box {
    padding: 12px 27px;
  }

  .crypto_icon.one_1 {
    top: -120px;
    left: 80px;
  }

  .crypto_icon.two_2 {
    top: 390px;
  }

  .heading_p {
    font-size: 14px;
  }

  .Dashcard .lrtcss-1iijojv {
    /* display: block; */
    overflow-wrap: break-word;
  }

  .header .css-z6khg5-MuiToolbar-root {
    min-height: 65px;
  }

  .link_section {
    margin-left: 0px;
  }

  .staking_right_section {
    max-width: 100% !important;
  }

  .select_coin .modal-title {
    font-size: 22px;
  }

  .top_coin_item {
    padding: 6px;
  }
}

@media (max-width: 300px) {
  .p2p_tabs .nav-item a {
    font-size: 16px;
  }

  .p2p_tabs .nav-tabs .nav-link.active::after {
    width: 25px;
  }

  .nav-item.buy .buy-btn {
    height: 28px;
    padding: 0px 10px;
    margin-left: 8px;
  }

  .ui.selection.dropdown {
    min-width: 14em;
    margin: 10px;
    min-width: 5em;
  }

  .kucoin_title {
    line-height: 40px;
  }

  .card-title.p2p {
    font-size: 14px;
  }

  .trading_accordion_section .card-body {
    font-size: 14px;
  }

  .tutorial-steps {
    flex-direction: column;
  }

  .table thead th {
    min-width: 280px;
  }

  .p2p_buy_box {
    padding: 12px 27px;
  }

  .crypto_icon.one_1 {
    top: -120px;
    left: 80px;
  }

  .crypto_icon.two_2 {
    top: 390px;
  }

  .heading_p {
    font-size: 14px;
  }

  .Dashcard .lrtcss-1iijojv {
    display: block;
    overflow-wrap: break-word;
  }

  .header .css-z6khg5-MuiToolbar-root {
    min-height: 65px;
  }

  .pay_balance {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .confirm-btn button {
    padding: 15px 75px;
  }
}

@media (max-width: 300px) {
  .p2p_tabs .nav-tabs .nav-link.active::after {
    width: 25px;
  }

  .nav-item.buy .buy-btn {
    height: 28px;
    padding: 0px 10px;
    margin-left: 8px;
  }

  .ui.selection.dropdown {
    min-width: 14em;
    margin: 10px;
    min-width: 5em;
  }

  .kucoin_title {
    line-height: 40px;
  }

  .card-title.p2p {
    font-size: 14px;
  }

  .trading_accordion_section .card-body {
    font-size: 14px;
  }

  .tutorial-steps {
    flex-direction: column;
  }

  .table thead th {
    min-width: 280px;
  }

  .p2p_buy_box {
    padding: 12px 27px;
  }

  .crypto_icon.one_1 {
    top: -120px;
    left: 80px;
  }

  .crypto_icon.two_2 {
    top: 390px;
  }

  .heading_p {
    font-size: 14px;
  }

  .dark .card_login h3 {
    font-size: 25px;
  }
}

@media (max-width: 300px) {
  .nav-item.buy .buy-btn {
    margin-left: 3px;
  }

  .card-header.collapsed.p2p.chat {
    padding: 16px 8px;
  }

  .place_order_content h1 {
    font-size: 24px;
  }

  .p2p_buy_box {
    padding: 10px;
  }

  button.veiw_btn,
  button.veiw_btn:hover {
    padding: 9px 19px;
    font-size: 13px;
    margin: 14px auto;
  }

  .heading_h2 {
    font-size: 22px;
  }

  .table_tabe ul li a {
    padding: 8px 19px;
    font-size: 13px;
  }

  .email_form input {
    width: 60%;
  }

  .link_section {
    margin-left: 0px;
  }
}

.heading_h2 {
  font-size: 35px;
}

.table_tabe ul li a {
  padding: 8px 19px;
  font-size: 13px;
}

.flexbutton button {
  min-width: 58px;
  font-size: 12px;
  padding: 8px 31px;
}

.flexbutton {
  gap: 16px;
  margin-left: 0px;
}

.Dashcard.bg_trance .lrtcss-17ouo08 {
  flex-direction: column;
}

.red-green {
  color: #0ecb81 !important;
}

.color-buy {
  color: #f6465d !important;
}

a {
  text-decoration: none !important;
}

.active-button {
  background-color: #813ef2 !important;
  border-radius: 100px !important;
}

.dropdesign {
  display: flex;
  justify-content: space-between;
  padding: 16px 21px !important;
}

.dropdown-toggle::after {
  margin-top: 10px !important;
}

.dropdesigns.dropdown-menu {
  width: 100% !important;
  background: #060612 !important;
  border: 1px solid #32323d !important;
}

.dropdesigns.dropdown-menu .dropdown-item {
  color: #fff !important;
}

.dropdesigns.dropdown-menu .dropdown-item:focus,
.dropdesigns.dropdown-menu .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #0b0b18 !important;
}

/* David */

html.theme-dark .group-wWM3zP_M- {
  background-color: #0000 !important;
}

.w-100 {
  width: 100% !important;
}

.price_pair {
  padding: 10px 0px;
}

.price_pair .paitr_content {
  align-items: center;
  color: #cecece;
  display: flex;
  font-size: 33px;
  gap: 10px;
  margin-top: 10px;
}

.paitr_content img {
  border-radius: 50% !important;
}

.lanchbuy {
  color: white;
  margin: 20px 0px 40px !important;
}

.lanchbuy h5 {
  font-weight: bold;
}

button.lanbutton {
  background: #813ef2;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  color: white;
}

button.lanbutton:hover {
  background: #813ef2;
}

.react-tel-input .flag-dropdown {
  background-color: #f5f5f500 !important;
  border: 1px solid #32323c !important;
  border-radius: 6px 0 0 6px !important;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  background-color: #0b0b18 !important;
  color: rgb(135 144 159) !important;
}

.Accordion .css-1wz20g3 {
  background-color: #0b0b18 !important;
  color: rgb(135 144 159) !important;
}

.roundedhidden {
  border-radius: 20px !important;
  overflow: hidden !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #0b0b18 !important;
}

.react-tel-input .country-list {
  background-color: #0b0b18 !important;
}

.react-tel-input .country-list .country-name {
  color: #fff !important;
}

.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
  background-color: #0d0e0f !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #0b0b18 !important;
}

.css-z6khg5-MuiToolbar-root {
  justify-content: space-between !important;
}

.MuiIconButton-root {
  color: #fff !important;
}

.MuiPaper-root {
  background-color: #181c2a !important;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: none !important;
  /* Change this to your desired color */
  color: fieldtext !important;
}

/* Define CSS variables for light and dark mode colors */
:root {
  --autofill-bg-light: rgb(232, 240, 254);
  /* Light mode color */
  --autofill-bg-dark: rgba(70, 90, 126, 0.4);
  /* Dark mode color */
}

/* Apply the background color for light mode */
@media (prefers-color-scheme: light) {
  input:-internal-autofill-selected {
    background-color: var(--autofill-bg-light) !important;
  }
}

/* Apply the background color for dark mode */
@media (prefers-color-scheme: dark) {
  input:-internal-autofill-selected {
    background-color: var(--autofill-bg-dark) !important;
  }
}

.Accordionfaq {
  background-color: #0b0b18 !important;
  color: rgb(135 144 159) !important;
}

.mt40 {
  margin-top: 200px !important;
}

.checkout_modal {
  align-items: flex-start;
  background-color: #0a090d;
  border: 1px solid #474d57;
  border-radius: 20px;
  flex-direction: column;
  padding: 24px;
  width: auto;
}
.Frame-14722 {
  align-items: center;
  flex-direction: row;
  height: 24px;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

i {
  cursor: pointer !important;
}
.Close,
.Item-Details {
  color: #eaecef;
  font-size: 20px;
}
.Deletecontent {
  color: #eaecef;
  font-size: 15px;
  font-weight: 600;
  margin-top: 24px !important;
  text-align: center;
}

.checkout_modal .modal-content {
  background-color: #0a090d !important;
}

.table.market th.textleft {
  text-align: left !important;
}

.minheight100 {
  min-height: 100vh !important;
}